"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatCityStateCode = exports.formatStreetAddress = void 0;
const formatStreetAddress = (address, withComma = true) => {
    const { streetAddress, unitNumber, attention } = address;
    let formattedStreetAddress = streetAddress
        ? unitNumber
            ? streetAddress + ', Unit ' + unitNumber
            : streetAddress
        : '';
    formattedStreetAddress = attention ? formattedStreetAddress + ' Attention: ' + attention : formattedStreetAddress;
    return withComma && formattedStreetAddress ? formattedStreetAddress + ', ' : formattedStreetAddress;
};
exports.formatStreetAddress = formatStreetAddress;
const formatCityStateCode = (address) => {
    const { city, state, postalCode } = address;
    return city && state && postalCode ? city + ', ' + state + ' ' + postalCode : '';
};
exports.formatCityStateCode = formatCityStateCode;
