"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.labelInvalidCheck = exports.isrcInvalidCheck = exports.artistNameInvalidCheck = exports.recordingTitleInvalidCheck = exports.publisherShareInvalidCheck = exports.publisherNameInvalidCheck = exports.isComposerPresent = exports.writerRoleInvalidCheck = exports.writerLastNameInvalidCheck = exports.writerFirstNameInvalidCheck = exports.akaTitleTypeInvalidCheck = exports.akaTitleInvalidCheck = exports.languageInvalidCheck = exports.iswcInvalidCheck = exports.rightsholderProprietaryIdInvalidCheck = exports.copyrightOfficeNumberInvalidCheck = exports.durationInvalidCheck = exports.workTitleInvalidCheck = void 0;
const validate_1 = require("@App/helpers/validate");
const work_entities_fields_validators_1 = require("@App/helpers/work-entities-fields-validators");
// These validators check specific field of work entities and return isError: boolean
// Work detail fields //
const workTitleInvalidCheck = (workTitle) => !workTitle || !workTitle.length || workTitle.length > 200 || (0, work_entities_fields_validators_1.containsNewLine)(workTitle);
exports.workTitleInvalidCheck = workTitleInvalidCheck;
const durationInvalidCheck = (duration) => !!(duration && !duration.match(/^[0-9]{2}[:]{1}[0-9]{2}$/));
exports.durationInvalidCheck = durationInvalidCheck;
const copyrightOfficeNumberInvalidCheck = (copyrightOfficeNumber) => !!(copyrightOfficeNumber && copyrightOfficeNumber.length > 12);
exports.copyrightOfficeNumberInvalidCheck = copyrightOfficeNumberInvalidCheck;
const rightsholderProprietaryIdInvalidCheck = (rightsholderProprietaryId) => !!(rightsholderProprietaryId && rightsholderProprietaryId.length > 14);
exports.rightsholderProprietaryIdInvalidCheck = rightsholderProprietaryIdInvalidCheck;
const iswcInvalidCheck = (iswc) => !!(iswc && !(0, validate_1.ISWCValidator)(iswc));
exports.iswcInvalidCheck = iswcInvalidCheck;
const languageInvalidCheck = (languageCode, langsLib, required) => {
    const langIds = langsLib ? langsLib.map((lang) => +lang.id) : [];
    if (required) {
        return !languageCode || langIds.indexOf(languageCode) === -1;
    }
    return !!(languageCode && langIds.indexOf(languageCode) === -1);
};
exports.languageInvalidCheck = languageInvalidCheck;
// Aka title fields //
const akaTitleInvalidCheck = (akaTitle) => !akaTitle || akaTitle.length > 500 || (0, work_entities_fields_validators_1.containsNewLine)(akaTitle);
exports.akaTitleInvalidCheck = akaTitleInvalidCheck;
const akaTitleTypeInvalidCheck = (akaTitleType, akaLib) => {
    const typeIds = akaLib ? akaLib.map((akaType) => +akaType.id) : [];
    return !akaTitleType || typeIds.indexOf(akaTitleType) === -1;
};
exports.akaTitleTypeInvalidCheck = akaTitleTypeInvalidCheck;
// Writer fields //
const writerFirstNameInvalidCheck = (firstName) => !!(firstName && (firstName.length > 60 || (0, work_entities_fields_validators_1.containsNewLine)(firstName)));
exports.writerFirstNameInvalidCheck = writerFirstNameInvalidCheck;
const writerLastNameInvalidCheck = (lastName) => !lastName || !lastName.length || lastName.length > 200 || (0, work_entities_fields_validators_1.containsNewLine)(lastName);
exports.writerLastNameInvalidCheck = writerLastNameInvalidCheck;
const writerRoleInvalidCheck = (roleCode, rolesLib) => {
    const roleIds = rolesLib ? rolesLib.map((writerRole) => +writerRole.id) : [];
    return !roleCode || roleIds.indexOf(roleCode) === -1;
};
exports.writerRoleInvalidCheck = writerRoleInvalidCheck;
const isComposerPresent = (writers) => writers.some((writer) => writer.roleCode === 10 || writer.roleCode === 11);
exports.isComposerPresent = isComposerPresent;
// Publisher fields //
const publisherNameInvalidCheck = (publisherName) => !publisherName || publisherName.length > 200 || (0, work_entities_fields_validators_1.containsNewLine)(publisherName);
exports.publisherNameInvalidCheck = publisherNameInvalidCheck;
const publisherShareInvalidCheck = (publisherShare) => Number(publisherShare) <= 0 || Number(publisherShare) > 100.06;
exports.publisherShareInvalidCheck = publisherShareInvalidCheck;
// Recording fields //
const recordingTitleInvalidCheck = (title) => !title || title.length > 200 || (0, work_entities_fields_validators_1.containsNewLine)(title);
exports.recordingTitleInvalidCheck = recordingTitleInvalidCheck;
const artistNameInvalidCheck = (artistName) => !artistName || !artistName.length || artistName.length > 60 || (0, work_entities_fields_validators_1.containsNewLine)(artistName);
exports.artistNameInvalidCheck = artistNameInvalidCheck;
const isrcInvalidCheck = (isrc) => !!(isrc && isrc.length !== 12);
exports.isrcInvalidCheck = isrcInvalidCheck;
const labelInvalidCheck = (label) => !!(label && (label.length > 60 || (0, work_entities_fields_validators_1.containsNewLine)(label)));
exports.labelInvalidCheck = labelInvalidCheck;
