"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formIsValid = void 0;
/**
 * @deprecated Use isFormNotValid = (object: any, validations: any): boolean instead found in src/helpers/validation/form-validation.ts
 */
const formIsValid = (formObject, validationsObj) => {
    for (const key in validationsObj) {
        if (validationsObj && Object.prototype.hasOwnProperty.call(validationsObj, key)) {
            if (validationsObj[key].error) {
                return false;
            }
            else if (validationsObj[key].required === true && !formObject[key]) {
                return false;
            }
            else if (validationsObj[key].required === true &&
                !Object.prototype.hasOwnProperty.call(formObject, key)) {
                return false;
            }
        }
    }
    return true;
};
exports.formIsValid = formIsValid;
