"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformUserGroupsToSelectOptions = void 0;
const transformUserGroupsToSelectOptions = (userGroups) => userGroups.map((item) => {
    const { name } = item;
    return {
        label: name,
        value: name,
        id: name,
    };
});
exports.transformUserGroupsToSelectOptions = transformUserGroupsToSelectOptions;
