"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.USER_PROFILE_TABS = exports.MAINTENANCE_TABS = void 0;
exports.MAINTENANCE_TABS = {
    mailingAndContact: 'Mailing and Contact',
    bankingAndTax: 'Banking and Tax',
    endorsement: 'Member Endorsement',
};
exports.USER_PROFILE_TABS = {
    user: 'User',
    rightsholders: 'Members',
};
