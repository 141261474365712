"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generatePublishersTableSchema = void 0;
const zod_1 = require("zod");
const is_overclaim_allowed_1 = require("@App/helpers/is-overclaim-allowed");
const minMaxCheck = (value) => value !== undefined && value >= 0.01 && value <= 100.06;
const publisherRowsValidationSchema = (workDraftFromLocal) => zod_1.z
    .object({
    ['publisherName']: zod_1.z.string(),
    ['publisherIPI']: zod_1.z.string().optional(),
    ['publisherIPINumber']: zod_1.z.string(),
    ['publisherShare']: zod_1.z
        .string({ invalid_type_error: 'Enter a number between 0.01% - 100.06%' })
        .or(zod_1.z.literal('')),
})
    .refine((value) => {
    if (value.publisherShare.trim() === '') {
        return false;
    }
    const foundValueIndex = workDraftFromLocal.originalPublishers.findIndex((op) => { var _a; return op.ipiNumber === ((_a = value.publisherIPI) !== null && _a !== void 0 ? _a : value.publisherIPINumber); });
    if (foundValueIndex !== -1) {
        const hasAdmin = workDraftFromLocal.originalPublishers[foundValueIndex].administratorPublishers.length > 0;
        return hasAdmin ? true : minMaxCheck(Number(value.publisherShare));
    }
    return true;
}, {
    path: ['publisherShare'],
    message: 'Enter a number between 0.01% - 100.06%',
})
    .optional()
    .array()
    .optional();
const administratorRowsValidationSchema = zod_1.z
    .object({
    ['administrator']: zod_1.z.string().optional(),
    ['administratorIPI']: zod_1.z.string().optional(),
    ['administratorShare']: zod_1.z
        .string({ invalid_type_error: 'Enter a number between 0.01% - 100.06%' })
        .or(zod_1.z.literal('')),
})
    .refine((value) => {
    if (value.administratorShare.trim() === '') {
        return false;
    }
    return minMaxCheck(Number(value.administratorShare));
}, {
    path: ['administratorShare'],
    message: 'Enter a number between 0.01% - 100.06%',
})
    .optional()
    .array()
    .optional();
const generatePublishersTableSchema = (workDraftLocal) => zod_1.z
    .object({
    publisherRows: publisherRowsValidationSchema(workDraftLocal),
    administratorRows: administratorRowsValidationSchema.optional(),
})
    .superRefine((refineValues, ctx) => {
    const { administratorRows, publisherRows } = refineValues;
    const totalSumOfShares = (publisherRows || [])
        .map((row) => row === null || row === void 0 ? void 0 : row.publisherShare)
        .concat(administratorRows === null || administratorRows === void 0 ? void 0 : administratorRows.map((row) => row === null || row === void 0 ? void 0 : row.administratorShare))
        .map((val) => val !== null && val !== void 0 ? val : '0')
        .map((val) => Number.parseFloat(val))
        .reduce((prev, curr) => prev + curr, 0);
    if (totalSumOfShares > 100.06 && !(0, is_overclaim_allowed_1.isOverclaimAllowed)(workDraftLocal)) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: 'Total share percentage must not exceed 100.06%',
            path: ['globalError'],
        });
    }
});
exports.generatePublishersTableSchema = generatePublishersTableSchema;
