"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPhoneNumberTitle = void 0;
const getPhoneNumberTitle = (phoneCountryCode, phoneAreaCode, phoneNumber) => {
    const code = phoneCountryCode ? phoneCountryCode : '';
    const area = phoneAreaCode ? phoneAreaCode : '';
    const num = phoneNumber ? phoneNumber : '';
    return code + area + num;
};
exports.getPhoneNumberTitle = getPhoneNumberTitle;
