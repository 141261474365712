"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RightsholderContactFormView = void 0;
const react_1 = __importStar(require("react"));
const forms_constants_1 = require("@Const/forms-constants");
const another_phone_formatter_1 = require("@App/helpers/another-phone-formatter");
const get_country_title_1 = require("@App/helpers/get-country-title");
const contexts_1 = require("@App/contexts");
const utils_1 = require("@App/common/utils");
const rightsholder_contact_type_1 = require("@App/helpers/rightsholder-contact-type");
const RightsholderContactFormView = (props) => {
    var _a;
    const { libStore } = (0, react_1.useContext)(contexts_1.StoresContext);
    const calculateCountryTitle = (countryId) => (0, utils_1.isCountryUS)(countryId) ? 'USA' : (0, get_country_title_1.getCountryTitle)(libStore.countries, countryId);
    const formatCountryPostalCodeLine = (countryId, postalCode) => {
        const countryTitle = calculateCountryTitle(countryId);
        if (countryTitle && postalCode) {
            return countryTitle + ', ' + postalCode;
        }
        if (countryTitle) {
            return countryTitle;
        }
        if (postalCode) {
            return postalCode;
        }
        return '';
    };
    const { firstName, lastName, rightsholderContactTypeId, address, phoneNumber, email } = props.rightsholderContact;
    return (react_1.default.createElement("div", { className: 'body-contact-info' },
        react_1.default.createElement("section", { className: 'name-section' },
            react_1.default.createElement("div", { className: 'fields-row-container' },
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("h5", { className: 'title' }, forms_constants_1.RIGHTSHOLDER_CONTACT_VIEW_FORM.contactName.label),
                    react_1.default.createElement("p", { className: 'value' },
                        firstName,
                        " ",
                        lastName)),
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("h5", { className: 'title' }, forms_constants_1.RIGHTSHOLDER_CONTACT_VIEW_FORM.contactType.label),
                    react_1.default.createElement("p", { className: 'value' }, (0, rightsholder_contact_type_1.getRightsholderContactType)(rightsholderContactTypeId))))),
        react_1.default.createElement("section", { className: 'mailing-address-section' },
            react_1.default.createElement("div", { className: 'fields-column-container custom' },
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("h5", { className: 'title' }, forms_constants_1.RIGHTSHOLDER_CONTACT_VIEW_FORM.contactAddress.label),
                    address && (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement("p", { className: 'value' }, (_a = address.streetAddress) !== null && _a !== void 0 ? _a : ''),
                        address.unitNumber && react_1.default.createElement("p", { className: 'value' },
                            "Unit #: ",
                            address.unitNumber),
                        address.attention && react_1.default.createElement("p", { className: 'value' },
                            "Attention: ",
                            address.attention),
                        react_1.default.createElement("p", { className: 'value' }, [address.city, address.state].filter(Boolean).join(', ')),
                        react_1.default.createElement("p", { className: 'value' }, formatCountryPostalCodeLine(Number(address.countryId), address.postalCode))))))),
        react_1.default.createElement("section", { className: 'contact-info-section' },
            react_1.default.createElement("div", { className: 'fields-column-container' },
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("h5", { className: 'title' }, forms_constants_1.RIGHTSHOLDER_CONTACT_VIEW_FORM.contactPhone.label),
                    react_1.default.createElement("p", { className: 'value' }, phoneNumber && (0, another_phone_formatter_1.formatPhoneNumber)(phoneNumber))),
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("h5", { className: 'title' }, forms_constants_1.RIGHTSHOLDER_CONTACT_VIEW_FORM.contactEmail.label),
                    react_1.default.createElement("p", { className: 'value' }, email !== null && email !== void 0 ? email : ''))))));
};
exports.RightsholderContactFormView = RightsholderContactFormView;
