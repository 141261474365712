"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQueryStringKey = exports.urlHasComponent = void 0;
const urlHasComponent = (component) => window.location.pathname.split('/').includes(component);
exports.urlHasComponent = urlHasComponent;
const getQueryStringKey = (key) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key);
};
exports.getQueryStringKey = getQueryStringKey;
