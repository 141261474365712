"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormFieldTypes = void 0;
var FormFieldTypes;
(function (FormFieldTypes) {
    FormFieldTypes["TEXT"] = "text";
    FormFieldTypes["DROPDOWN"] = "dropdown";
    FormFieldTypes["CHECKBOX"] = "checkbox";
    FormFieldTypes["DATEPICKER"] = "datepicker";
    FormFieldTypes["RADIO"] = "radio";
    FormFieldTypes["MULTI_DROPDOWN"] = "multi-dropdown";
})(FormFieldTypes = exports.FormFieldTypes || (exports.FormFieldTypes = {}));
