"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleAbortedException = void 0;
const HttpError_1 = require("@App/exceptions/HttpError");
// Suppress Errors Coming From Cancelled API calls
const handleAbortedException = (error) => {
    if (!((0, HttpError_1.isHttpError)(error) && error.statusCode === 'ERR_CANCELED')) {
        throw error;
    }
};
exports.handleAbortedException = handleAbortedException;
