"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isHttpError = exports.HttpError = void 0;
class HttpError extends Error {
    constructor(message, statusCode) {
        super(message);
        Object.defineProperty(this, "statusCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 200
        });
        this.name = this.constructor.name;
        this.statusCode = statusCode;
    }
}
exports.HttpError = HttpError;
const isHttpError = (error) => error.statusCode !== undefined;
exports.isHttpError = isHttpError;
