"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapCriteriaToObject = void 0;
const mapCriteriaToObject = (searchCriteria) => searchCriteria.reduce((resultValue, { searchTerm, selectedOption }) => {
    searchTerm && setResultValue(resultValue, selectedOption.value, searchTerm, !!selectedOption.forceArray);
    return resultValue;
}, {});
exports.mapCriteriaToObject = mapCriteriaToObject;
/* Some trickery to work-around issues setting property values on generics.
   If the property we're adding already exists, switch it to an array if it's not already, then add the value -
   otherwise, add the value as a regular field */
const setResultValue = (obj, prop, value, forceArray) => {
    if (prop in obj) {
        Array.isArray(obj[prop]) ? obj[prop].push(value) : (obj[prop] = [obj[prop], value]);
    }
    else {
        obj[prop] = forceArray ? [value] : value;
    }
};
