"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePasswordStatus = void 0;
var CreatePasswordStatus;
(function (CreatePasswordStatus) {
    CreatePasswordStatus[CreatePasswordStatus["setting_password"] = 0] = "setting_password";
    CreatePasswordStatus[CreatePasswordStatus["confirm_sms_code"] = 1] = "confirm_sms_code";
    CreatePasswordStatus[CreatePasswordStatus["configuration_mfa"] = 2] = "configuration_mfa";
    CreatePasswordStatus[CreatePasswordStatus["config_done"] = 3] = "config_done";
})(CreatePasswordStatus = exports.CreatePasswordStatus || (exports.CreatePasswordStatus = {}));
