"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.durationMessage = exports.maxNumberMessage = exports.nonNegativeMessage = exports.stringSizeBetweenMessage = exports.stringSizeMinMessage = exports.stringSizeMaxMessage = exports.newlineMessage = exports.booleanMessage = exports.integerMessage = exports.stringMessage = exports.numericMessage = exports.requiredMessage = void 0;
const requiredMessage = (fieldName) => `${fieldName} is required`;
exports.requiredMessage = requiredMessage;
const numericMessage = (fieldName) => `${fieldName} must be numeric`;
exports.numericMessage = numericMessage;
const stringMessage = (fieldName) => `${fieldName} must be alpha-numeric`;
exports.stringMessage = stringMessage;
const integerMessage = (fieldName) => `${fieldName} must be an integer`;
exports.integerMessage = integerMessage;
const booleanMessage = (fieldName) => `${fieldName} must be a boolean`;
exports.booleanMessage = booleanMessage;
const newlineMessage = (fieldName) => `${fieldName} may not contain newline characters`;
exports.newlineMessage = newlineMessage;
const stringSizeMaxMessage = (fieldName, maxSize) => `${fieldName} must contain at most ${maxSize} characters`;
exports.stringSizeMaxMessage = stringSizeMaxMessage;
const stringSizeMinMessage = (fieldName, minSize) => `${fieldName} must contain at least ${minSize} characters`;
exports.stringSizeMinMessage = stringSizeMinMessage;
const stringSizeBetweenMessage = (fieldName, minSize, maxSize) => `${fieldName} must be between ${minSize} and ${maxSize} characters`;
exports.stringSizeBetweenMessage = stringSizeBetweenMessage;
const nonNegativeMessage = (fieldName) => `${fieldName} must be be greater than or equal to 0`;
exports.nonNegativeMessage = nonNegativeMessage;
const maxNumberMessage = (fieldName, maxValue) => `${fieldName} must be less than or equal to ${maxValue}`;
exports.maxNumberMessage = maxNumberMessage;
const durationMessage = () => `Duration Must Be In Format XX:XX`;
exports.durationMessage = durationMessage;
