"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkForErrorsInMetadata = void 0;
const checkForErrorsInMetadata = (errors) => (errors === null || errors === void 0 ? void 0 : errors.rightsholderProprietaryId) ||
    (errors === null || errors === void 0 ? void 0 : errors.copyrightOfficeNumber) ||
    (errors === null || errors === void 0 ? void 0 : errors.copyrightOfficeDate) ||
    (errors === null || errors === void 0 ? void 0 : errors.songCode) ||
    (errors === null || errors === void 0 ? void 0 : errors.iswc) ||
    (errors === null || errors === void 0 ? void 0 : errors.duration) ||
    (errors === null || errors === void 0 ? void 0 : errors.languageCode) ||
    (errors === null || errors === void 0 ? void 0 : errors.referenceId) ||
    (errors === null || errors === void 0 ? void 0 : errors.alternativeTitles);
exports.checkForErrorsInMetadata = checkForErrorsInMetadata;
