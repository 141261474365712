"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const paymentStatusDefinitons = {
    cleared: {
        explanation: 'The check has been cashed by the payee.',
        color: 'green',
        link: 'none',
    },
    submitted: {
        explanation: 'The payment has been submitted. Transfer timelines vary by payment method.',
        color: 'green',
        link: 'methods',
    },
    paid: {
        explanation: "The transaction has been processed and the funds have been remitted based on the payee's chosen payment method.",
        color: 'green',
        link: 'none',
    },
    processing: {
        explanation: 'The payment is currently processing.',
        color: 'green',
        link: 'none',
    },
    deferred: {
        explanation: 'There was an issue with the payee account.' +
            ' Please revise your payment information. Once resolved, we will retry the payment in the next distribution period.',
        color: 'yellow',
        link: 'contactus',
    },
    'deferred (internal)': {
        explanation: 'Account is below threshold or under review.',
        color: 'yellow',
        link: 'methods',
    },
    scheduled: {
        explanation: 'Payment is scheduled for a future date.',
        color: 'yellow',
        link: 'none',
    },
    'risk review': {
        explanation: 'The payment is under review.',
        color: 'yellow',
        link: 'none',
    },
    'pending funds': {
        explanation: 'Payment has been submitted and is pending funds in the payer account before the payment is made.',
        color: 'yellow',
        link: 'none',
    },
    rejected: {
        explanation: 'Payment has been rejected by your payment provider. Please revise your payment information.' +
            ' Once resolved, we will retry the payment in the next distribution period.',
        color: 'red',
        link: 'contactus',
    },
    canceled: {
        explanation: 'Scheduled payment was stopped by The MLC.',
        color: 'red',
        link: 'contactus',
    },
};
exports.default = paymentStatusDefinitons;
