"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.labelInvalidCheck = exports.isrcInvalidCheck = exports.artistNameInvalidCheck = exports.recordingTitleInvalidCheck = exports.languageInvalidCheck = exports.iswcInvalidCheck = exports.rightsholderProprietaryIdInvalidCheck = exports.copyrightOfficeNumberInvalidCheck = exports.durationInvalidCheck = exports.workTitleInvalidCheck = exports.containsNewLine = void 0;
const validate_1 = require("@App/helpers/validate");
// These validators check specific field of work entities and return isError: boolean
const containsNewLine = (value) => value.includes('\n') || value.includes('\n');
exports.containsNewLine = containsNewLine;
// Work detail fields //
const workTitleInvalidCheck = (workTitle) => !workTitle || !workTitle.length || workTitle.length > 200 || (0, exports.containsNewLine)(workTitle);
exports.workTitleInvalidCheck = workTitleInvalidCheck;
const durationInvalidCheck = (duration) => duration && !duration.match(/^[0-9]{2}[:]{1}[0-9]{2}$/);
exports.durationInvalidCheck = durationInvalidCheck;
const copyrightOfficeNumberInvalidCheck = (copyrightOfficeNumber) => copyrightOfficeNumber && copyrightOfficeNumber.length > 12;
exports.copyrightOfficeNumberInvalidCheck = copyrightOfficeNumberInvalidCheck;
const rightsholderProprietaryIdInvalidCheck = (rightsholderProprietaryId) => rightsholderProprietaryId && rightsholderProprietaryId.length > 14;
exports.rightsholderProprietaryIdInvalidCheck = rightsholderProprietaryIdInvalidCheck;
const iswcInvalidCheck = (iswc) => iswc && !(0, validate_1.ISWCValidator)(iswc);
exports.iswcInvalidCheck = iswcInvalidCheck;
const languageInvalidCheck = (languageCode, langsLib, required) => {
    const langIds = langsLib ? langsLib.map((lang) => +lang.id) : [];
    if (required) {
        return !languageCode || langIds.indexOf(languageCode) === -1;
    }
    return languageCode && langIds.indexOf(languageCode) === -1;
};
exports.languageInvalidCheck = languageInvalidCheck;
// Recording fields //
const recordingTitleInvalidCheck = (title) => !title || title.length > 100 || (0, exports.containsNewLine)(title);
exports.recordingTitleInvalidCheck = recordingTitleInvalidCheck;
const artistNameInvalidCheck = (artistName) => (artistName && artistName.length > 60) || (0, exports.containsNewLine)(artistName);
exports.artistNameInvalidCheck = artistNameInvalidCheck;
const isrcInvalidCheck = (isrc) => {
    if (isrc) {
        if (!isrc.match(/^[0-9A-Za-z]+$/)) {
            return true;
        }
        if (!isrc.substring(0, 2).match(/^[A-Za-z]+$/)) {
            return true;
        }
        if (!isrc.substring(5, 12).match(/^[0-9]+$/)) {
            return true;
        }
        if (isrc.length !== 12) {
            return true;
        }
    }
    return false;
};
exports.isrcInvalidCheck = isrcInvalidCheck;
const labelInvalidCheck = (label) => label && (label.length > 60 || (0, exports.containsNewLine)(label));
exports.labelInvalidCheck = labelInvalidCheck;
