"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RightsholderContactTypes = void 0;
var RightsholderContactTypes;
(function (RightsholderContactTypes) {
    RightsholderContactTypes["PUBLIC"] = "PUBLIC";
    RightsholderContactTypes["LEGAL"] = "LEGAL";
    RightsholderContactTypes["FINANCE"] = "FINANCE";
    RightsholderContactTypes["COPYRIGHT"] = "COPYRIGHT";
})(RightsholderContactTypes = exports.RightsholderContactTypes || (exports.RightsholderContactTypes = {}));
