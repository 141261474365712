"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WithRequestMetadata = exports.getOrElse = exports.isUndefined = exports.isDefined = void 0;
const isDefined = (x) => x !== undefined && x !== null;
exports.isDefined = isDefined;
const isUndefined = (x) => x === undefined || x === null;
exports.isUndefined = isUndefined;
const getOrElse = (x, defaultValue) => ((0, exports.isDefined)(x) ? x : defaultValue);
exports.getOrElse = getOrElse;
class WithRequestMetadata {
    constructor(requestStatus, data, error) {
        Object.defineProperty(this, "requestStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: requestStatus
        });
        Object.defineProperty(this, "data", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: data
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: error
        });
    }
    static error(err) {
        return new WithRequestMetadata('error', undefined, err);
    }
    static data(data) {
        return new WithRequestMetadata('ready', data);
    }
    static requested() {
        return new WithRequestMetadata('requested');
    }
    static empty() {
        return new WithRequestMetadata('empty');
    }
}
exports.WithRequestMetadata = WithRequestMetadata;
