"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateRightsholderPaginationStatus = void 0;
const rightsholder_statuses_1 = require("@Const/rightsholder-statuses");
const generateRightsholderPaginationStatus = (rightsholderStatus) => {
    let newStatus;
    switch (rightsholderStatus) {
        case rightsholder_statuses_1.RIGHTSHOLDER_GROUP.all:
            newStatus = undefined;
            break;
        case rightsholder_statuses_1.RIGHTSHOLDER_GROUP.done:
        case rightsholder_statuses_1.RIGHTSHOLDER_GROUP.allDone:
            newStatus = rightsholder_statuses_1.RIGHTSHOLDER_VALIDATION_GROUP_STATUSES.done;
            break;
        case rightsholder_statuses_1.RIGHTSHOLDER_GROUP.pending:
        case rightsholder_statuses_1.RIGHTSHOLDER_GROUP.allPending:
            newStatus = rightsholder_statuses_1.RIGHTSHOLDER_VALIDATION_GROUP_STATUSES.pending;
            break;
        case rightsholder_statuses_1.RIGHTSHOLDER_GROUP.failed:
            newStatus = rightsholder_statuses_1.RIGHTSHOLDER_VALIDATION_GROUP_STATUSES.failed;
    }
    return newStatus;
};
exports.generateRightsholderPaginationStatus = generateRightsholderPaginationStatus;
