"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSourceNameBySourceId = void 0;
const getSourceNameBySourceId = (sourceId) => {
    if (sourceId) {
        switch (sourceId) {
            case 724:
            case 725:
                return 'eSong';
            case 726:
                return 'MLC Portal';
            default:
                return 'CWR';
        }
    }
    return null;
};
exports.getSourceNameBySourceId = getSourceNameBySourceId;
