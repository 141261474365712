"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.email = void 0;
const error_messages_1 = require("@Const/error-messages");
const error_messages_2 = require("@Entities/schemas/work/error-messages");
const validate_1 = require("@App/helpers/validate");
const zod_1 = require("zod");
const email = zod_1.z
    .string()
    .min(1, error_messages_1.REQUIRED_MESSAGE)
    .trim()
    .toLowerCase()
    .email(error_messages_1.EMAIL_MESSAGE)
    .max(2000, (0, error_messages_2.stringSizeMaxMessage)('Email', 2000))
    .refine(validate_1.isNotAnReservedEmailBoolean, error_messages_1.NO_RESERVED_EMAIL_MESSAGE);
exports.email = email;
