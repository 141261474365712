"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlternativeTitlesTable = void 0;
const react_1 = __importStar(require("react"));
require("./index.scss");
const contexts_1 = require("@App/contexts");
const string_escape_newlines_1 = require("@App/helpers/string-escape-newlines");
const format_language_1 = require("@App/helpers/format-language");
const AlternativeTitlesTable = ({ viewWork, errors }) => {
    const { libStore } = (0, react_1.useContext)(contexts_1.StoresContext);
    const langOptions = libStore.languages;
    const titleTypes = libStore.akaTitleTypes;
    const formatTitleType = (titleType) => { var _a; return (_a = titleTypes.find((type) => +type.id === titleType)) === null || _a === void 0 ? void 0 : _a.label; };
    return (react_1.default.createElement("div", { className: 'alternative-titles-table-container' },
        react_1.default.createElement("h5", null, "Alternative Titles"),
        viewWork.alternativeTitles.length > 0 ? (react_1.default.createElement("div", { className: 'container' },
            react_1.default.createElement("table", { className: 'alternative-titles-table' },
                react_1.default.createElement("thead", null,
                    react_1.default.createElement("tr", null,
                        react_1.default.createElement("th", null, "Alternative Title"),
                        react_1.default.createElement("th", null, "Alternative Title Type"),
                        react_1.default.createElement("th", null, "Language"))),
                react_1.default.createElement("tbody", null, viewWork.alternativeTitles.map((altTitle, idx) => {
                    var _a, _b, _c, _d, _e;
                    return (react_1.default.createElement("tr", { key: idx },
                        react_1.default.createElement("td", null, (0, string_escape_newlines_1.stringEscapeNewlines)(altTitle.title)),
                        react_1.default.createElement("td", null, altTitle.titleType ? formatTitleType(altTitle.titleType) : '--'),
                        react_1.default.createElement("td", null, altTitle.languageCode
                            ? (0, format_language_1.formatLanguage)(langOptions, altTitle.languageCode)
                            : '--'),
                        ((_b = (_a = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _a === void 0 ? void 0 : _a.title) === null || _b === void 0 ? void 0 : _b._errors[0]) && (react_1.default.createElement("td", { className: 'error-field' }, (_e = (_d = (_c = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _c === void 0 ? void 0 : _c.title) === null || _d === void 0 ? void 0 : _d._errors) === null || _e === void 0 ? void 0 : _e.join(', ')))));
                }))))) : (react_1.default.createElement("span", { className: 'no-alternative-titles-label' }, "No Alternative Titles"))));
};
exports.AlternativeTitlesTable = AlternativeTitlesTable;
