"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidWorkType = void 0;
const workTypeTypes = [
    'bulk',
    'work',
    'history',
    'draft',
    'create-form',
    'create-wizard',
    'claim-wizard',
    'overclaim',
];
const isValidWorkType = (type) => {
    if (!type) {
        return false;
    }
    const s = workTypeTypes;
    return s.includes(type);
};
exports.isValidWorkType = isValidWorkType;
