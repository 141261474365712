"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkUploadStore = void 0;
const workAPI = __importStar(require("@App/api/routes/work"));
const mobx_1 = require("mobx");
const calc_num_pages_1 = require("@App/helpers/pagination/calc-num-pages");
const find_work_by_local_id_1 = require("@App/helpers/find-work-by-local-id");
const bulk_upload_error_type_1 = require("@App/enum/bulk-upload-error-type");
class BulkUploadStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_workFileDraftLocal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_allWorksFromUpload", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_worksFromUpload", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_currentFileId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_worksSubmissionsCurrent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_worksErrorFromUploadPaginationInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_worksCompleteFromUploadPaginationInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_modalErrorType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_modalErrorMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_startTitlePositionWorkSubmissions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "destroyWorkFileDraftLocal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this._workFileDraftLocal = undefined;
                this.rootStore.workStore.setWizardStepValidation(false);
            }
        });
        (0, mobx_1.makeObservable)(this, {
            _workFileDraftLocal: mobx_1.observable,
            _allWorksFromUpload: mobx_1.observable,
            _worksFromUpload: mobx_1.observable,
            _currentFileId: mobx_1.observable,
            _worksSubmissionsCurrent: mobx_1.observable,
            _worksErrorFromUploadPaginationInfo: mobx_1.observable,
            _worksCompleteFromUploadPaginationInfo: mobx_1.observable,
            _modalErrorType: mobx_1.observable,
            _modalErrorMessage: mobx_1.observable,
            _startTitlePositionWorkSubmissions: mobx_1.observable,
            getCurrentWorksFromUploadFile: mobx_1.action,
            setInfoModalForBulkUpload: mobx_1.action,
            setWorkFileDraftToLocal: mobx_1.action,
            setAllWorksFromUploadToLocal: mobx_1.action,
            setPageWorksFromUploadToLocal: mobx_1.action,
            setCurrentFileId: mobx_1.action,
            setStartTitlePositionWorkSubmissions: mobx_1.action,
            destroyAllWorksFromUpload: mobx_1.action,
            destroyWorksOnCurrentPage: mobx_1.action,
            destroyStartTitlePositionWorkSubmissions: mobx_1.action,
            destroyWorkFileDraftLocal: mobx_1.action,
        });
        this.rootStore = rootStore;
    }
    saveUploadWorks(rightsHolderId, works) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return yield workAPI.saveUploadWorks(rightsHolderId, works);
            }
            catch (e) {
                this.rootStore.popupStore.setAlertFromError(e);
            }
        });
    }
    getCompleteWorksFromUpload(rightsHolderId, pagination = {
        page: 1,
        limit: 10000,
    }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield workAPI.getCompleteWorksFromUpload(rightsHolderId, this._currentFileId, pagination);
                (0, mobx_1.runInAction)(() => {
                    this._worksSubmissionsCurrent = response.data;
                });
            }
            catch (e) {
                this.rootStore.popupStore.setAlertFromError(e);
            }
        });
    }
    getCurrentWorksFromUploadFile(rightsHolderId, pagination = {
        page: 1,
        limit: 1000,
    }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const file = this._workFileDraftLocal;
                if (!file) {
                    this.setInfoModalForBulkUpload(undefined);
                    return;
                }
                const allWorksFromUpload = this._allWorksFromUpload;
                const { limit, page } = pagination;
                const data = new FormData();
                // for the first call
                if (!allWorksFromUpload || !allWorksFromUpload) {
                    data.append('file', file, file.name);
                }
                else {
                    const fileId = allWorksFromUpload.fileId;
                    data.append('file', fileId.toString());
                }
                const response = yield workAPI.getCurrentWorksFromUploadFile(rightsHolderId, pagination, data);
                let worksResponse = response.data;
                if (page && limit) {
                    worksResponse = this.getAllWorksFromUploadResponseFirstCall(worksResponse, allWorksFromUpload);
                }
                // for the first call
                if (!allWorksFromUpload || !allWorksFromUpload) {
                    (0, mobx_1.runInAction)(() => (this._allWorksFromUpload = worksResponse));
                }
                else {
                    this.getAllWorksFromUploadResponseNotFirstCall(allWorksFromUpload, worksResponse);
                }
                (0, mobx_1.runInAction)(() => {
                    this._worksFromUpload = worksResponse;
                    if (pagination.limit && pagination.page && worksResponse) {
                        const dataForErrorWorks = (0, calc_num_pages_1.calcNumPages)(worksResponse.errorCount, pagination.limit, pagination.page);
                        const dataForCompleteWorks = (0, calc_num_pages_1.calcNumPages)(worksResponse.completeCount, pagination.limit, pagination.page);
                        this._worksErrorFromUploadPaginationInfo = dataForErrorWorks;
                        this._worksCompleteFromUploadPaginationInfo = dataForCompleteWorks;
                    }
                });
            }
            catch (e) {
                this.setInfoModalForBulkUpload(e);
                throw e;
            }
        });
    }
    formatWorksForSaving(workDraftFromLocal) {
        let arrayWorksOnCurrentPage = this.worksFromUpload;
        let arrayAllWorks = this.allWorksFromUpload;
        if (!arrayWorksOnCurrentPage || !arrayAllWorks) {
            return;
        }
        let newWork = Object.assign({}, workDraftFromLocal);
        if (newWork.isComplete) {
            let completeArrayAllWorks = [...arrayAllWorks.complete];
            let completeArrayPageWorks = [...arrayWorksOnCurrentPage.complete];
            // changed old complete work to new complete
            completeArrayAllWorks = completeArrayAllWorks.map((item) => item.localId === newWork.localId ? newWork : item);
            completeArrayPageWorks = completeArrayPageWorks.map((item) => item.localId === newWork.localId ? newWork : item);
            arrayAllWorks = Object.assign(Object.assign({}, arrayAllWorks), { complete: [...completeArrayAllWorks] });
            arrayWorksOnCurrentPage = Object.assign(Object.assign({}, arrayWorksOnCurrentPage), { complete: [...completeArrayPageWorks] });
        }
        else {
            newWork = Object.assign(Object.assign({}, newWork), { isComplete: true });
            // remove from error
            const newAllErrorWorks = arrayAllWorks.error.filter((item) => item.localId !== newWork.localId);
            const newErrorWorksPage = arrayWorksOnCurrentPage.error.filter((item) => item.localId !== newWork.localId);
            // add to complete
            const newCompleteCount = arrayAllWorks.complete.length + 1;
            const newCompleteCountCurrent = arrayWorksOnCurrentPage.complete.length + 1;
            arrayAllWorks = Object.assign(Object.assign({}, arrayAllWorks), { error: [...newAllErrorWorks], errorCount: newAllErrorWorks.length, complete: [...arrayAllWorks.complete, newWork], completeCount: newCompleteCount });
            arrayWorksOnCurrentPage = Object.assign(Object.assign({}, arrayWorksOnCurrentPage), { error: [...newErrorWorksPage], errorCount: newErrorWorksPage.length, complete: [...arrayWorksOnCurrentPage.complete, newWork], completeCount: newCompleteCountCurrent });
        }
        this.setAllWorksFromUploadToLocal(arrayAllWorks);
        this.setPageWorksFromUploadToLocal(arrayWorksOnCurrentPage);
    }
    setInfoModalForBulkUpload(error) {
        if (!error) {
            this._modalErrorType = undefined;
            this._modalErrorMessage = null;
            return;
        }
        this._modalErrorType = this.getStatusCode(error);
        this._modalErrorMessage = error.message;
        if (this._modalErrorType === bulk_upload_error_type_1.BulkUploadErrorType.UNKNOWN) {
            throw new Error(error.message ? error.message : 'Something went wrong');
        }
    }
    setWorkFileDraftToLocal(file) {
        this._workFileDraftLocal = file;
        this.rootStore.workStore.setWizardStepValidation(!!this._workFileDraftLocal);
    }
    setAllWorksFromUploadToLocal(allWorks) {
        this._allWorksFromUpload = allWorks;
    }
    setPageWorksFromUploadToLocal(works) {
        this._worksFromUpload = works;
    }
    setCurrentFileId(fileId) {
        this._currentFileId = fileId;
    }
    setStartTitlePositionWorkSubmissions(position) {
        this._startTitlePositionWorkSubmissions = position;
    }
    get workFileDraftLocal() {
        return this._workFileDraftLocal;
    }
    get worksErrorFromUploadPaginationInfo() {
        return this._worksErrorFromUploadPaginationInfo;
    }
    get worksCompleteFromUploadPaginationInfo() {
        return this._worksCompleteFromUploadPaginationInfo;
    }
    get modalErrorMessage() {
        return this._modalErrorMessage;
    }
    get worksSubmissionsCurrent() {
        return this._worksSubmissionsCurrent;
    }
    get allWorksFromUpload() {
        return this._allWorksFromUpload;
    }
    get worksFromUpload() {
        return this._worksFromUpload;
    }
    get currentFileId() {
        return this._currentFileId;
    }
    get modalErrorType() {
        return this._modalErrorType;
    }
    get startTitlePositionWorkSubmissions() {
        return this._startTitlePositionWorkSubmissions;
    }
    destroyAllWorksFromUpload() {
        this._allWorksFromUpload = undefined;
    }
    destroyWorksOnCurrentPage() {
        this._worksFromUpload = undefined;
    }
    destroyStartTitlePositionWorkSubmissions() {
        this._startTitlePositionWorkSubmissions = undefined;
    }
    getAllWorksFromUploadResponseFirstCall(worksResponse, allWorksFromUpload) {
        let newErrorWorks = [];
        let newCompleteWorks = [];
        if (allWorksFromUpload && allWorksFromUpload) {
            const works = allWorksFromUpload;
            worksResponse.error.forEach((item) => {
                const localId = item.localId ? item.localId : '-1';
                const existingWork = (0, find_work_by_local_id_1.findWorkByLocalId)(localId, works);
                // if work with this localId exist
                if (existingWork) {
                    // if this work complete now - set to complete
                    if (existingWork.isComplete) {
                        newCompleteWorks = [...newCompleteWorks, existingWork];
                    }
                    else {
                        // if this work error - set to error
                        newErrorWorks = [...newErrorWorks, existingWork];
                    }
                }
                else {
                    const newWork = Object.assign({}, item);
                    newErrorWorks = [...newErrorWorks, newWork];
                }
            });
            worksResponse.complete.forEach((item) => {
                const localId = item.localId ? item.localId : '-1';
                const existingWork = (0, find_work_by_local_id_1.findWorkByLocalId)(localId, works);
                // if work with this localId exist
                if (existingWork) {
                    // if this work complete now - set to complete
                    if (existingWork.isComplete) {
                        newCompleteWorks = [...newCompleteWorks, existingWork];
                    }
                    else {
                        // if this work error - set to error
                        newErrorWorks = [...newErrorWorks, existingWork];
                    }
                }
                else {
                    const newWork = Object.assign({}, item);
                    newCompleteWorks = [...newCompleteWorks, newWork];
                }
            });
        }
        else {
            worksResponse.error.forEach((item) => {
                const newWork = Object.assign({}, item);
                newErrorWorks = [...newErrorWorks, newWork];
            });
            worksResponse.complete.forEach((item) => {
                const newWork = Object.assign({}, item);
                newCompleteWorks = [...newCompleteWorks, newWork];
            });
        }
        return Object.assign(Object.assign({}, worksResponse), { error: newErrorWorks, complete: newCompleteWorks });
    }
    getAllWorksFromUploadResponseNotFirstCall(allWorksFromUpload, worksResponse) {
        let allWorks = allWorksFromUpload;
        // Set current work page to array, that contain all work
        worksResponse.error.forEach((currentWork) => {
            const isNewWorkFromError = allWorks.error.every((work) => work.localId !== currentWork.localId);
            const isNewWorkFromComplete = allWorks.complete.every((work) => work.localId !== currentWork.localId);
            // if this work doesn't exist
            if (isNewWorkFromError && isNewWorkFromComplete) {
                allWorks = Object.assign(Object.assign({}, allWorks), { error: [...allWorks.error, currentWork] });
            }
        });
        worksResponse.complete.forEach((currentWork) => {
            const isNewWork = allWorks.complete.every((work) => work.localId !== currentWork.localId);
            if (isNewWork) {
                allWorks = Object.assign(Object.assign({}, allWorks), { complete: [...allWorks.complete, currentWork] });
            }
        });
        (0, mobx_1.runInAction)(() => (this._allWorksFromUpload = allWorks));
    }
    getStatusCode(error) {
        switch (error.statusCode) {
            case 500:
                return bulk_upload_error_type_1.BulkUploadErrorType.PARSE_ERROR;
            case 413:
            case 415:
                return bulk_upload_error_type_1.BulkUploadErrorType.FILE_TOO_LARGE;
            case 400:
                if (error.message.toLowerCase().includes('required')) {
                    return bulk_upload_error_type_1.BulkUploadErrorType.REQUIRED;
                }
                if (error.message.toLowerCase().includes('duplicate')) {
                    return bulk_upload_error_type_1.BulkUploadErrorType.DUPLICATE_TITLE;
                }
        }
        return bulk_upload_error_type_1.BulkUploadErrorType.UNKNOWN;
    }
}
exports.BulkUploadStore = BulkUploadStore;
