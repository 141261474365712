"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findPageOnLimitChange = void 0;
const get_num_titles_1 = require("@App/helpers/pagination/get-num-titles");
const findPageOnLimitChange = (pagination, workCount, newLimit, defaultPage = 0) => {
    var _a, _b;
    const currentPage = (_a = pagination.page) !== null && _a !== void 0 ? _a : defaultPage;
    const numTitles = (0, get_num_titles_1.getNumTitles)(workCount, currentPage, (_b = pagination.limit) !== null && _b !== void 0 ? _b : 10);
    return Math.ceil(Math.max(numTitles.firstTitle / newLimit, 1));
};
exports.findPageOnLimitChange = findPageOnLimitChange;
