"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.determineChangeValue = exports.isPostcodeRequiredCountry = exports.isPhoneCodeUS = exports.isUSorCanada = exports.isCountryCAN = exports.isCountryUS = exports.doesFieldHaveFocus = void 0;
const doesFieldHaveFocus = (name) => document.hasFocus() &&
    !!document.activeElement &&
    !!document.activeElement.attributes[0] &&
    name === document.activeElement.attributes[0].name;
exports.doesFieldHaveFocus = doesFieldHaveFocus;
const isCountryUS = (value) => value === '840' || value === 840;
exports.isCountryUS = isCountryUS;
const isCountryCAN = (value) => value === '124' || value === 124;
exports.isCountryCAN = isCountryCAN;
const isUSorCanada = (value) => (0, exports.isCountryUS)(value) || (0, exports.isCountryCAN)(value);
exports.isUSorCanada = isUSorCanada;
const isPhoneCodeUS = (value) => value === '1' || value === 1;
exports.isPhoneCodeUS = isPhoneCodeUS;
// Post Codes Are Required For The Following Countries In Trulioo
// South Africa, Germany, UK, Mexico, Japan, Canada, United States
const isPostcodeRequiredCountry = (countryId) => [710, 276, 826, 484, 392, 124, 840].includes(+countryId);
exports.isPostcodeRequiredCountry = isPostcodeRequiredCountry;
const determineChangeValue = (value) => {
    if (value) {
        if (!Array.isArray(value)) {
            return value.value;
        }
        else {
            return value[0].value;
        }
    }
    return value;
};
exports.determineChangeValue = determineChangeValue;
