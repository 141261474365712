"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flattenObject = void 0;
const flattenObject = (obj) => {
    const flattened = {};
    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            Object.assign(flattened, (0, exports.flattenObject)(value));
        }
        else {
            flattened[key] = value;
        }
    });
    return flattened;
};
exports.flattenObject = flattenObject;
