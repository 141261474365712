"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
require("./index.scss");
const TabsAlertMessage_1 = require("@Components/Popups/TabsAlertMessage");
const string_to_inline_1 = require("@App/helpers/string-to-inline");
const react_router_dom_1 = require("react-router-dom");
const TabsWrapperComponent = (props) => {
    var _a;
    const { tabs, defaultTab, onTabChange } = props;
    const [activeTabName, setActiveTabName] = (0, react_1.useState)(defaultTab);
    const [activeLineStyle, setActiveLineStyle] = (0, react_1.useState)();
    const tabsContainerRef = (0, react_1.useRef)(null);
    const location = (0, react_router_dom_1.useLocation)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const onTab = (tabName) => {
        setActiveTabName(tabName);
        setUrl(tabName);
        if (onTabChange) {
            onTabChange(tabName);
        }
    };
    const setUrl = (tabName) => {
        const activeHash = '#' + (0, string_to_inline_1.stringToInline)(tabName);
        const tabLocation = location.pathname + activeHash;
        navigate(tabLocation, { replace: true });
    };
    const checkHash = () => {
        const hash = location.hash.split('#')[1];
        const currentTab = tabs.map((tab) => tab.tabName).find((name) => hash === (0, string_to_inline_1.stringToInline)(name));
        if (currentTab) {
            setActiveTabName(currentTab);
            setActiveLineStyle(getActiveLineStyleForTabName(currentTab));
        }
        else {
            setUrl(defaultTab);
        }
    };
    (0, react_1.useEffect)(() => {
        checkHash();
    }, [location]);
    const getActiveLineStyleForTabName = (tabName) => {
        const tabNamesLocal = tabs.map((tab) => tab.tabName);
        const startIndex = tabNamesLocal.findIndex((item) => item === tabName);
        const activeTab = tabsContainerRef.current && tabsContainerRef.current.children[startIndex];
        const tabWidth = activeTab === null || activeTab === void 0 ? void 0 : activeTab.offsetWidth;
        const tabLeft = activeTab === null || activeTab === void 0 ? void 0 : activeTab.offsetLeft;
        return { transform: `translateX(${tabLeft}px)`, width: tabWidth };
    };
    return (react_1.default.createElement("div", { className: "tabs-wrapper-component" },
        react_1.default.createElement("div", { className: 'tabs-row' },
            react_1.default.createElement("div", { ref: tabsContainerRef, id: 'tabs-container', className: 'tabs-container' }, tabs
                .map((tab) => tab.tabName)
                .map((value, index) => (react_1.default.createElement("p", { key: index, onClick: () => onTab(value), className: 'small bold' }, value)))),
            react_1.default.createElement("div", { className: 'active-line', style: activeLineStyle })),
        react_1.default.createElement(TabsAlertMessage_1.TabsAlertMessage, { currentTab: activeTabName }),
        react_1.default.createElement("div", { className: 'tabs-content' }, (_a = tabs.find((tab) => tab.tabName === activeTabName)) === null || _a === void 0 ? void 0 : _a.tabComponent)));
};
exports.default = TabsWrapperComponent;
