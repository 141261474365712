"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
const publisher_form_type_1 = require("@App/enum/publisher-form-type");
const removers_1 = require("@App/helpers/work-entities/removers");
const publisher_side_bar_state_1 = require("@App/enum/publisher-side-bar-state");
class PublisherStepStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_originalPublisherData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_administratorPublisherData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_formType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_editingOP", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_currentOP", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "initAddAP", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (currentOP) => {
                this._currentOP = currentOP;
                this.rootStore.popupStore.setPublisherSidebarState(publisher_side_bar_state_1.PublisherSideBarState.addAP);
            }
        });
        Object.defineProperty(this, "initEditOriginalPublisher", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (editingOP) => {
                this._editingOP = editingOP;
                this.rootStore.popupStore.setPublisherSidebarState(publisher_side_bar_state_1.PublisherSideBarState.editOP);
            }
        });
        Object.defineProperty(this, "initAddWriterToOriginalPublisher", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (editingOP) => {
                this._editingOP = editingOP;
                this.rootStore.popupStore.setPublisherSidebarState(publisher_side_bar_state_1.PublisherSideBarState.addWriterOp);
            }
        });
        Object.defineProperty(this, "addOriginalPublisherToDraft", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const workDraftFromLocal = this.rootStore.workStore.workDraftLocal;
                if (!workDraftFromLocal) {
                    return;
                }
                const originalPublishers = workDraftFromLocal.originalPublishers;
                const lastOpId = originalPublishers.length
                    ? Number(originalPublishers[originalPublishers.length - 1].localId)
                    : 0;
                const updatedWorkDraft = Object.assign(Object.assign({}, workDraftFromLocal), { originalPublishers: [
                        ...originalPublishers,
                        Object.assign(Object.assign({}, this._originalPublisherData), { amend: true, ipiNumber: this._originalPublisherData.ipiNumber
                                ? this._originalPublisherData.ipiNumber.padStart(11, '0')
                                : null, localId: lastOpId + 1 }),
                    ] });
                this.rootStore.workStore.setWorkDraftToLocal(updatedWorkDraft);
                this.rootStore.popupStore.setPublisherSidebarState(publisher_side_bar_state_1.PublisherSideBarState.empty);
            }
        });
        Object.defineProperty(this, "opItemWasAdded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (publisherName) => {
                const workDraftFromLocal = this.rootStore.workStore.workDraftLocal;
                if (!workDraftFromLocal) {
                    return false;
                }
                const theSamePublisher = workDraftFromLocal.originalPublishers.find((item) => item.publisherName === publisherName);
                if (!theSamePublisher) {
                    return false;
                }
                const publisherHasAdministrator = theSamePublisher.administratorPublishers.length > 0;
                return !!theSamePublisher || publisherHasAdministrator;
            }
        });
        Object.defineProperty(this, "apItemWasAdded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (publisherName) => {
                const workDraftFromLocal = this.rootStore.workStore.workDraftLocal;
                if (!workDraftFromLocal) {
                    return;
                }
                const originalPublishers = workDraftFromLocal.originalPublishers;
                const administratorsAreEqual = this._currentOP.administratorPublishers.findIndex((item) => publisherName === item.publisherName) !== -1;
                const publishersAreEqual = publisherName === this._currentOP.publisherName;
                let adminIsInOtherPublisher = false;
                originalPublishers.forEach((publisherItem) => {
                    if (this._currentOP.publisherName === publisherItem.publisherName) {
                        publisherItem.administratorPublishers.forEach((adminItem) => {
                            if (adminItem.publisherName === publisherName) {
                                adminIsInOtherPublisher = true;
                                return;
                            }
                        });
                    }
                });
                return administratorsAreEqual || publishersAreEqual || adminIsInOtherPublisher;
            }
        });
        Object.defineProperty(this, "setFoundAdministratorPublisher", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (publisher) => {
                this.rootStore.workStore.setFoundAdministratorPublisher(publisher, this._currentOP);
            }
        });
        (0, mobx_1.makeObservable)(this, {
            _originalPublisherData: mobx_1.observable,
            _administratorPublisherData: mobx_1.observable,
            _formType: mobx_1.observable,
            _editingOP: mobx_1.observable,
            _currentOP: mobx_1.observable,
            setFormType: mobx_1.action,
            initAddAP: mobx_1.action,
            initEditOriginalPublisher: mobx_1.action,
            setOriginalPublisherData: mobx_1.action,
            setAdministratorPublisherData: mobx_1.action,
            resetStore: mobx_1.action,
        });
        this.rootStore = rootStore;
        this._formType = publisher_form_type_1.PublisherFormType.add;
        this._originalPublisherData = {
            publisherName: '',
            publisherShare: null,
            ipiNumber: null,
            lastUpdated: '',
            administratorPublishers: [],
            writers: [],
        };
        this._administratorPublisherData = {
            publisherName: '',
            publisherShare: null,
            lastUpdated: '',
            ipiNumber: '',
        };
    }
    get formType() {
        return this._formType;
    }
    get originalPublisherData() {
        return this._originalPublisherData;
    }
    get administratorPublisherData() {
        return this._administratorPublisherData;
    }
    get editingOP() {
        return this._editingOP;
    }
    setFormType(formType) {
        this._formType = formType;
    }
    setOriginalPublisherData(originalPublisherData) {
        this._originalPublisherData = originalPublisherData;
    }
    setAdministratorPublisherData(administratorPublisherData) {
        this._administratorPublisherData = administratorPublisherData;
    }
    addAdministratorPublisherToDraft() {
        const workDraftFromLocal = this.rootStore.workStore.workDraftLocal;
        if (!workDraftFromLocal) {
            return;
        }
        const originalPublishers = workDraftFromLocal.originalPublishers;
        const currentOPid = this._currentOP.localId;
        const administratorPublishers = this._currentOP.administratorPublishers;
        const lastAPid = administratorPublishers.length
            ? Number(administratorPublishers[administratorPublishers.length - 1].localId)
            : 0;
        const indexOfOP = originalPublishers.map((op) => op.localId).indexOf(currentOPid);
        const newAdministratorPublisher = {
            localId: lastAPid + 1,
            amend: true,
            publisherName: this._administratorPublisherData.publisherName,
            ipiNumber: this._administratorPublisherData.ipiNumber
                ? this._administratorPublisherData.ipiNumber.padStart(11, '0')
                : null,
            lastUpdated: this._administratorPublisherData.lastUpdated,
            publisherShare: 0,
            hfaPublisherNumber: this._administratorPublisherData.hfaPublisherNumber,
        };
        const updatedCurrentOP = Object.assign(Object.assign({}, this._currentOP), { publisherShare: 0, administratorPublishers: [...this._currentOP.administratorPublishers, newAdministratorPublisher] });
        const updatedOPArray = (0, removers_1.setItemToArrayByIndex)(originalPublishers, +indexOfOP, updatedCurrentOP);
        const updatedWorkDraft = Object.assign(Object.assign({}, workDraftFromLocal), { originalPublishers: updatedOPArray });
        this.rootStore.workStore.setWorkDraftToLocal(updatedWorkDraft);
        this.rootStore.popupStore.setPublisherSidebarState(publisher_side_bar_state_1.PublisherSideBarState.empty);
    }
    resetStore() {
        this._formType = publisher_form_type_1.PublisherFormType.add;
        this._originalPublisherData = {
            publisherName: '',
            publisherShare: null,
            ipiNumber: null,
            lastUpdated: '',
            administratorPublishers: [],
            writers: [],
        };
        this._administratorPublisherData = {
            publisherName: '',
            publisherShare: null,
            lastUpdated: '',
            ipiNumber: '',
        };
    }
}
exports.default = PublisherStepStore;
