"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PopupStore = void 0;
const _Types_1 = require("@Types");
const mobx_1 = require("mobx");
const publisher_side_bar_state_1 = require("@App/enum/publisher-side-bar-state");
class PopupStore {
    constructor() {
        Object.defineProperty(this, "_alertData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_tabsAlertData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_rightsholderTrayState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_publisherSidebarState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        (0, mobx_1.makeObservable)(this, {
            _alertData: mobx_1.observable,
            _tabsAlertData: mobx_1.observable,
            _rightsholderTrayState: mobx_1.observable,
            _publisherSidebarState: mobx_1.observable,
            setAlertData: mobx_1.action,
            setAlertFromError: mobx_1.action,
            setTabsAlertData: mobx_1.action,
            setRightsholderTrayState: mobx_1.action,
            setPublisherSidebarState: mobx_1.action,
        });
        this._rightsholderTrayState = false;
        this._alertData = _Types_1.WithRequestMetadata.empty();
        this._tabsAlertData = _Types_1.WithRequestMetadata.empty();
        this._publisherSidebarState = publisher_side_bar_state_1.PublisherSideBarState.empty;
    }
    get alertData() {
        return this._alertData.data;
    }
    get rightsholderTrayState() {
        return this._rightsholderTrayState;
    }
    get tabsAlertData() {
        return this._tabsAlertData.data;
    }
    get publisherSidebarState() {
        return this._publisherSidebarState;
    }
    setAlertData(message, isError, dontHide = false) {
        const data = { message, isError };
        this._alertData = _Types_1.WithRequestMetadata.data(data);
        if (message && !dontHide) {
            setTimeout(() => {
                this.setAlertData('', isError);
            }, 5000);
        }
    }
    setAlertFromError(error) {
        if (error instanceof Error) {
            this.setAlertData(error.message, true);
        }
        else {
            this.setAlertData('Something Has Gone Wrong', true);
        }
    }
    setTabsAlertData(message, tabName, isError, timeoutOff) {
        const data = { message, tabName, isError };
        this._tabsAlertData = _Types_1.WithRequestMetadata.data(data);
        if (message && !timeoutOff) {
            setTimeout(() => {
                this.setTabsAlertData('', tabName, isError);
            }, 5000);
        }
    }
    setRightsholderTrayState(state) {
        this._rightsholderTrayState = state;
    }
    setPublisherSidebarState(state) {
        this._publisherSidebarState = state;
    }
}
exports.PopupStore = PopupStore;
