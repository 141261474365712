"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRightsholderContactType = void 0;
const rightsholder_contact_types_1 = require("@App/enum/rightsholder-contact-types");
const getRightsholderContactType = (contactType) => {
    switch (contactType) {
        case rightsholder_contact_types_1.RightsholderContactTypes.PUBLIC:
            return 'Public';
        case rightsholder_contact_types_1.RightsholderContactTypes.LEGAL:
            return 'Legal';
        case rightsholder_contact_types_1.RightsholderContactTypes.FINANCE:
            return 'Finance';
        case rightsholder_contact_types_1.RightsholderContactTypes.COPYRIGHT:
            return 'Copyright';
        default:
            return '';
    }
};
exports.getRightsholderContactType = getRightsholderContactType;
