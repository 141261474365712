"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapWorkSearchResults = exports.SEARCH_OVERCLAIM_WORK_OPTIONS = exports.SEARCH_CATALOG_WORK_OPTIONS = exports.SEARCH_REG_HISTORY_WORK_OPTIONS = exports.SEARCH_WORK_OPTIONS = void 0;
const uuid_1 = require("uuid");
exports.SEARCH_WORK_OPTIONS = [
    { id: (0, uuid_1.v4)(), value: 'title', label: 'Work Title', isPrimary: true },
    { id: (0, uuid_1.v4)(), value: 'writerName', label: 'Writer Name' },
    { id: (0, uuid_1.v4)(), value: 'iswc', label: 'ISWC', isPrimary: true },
    { id: (0, uuid_1.v4)(), value: 'songCode', label: 'MLC Song Code', isPrimary: true },
    { id: (0, uuid_1.v4)(), value: 'writerIpi', label: 'Writer IPI' },
    { id: (0, uuid_1.v4)(), value: 'publisherName', label: 'Publisher Name' },
    { id: (0, uuid_1.v4)(), value: 'publisherIpi', label: 'Publisher IPI' },
    { id: (0, uuid_1.v4)(), value: 'publisherNumber', label: 'MLC Publisher Number' },
];
exports.SEARCH_REG_HISTORY_WORK_OPTIONS = [
    { id: (0, uuid_1.v4)(), value: 'title', label: 'Work Title', isPrimary: true },
    { id: (0, uuid_1.v4)(), value: 'writerName', label: 'Writer Name' },
    { id: (0, uuid_1.v4)(), value: 'writerIpi', label: 'Writer IPI' },
    { id: (0, uuid_1.v4)(), value: 'publisherName', label: 'Publisher Name' },
    { id: (0, uuid_1.v4)(), value: 'publisherIpi', label: 'Publisher IPI' },
    { id: (0, uuid_1.v4)(), value: 'recordingName', label: 'Recording Title' },
    { id: (0, uuid_1.v4)(), value: 'artist', label: 'Artist' },
    { id: (0, uuid_1.v4)(), value: 'isrc', label: 'ISRC' },
];
exports.SEARCH_CATALOG_WORK_OPTIONS = [
    { id: (0, uuid_1.v4)(), value: 'title', label: 'Work Title', isPrimary: true },
    { id: (0, uuid_1.v4)(), value: 'writerName', label: 'Writer Name' },
    { id: (0, uuid_1.v4)(), value: 'writerIpi', label: 'Writer IPI' },
    { id: (0, uuid_1.v4)(), value: 'iswc', label: 'ISWC', isPrimary: true },
    { id: (0, uuid_1.v4)(), value: 'songCode', label: 'MLC Song Code', isPrimary: true },
    { id: (0, uuid_1.v4)(), value: 'publisherName', label: 'Publisher Name' },
    { id: (0, uuid_1.v4)(), value: 'publisherIpi', label: 'Publisher IPI' },
    { id: (0, uuid_1.v4)(), value: 'publisherNumber', label: 'MLC Publisher Number' },
    { id: (0, uuid_1.v4)(), value: 'memberProprietaryId', label: 'Member Work Identifier', isPrimary: true, isUnique: true },
    { id: (0, uuid_1.v4)(), value: 'recordingName', label: 'Recording Title' },
    { id: (0, uuid_1.v4)(), value: 'artist', label: 'Artist' },
    { id: (0, uuid_1.v4)(), value: 'isrc', label: 'ISRC' },
];
exports.SEARCH_OVERCLAIM_WORK_OPTIONS = [
    { id: (0, uuid_1.v4)(), value: 'title', label: 'Work Title', isPrimary: true },
    { id: (0, uuid_1.v4)(), value: 'songCode', label: 'MLC Song Code', isPrimary: true },
    { id: (0, uuid_1.v4)(), value: 'overclaimId', label: 'Overclaim ID', isPrimary: true },
];
const mapWorkSearchResults = (result) => {
    const works = result.results.map((item) => (Object.assign({}, item.entity)));
    return {
        count: result.count,
        works: works,
    };
};
exports.mapWorkSearchResults = mapWorkSearchResults;
