"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
class WizardFlowStore {
    constructor() {
        Object.defineProperty(this, "_currentIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "_currentSubIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "_flow", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "setFlow", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (flow) => {
                this._flow = [...flow];
            }
        });
        Object.defineProperty(this, "resetIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this._currentIndex = 0;
                this._currentSubIndex = 0;
            }
        });
        Object.defineProperty(this, "setCurrentIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (index) => {
                this._currentIndex = index;
            }
        });
        Object.defineProperty(this, "advanceIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                ++this._currentIndex;
            }
        });
        Object.defineProperty(this, "decrementIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                --this._currentIndex;
            }
        });
        Object.defineProperty(this, "setCurrentSubIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (index) => {
                this._currentSubIndex = index;
            }
        });
        Object.defineProperty(this, "advanceSubIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                ++this._currentSubIndex;
            }
        });
        Object.defineProperty(this, "decrementSubIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                --this._currentSubIndex;
            }
        });
        (0, mobx_1.makeAutoObservable)(this, { setFlow: mobx_1.action });
    }
    get currentFlowStep() {
        return this._flow[this._currentIndex];
    }
    get flowLength() {
        return this._flow.length;
    }
    get flow() {
        return this._flow;
    }
    get currentIndex() {
        return this._currentIndex;
    }
    get currentSubIndex() {
        return this._currentSubIndex;
    }
}
exports.default = WizardFlowStore;
