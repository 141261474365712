"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublisherSideBarState = void 0;
var PublisherSideBarState;
(function (PublisherSideBarState) {
    PublisherSideBarState[PublisherSideBarState["addAP"] = 0] = "addAP";
    PublisherSideBarState[PublisherSideBarState["addOP"] = 1] = "addOP";
    PublisherSideBarState[PublisherSideBarState["editOP"] = 2] = "editOP";
    PublisherSideBarState[PublisherSideBarState["addWriterOp"] = 3] = "addWriterOp";
    PublisherSideBarState[PublisherSideBarState["empty"] = 4] = "empty";
})(PublisherSideBarState = exports.PublisherSideBarState || (exports.PublisherSideBarState = {}));
