"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const removers_1 = require("@App/helpers/work-entities/removers");
const react_1 = require("react");
const contexts_1 = require("@App/contexts");
const usePublisherService = () => {
    const { workStore } = (0, react_1.useContext)(contexts_1.StoresContext);
    const workDraftFromLocal = workStore.workDraftLocal;
    const updatePublisherShare = (publisherModel, newValue) => {
        if (!workDraftFromLocal) {
            return;
        }
        const updatedPublisherModel = Object.assign(Object.assign({}, publisherModel), { amend: true, publisherShare: newValue });
        const indexOfOP = workDraftFromLocal.originalPublishers
            .map((op) => op.localId)
            .indexOf(publisherModel.localId);
        const updatedOpArray = (0, removers_1.setItemToArrayByIndex)(workDraftFromLocal.originalPublishers, +indexOfOP, updatedPublisherModel);
        const updatedWorkDraft = Object.assign(Object.assign({}, workDraftFromLocal), { originalPublishers: updatedOpArray });
        workStore.setWorkDraftToLocal(updatedWorkDraft);
    };
    const updateAdministratorPublisherShare = (originalPublisherModel, adminModel, newValue) => {
        if (!workDraftFromLocal) {
            return;
        }
        const updatedAP = Object.assign(Object.assign({}, adminModel), { amend: true, publisherShare: newValue });
        const indexOfAP = originalPublisherModel.administratorPublishers
            .map((ap) => ap.localId)
            .indexOf(adminModel.localId);
        const updatedApArray = (0, removers_1.setItemToArrayByIndex)(originalPublisherModel.administratorPublishers, +indexOfAP, updatedAP);
        const updatedParentOp = Object.assign(Object.assign({}, originalPublisherModel), { administratorPublishers: updatedApArray });
        const indexOfOp = workDraftFromLocal.originalPublishers
            .map((op) => op.localId)
            .indexOf(originalPublisherModel.localId);
        const updatedOpArray = (0, removers_1.setItemToArrayByIndex)(workDraftFromLocal.originalPublishers, +indexOfOp, updatedParentOp);
        const updatedWorkDraft = Object.assign(Object.assign({}, workDraftFromLocal), { originalPublishers: updatedOpArray });
        workStore.setWorkDraftToLocal(updatedWorkDraft);
    };
    const removeLinkedWriter = (publisher, removingWriter) => {
        const data = publisher;
        const editingOPId = data.localId;
        if (!workDraftFromLocal || !editingOPId || !removingWriter) {
            return;
        }
        const originalPublishers = workDraftFromLocal.originalPublishers;
        const indexOfOP = originalPublishers.map((op) => op.localId).indexOf(editingOPId);
        const updatedWriters = data.writers.filter((writer) => {
            if (removingWriter.localId === writer.localId) {
                return;
            }
            return writer;
        });
        const updatedOriginalPublisher = Object.assign(Object.assign({}, data), { id: data.id, amend: data.amend, localId: editingOPId, publisherName: data.publisherName, ipiNumber: data.ipiNumber, publisherShare: data.publisherShare, administratorPublishers: data.administratorPublishers, writers: updatedWriters });
        const updatedOPArray = (0, removers_1.setItemToArrayByIndex)(originalPublishers, +indexOfOP, updatedOriginalPublisher);
        const updatedWorkDraft = Object.assign(Object.assign({}, workDraftFromLocal), { originalPublishers: updatedOPArray });
        workStore.setWorkDraftToLocal(updatedWorkDraft);
    };
    return { updatePublisherShare, updateAdministratorPublisherShare, removeLinkedWriter };
};
exports.default = usePublisherService;
