"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const zod_1 = require("zod");
const work_entities_fields_validators_1 = require("@App/helpers/work-entities-fields-validators");
const error_messages_1 = require("@Entities/schemas/work/error-messages");
const regexp_1 = require("@Const/regexp");
const recordingSchema = zod_1.z.object({
    id: zod_1.z
        .number({ invalid_type_error: (0, error_messages_1.numericMessage)('ID') })
        .int((0, error_messages_1.integerMessage)('ID'))
        .optional()
        .nullable(),
    localId: zod_1.z
        .number({ invalid_type_error: (0, error_messages_1.numericMessage)('Local ID') })
        .int((0, error_messages_1.integerMessage)('Local ID'))
        .optional(),
    title: zod_1.z
        .string({ invalid_type_error: (0, error_messages_1.stringMessage)('Title'), required_error: (0, error_messages_1.requiredMessage)('Title') })
        .max(100, (0, error_messages_1.stringSizeMaxMessage)('Title', 100))
        .refine((val) => !(0, work_entities_fields_validators_1.containsNewLine)(val), (0, error_messages_1.newlineMessage)('Title')),
    artistName: zod_1.z
        .string({ invalid_type_error: (0, error_messages_1.stringMessage)('Artist name'), required_error: (0, error_messages_1.requiredMessage)('Artist name') })
        .max(60, (0, error_messages_1.stringSizeMaxMessage)('Artist name', 60))
        .refine((val) => !(0, work_entities_fields_validators_1.containsNewLine)(val), (0, error_messages_1.newlineMessage)('Artist name')),
    isrc: zod_1.z
        .string({ invalid_type_error: (0, error_messages_1.stringMessage)('ISRC') })
        .refine((isrc) => !(0, work_entities_fields_validators_1.isrcInvalidCheck)(isrc), 'Invalid ISRC Format')
        .nullable(),
    duration: zod_1.z
        .string({ invalid_type_error: (0, error_messages_1.stringMessage)('Duration') })
        .regex(regexp_1.DURATION_REGEX, (0, error_messages_1.durationMessage)())
        .nullable()
        .or(zod_1.z.literal('')),
    releaseDate: zod_1.z
        .string({ invalid_type_error: (0, error_messages_1.stringMessage)('Release date'), required_error: (0, error_messages_1.requiredMessage)('Release date') })
        .nullable(),
    label: zod_1.z
        .string({ invalid_type_error: (0, error_messages_1.stringMessage)('Label'), required_error: (0, error_messages_1.requiredMessage)('Label') })
        .max(60, (0, error_messages_1.stringSizeMaxMessage)('Label', 60))
        .refine((val) => !(0, work_entities_fields_validators_1.containsNewLine)(val), (0, error_messages_1.newlineMessage)('Label'))
        .nullable(),
    amend: zod_1.z.boolean().optional().nullable(),
    publishOwner: zod_1.z
        .string({ invalid_type_error: (0, error_messages_1.stringMessage)('Publisher owner') })
        .optional()
        .nullable(),
    publishYear: zod_1.z
        .number({ invalid_type_error: (0, error_messages_1.numericMessage)('Publish year') })
        .optional()
        .nullable(),
    albumTitle: zod_1.z
        .string({ invalid_type_error: (0, error_messages_1.stringMessage)('Album title') })
        .optional()
        .nullable(),
});
exports.default = recordingSchema;
