"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findWorkByLocalId = void 0;
const findWorkByLocalId = (localId, arrayAllWorks) => {
    const errorWork = arrayAllWorks.error.find((item) => item.localId === localId);
    const completeWork = arrayAllWorks.complete.find((item) => item.localId === localId);
    if (errorWork) {
        return errorWork;
    }
    else if (completeWork) {
        return completeWork;
    }
    else {
        return;
    }
};
exports.findWorkByLocalId = findWorkByLocalId;
