"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RIGHTSHOLDER_VALIDATION_GROUP_STATUSES = exports.RIGHTSHOLDER_GROUP = exports.RIGHTSHOLDER_VALIDATION_STATUSES = void 0;
exports.RIGHTSHOLDER_VALIDATION_STATUSES = {
    NOT_VALIDATED: {
        id: 1,
        name: 'Not Validated',
    },
    TRULIOO_PASSED: {
        id: 2,
        name: 'Trulioo Passed',
    },
    TROLIOO_FAILED: {
        id: 3,
        name: 'Trulioo Failed',
    },
    HFA_APPROVED_EXISTING: {
        id: 4,
        name: 'HFA Approved and Existing',
    },
    HFA_REJECTED: {
        id: 5,
        name: 'HFA Rejected',
    },
    HFA_COPIED: {
        id: 6,
        name: 'HFA Copied',
    },
    DONE: {
        id: 7,
        name: 'Done',
    },
    HFA_APPROVED_NEW: {
        id: 8,
        name: 'HFA Approved and New',
    },
    HFA_REJECTED_SUB: {
        id: 9,
        name: 'HFA Rejected Sub-Publisher',
    },
};
exports.RIGHTSHOLDER_GROUP = {
    all: 'all',
    allDone: 'allDone',
    allPending: 'allPending',
    done: 'done',
    pending: 'pending',
    failed: 'failed',
};
exports.RIGHTSHOLDER_VALIDATION_GROUP_STATUSES = {
    all: [],
    done: [exports.RIGHTSHOLDER_VALIDATION_STATUSES.DONE.id],
    pending: [
        exports.RIGHTSHOLDER_VALIDATION_STATUSES.NOT_VALIDATED.id,
        exports.RIGHTSHOLDER_VALIDATION_STATUSES.TRULIOO_PASSED.id,
        exports.RIGHTSHOLDER_VALIDATION_STATUSES.HFA_APPROVED_EXISTING.id,
        exports.RIGHTSHOLDER_VALIDATION_STATUSES.HFA_APPROVED_NEW.id,
    ],
    failed: [
        exports.RIGHTSHOLDER_VALIDATION_STATUSES.TROLIOO_FAILED.id,
        exports.RIGHTSHOLDER_VALIDATION_STATUSES.HFA_REJECTED.id,
        exports.RIGHTSHOLDER_VALIDATION_STATUSES.HFA_REJECTED_SUB.id,
    ],
};
