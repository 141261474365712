"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
const calc_num_pages_1 = require("@App/helpers/pagination/calc-num-pages");
const rightsholder_statuses_1 = require("@Const/rightsholder-statuses");
const get_rigthsholder_pagination_status_1 = require("@App/helpers/get-rigthsholder-pagination-status");
const rightsholder_1 = require("@App/api/routes/rightsholder/rightsholder");
class RightsholderSearchStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_rightsholders", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_paginationInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "loadRightsholders", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (paginationInfo) => __awaiter(this, void 0, void 0, function* () {
                yield this.setRightsholdersAndPagination(paginationInfo, (passedPagInfo) => (0, rightsholder_1.getRightsholders)(passedPagInfo));
            })
        });
        Object.defineProperty(this, "searchRightsholders", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (paginationInfo, search) => __awaiter(this, void 0, void 0, function* () {
                yield this.setRightsholdersAndPagination(paginationInfo, (passedPagInfo) => (0, rightsholder_1.searchRightsholders)(passedPagInfo, search));
            })
        });
        Object.defineProperty(this, "setRightsholdersAndPagination", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (paginationInfo, fetchFn) => __awaiter(this, void 0, void 0, function* () {
                const newPagination = this.processRightsholderPagination(paginationInfo);
                const rightsholders = yield fetchFn(paginationInfo);
                (0, mobx_1.runInAction)(() => {
                    this._rightsholders = rightsholders.data;
                    if (newPagination.limit && newPagination.page && rightsholders) {
                        this._paginationInfo = (0, calc_num_pages_1.calcNumPages)(rightsholders.data.count, newPagination.limit, newPagination.page);
                    }
                });
            })
        });
        Object.defineProperty(this, "processRightsholderPagination", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pagination) => {
                if (pagination.pending) {
                    pagination.status = rightsholder_statuses_1.RIGHTSHOLDER_GROUP.pending;
                }
                const newStatus = (0, get_rigthsholder_pagination_status_1.generateRightsholderPaginationStatus)(pagination.status);
                const newPagination = pagination;
                newStatus ? (newPagination.status = newStatus) : delete newPagination.status;
                return newPagination;
            }
        });
        (0, mobx_1.makeObservable)(this, {
            _rightsholders: mobx_1.observable,
            _paginationInfo: mobx_1.observable,
            rightsholderCount: mobx_1.computed,
        });
        this.rootStore = rootStore;
    }
    get rightsholders() {
        return this._rightsholders;
    }
    get paginationInfo() {
        return this._paginationInfo;
    }
    get rightsholderCount() {
        if (!this._rightsholders) {
            return 0;
        }
        return this._rightsholders.count;
    }
}
exports.default = RightsholderSearchStore;
