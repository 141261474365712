"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PERMISSION_NAMES = void 0;
var PERMISSION_NAMES;
(function (PERMISSION_NAMES) {
    PERMISSION_NAMES["super_user"] = "Super User";
    PERMISSION_NAMES["finance"] = "Finance";
    PERMISSION_NAMES["copyright"] = "Copyright";
    PERMISSION_NAMES["internal_user"] = "Internal User";
})(PERMISSION_NAMES = exports.PERMISSION_NAMES || (exports.PERMISSION_NAMES = {}));
