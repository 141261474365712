"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.writersTableSchema = void 0;
const zod_1 = require("zod");
const error_messages_1 = require("@Entities/schemas/work/error-messages");
const validate_1 = require("@App/helpers/validate");
const writersTableSchema = zod_1.z
    .object({
    ['writerName']: zod_1.z.string(),
    ['writerIPI']: zod_1.z
        .string()
        .max(11, (0, error_messages_1.stringSizeMaxMessage)('IPI Number', 11))
        .refine((ipi) => (0, validate_1.IPIValidator)(ipi, false), 'Invalid IPI Number')
        .or(zod_1.z.literal('--'))
        .optional(),
    ['writerRole']: zod_1.z.object({
        value: zod_1.z.string(),
        label: zod_1.z.string(),
    }),
})
    .array()
    .superRefine((val, ctx) => {
    const containsAtLeastOneComposer = val.filter((item) => item.writerRole.value === 'C' || item.writerRole.value === 'CA').length > 0;
    if (!containsAtLeastOneComposer) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: 'At least one Composer/Author or Composer writer role is required to submit a work registration.',
            path: ['globalError'],
        });
    }
});
exports.writersTableSchema = writersTableSchema;
