"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UiStore = void 0;
const mobx_1 = require("mobx");
const check_is_mobile_1 = require("@App/helpers/check-is-mobile");
const url_1 = require("@App/helpers/url");
class UiStore {
    constructor() {
        Object.defineProperty(this, "_windowSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_onLoginPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // todo move this bulk upload wizard store when it is made
        Object.defineProperty(this, "_bulkUploadModalIsOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        (0, mobx_1.makeObservable)(this, {
            _windowSize: mobx_1.observable,
            _onLoginPage: mobx_1.observable,
            _bulkUploadModalIsOpen: mobx_1.observable,
            setWindowSize: mobx_1.action,
            setOnLoginPage: mobx_1.action,
            setBulkUploadModalIsOpen: mobx_1.action,
            isMobile: mobx_1.computed,
        });
        this._bulkUploadModalIsOpen = true;
        this._onLoginPage = (0, url_1.urlHasComponent)('login');
    }
    get windowSize() {
        return this._windowSize;
    }
    get onLoginPage() {
        return this._onLoginPage;
    }
    get bulkUploadModalIsOpen() {
        return this._bulkUploadModalIsOpen;
    }
    setWindowSize(widthData) {
        this._windowSize = widthData;
    }
    setOnLoginPage(onLogin) {
        this._onLoginPage = onLogin;
    }
    setBulkUploadModalIsOpen(isOpen) {
        this._bulkUploadModalIsOpen = isOpen;
    }
    get isMobile() {
        return this._windowSize && (0, check_is_mobile_1.checkIsMobile)(this._windowSize && this._windowSize.width);
    }
}
exports.UiStore = UiStore;
