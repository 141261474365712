"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNotAnEmptyObject = exports.isEmptyObject = void 0;
const isEmptyObject = (object) => Object.keys(object).length === 0;
exports.isEmptyObject = isEmptyObject;
const isNotAnEmptyObject = (object) => {
    if (!object) {
        return false;
    }
    return Object.keys(object).length > 0;
};
exports.isNotAnEmptyObject = isNotAnEmptyObject;
