"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidebarDownloadType = void 0;
class SidebarDownloadType {
    constructor(formText, submittedText) {
        Object.defineProperty(this, "formText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "submittedText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.formText = formText;
        this.submittedText = submittedText;
    }
}
exports.SidebarDownloadType = SidebarDownloadType;
Object.defineProperty(SidebarDownloadType, "CatalogExport", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: Object.freeze(new SidebarDownloadType('Select the file type you would that you would like to download your catalog in.', "We're preparing your files for download. You'll receive an email with " +
        'your downloadable catalog export.'))
});
Object.defineProperty(SidebarDownloadType, "MatchedRecordingsExport", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: Object.freeze(new SidebarDownloadType('Select the file type you would that you would like to download your matched recordings in.', "We're preparing your files for download. You'll receive an email shortly with " +
        'your downloadable matched recordings extract'))
});
Object.defineProperty(SidebarDownloadType, "MatchHistoryExport", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: Object.freeze(new SidebarDownloadType('Select the file type you would that you would like to download your match history in.', "We're preparing your files for download. You'll receive an email shortly with " +
        'your downloadable match history extract'))
});
Object.defineProperty(SidebarDownloadType, "RoyaltyStatements", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: Object.freeze(new SidebarDownloadType('Select the format and file type that you would like ' + 'to download your statements in.', "We're preparing your files for download. " +
        "You'll receive an email shortly with downloadable statements."))
});
