"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unmatchedValueOptions = exports.contactTypeOptions = exports.writerRoleOptions = exports.titleTypeOptions = exports.languageOptions = void 0;
exports.languageOptions = [
    {
        id: '1',
        label: 'English',
        value: 'EN',
    },
    {
        id: '2',
        label: 'Spanish',
        value: 'ES',
    },
    {
        id: '3',
        label: '(Afan) Oromo',
        value: 'OM',
    },
    {
        id: '4',
        label: 'Abkhazian',
        value: 'AB',
    },
    {
        id: '5',
        label: 'Afar',
        value: 'AA',
    },
    {
        id: '6',
        label: 'Afrikaans',
        value: 'AF',
    },
    {
        id: '7',
        label: 'Albanian',
        value: 'SQ',
    },
    {
        id: '8',
        label: 'Amharic',
        value: 'AM',
    },
    {
        id: '9',
        label: 'Arabic',
        value: 'AR',
    },
    {
        id: '10',
        label: 'Armenian',
        value: 'HY',
    },
    {
        id: '11',
        label: 'Assamese',
        value: 'AS',
    },
    {
        id: '12',
        label: 'Aymara',
        value: 'AY',
    },
    {
        id: '13',
        label: 'Azerbaijani',
        value: 'AZ',
    },
    {
        id: '14',
        label: 'Bashkir',
        value: 'BA',
    },
    {
        id: '15',
        label: 'Basque',
        value: 'EU',
    },
    {
        id: '16',
        label: 'Bengali',
        value: 'BN',
    },
    {
        id: '17',
        label: 'Bhutani',
        value: 'DZ',
    },
    {
        id: '18',
        label: 'Bihari',
        value: 'BH',
    },
    {
        id: '19',
        label: 'Bislama',
        value: 'BI',
    },
    {
        id: '20',
        label: 'Breton',
        value: 'BR',
    },
    {
        id: '21',
        label: 'Bulgarian',
        value: 'BG',
    },
    {
        id: '22',
        label: 'Burmese',
        value: 'MY',
    },
    {
        id: '23',
        label: 'Byelorussian',
        value: 'BE',
    },
    {
        id: '24',
        label: 'Cambodian',
        value: 'KM',
    },
    {
        id: '25',
        label: 'Catalan',
        value: 'CA',
    },
    {
        id: '26',
        label: 'Chinese',
        value: 'ZH',
    },
    {
        id: '27',
        label: 'Corsican',
        value: 'CO',
    },
    {
        id: '28',
        label: 'Croatian',
        value: 'HR',
    },
    {
        id: '29',
        label: 'Czech',
        value: 'CS',
    },
    {
        id: '30',
        label: 'Danish',
        value: 'DA',
    },
    {
        id: '31',
        label: 'Dutch',
        value: 'NL',
    },
    {
        id: '32',
        label: 'Esperanto',
        value: 'EO',
    },
    {
        id: '33',
        label: 'Estonian',
        value: 'ET',
    },
    {
        id: '34',
        label: 'Faeroese',
        value: 'FO',
    },
    {
        id: '35',
        label: 'Farsi',
        value: 'FA',
    },
    {
        id: '36',
        label: 'Fiji',
        value: 'FJ',
    },
    {
        id: '37',
        label: 'Finnish',
        value: 'FI',
    },
    {
        id: '38',
        label: 'French',
        value: 'FR',
    },
    {
        id: '39',
        label: 'Frisian',
        value: 'FY',
    },
    {
        id: '40',
        label: 'Galician',
        value: 'GL',
    },
    {
        id: '41',
        label: 'Georgian',
        value: 'KA',
    },
    {
        id: '42',
        label: 'German',
        value: 'DE',
    },
    {
        id: '43',
        label: 'Greek',
        value: 'EL',
    },
    {
        id: '44',
        label: 'Greenlandic',
        value: 'KL',
    },
    {
        id: '45',
        label: 'Guarani',
        value: 'GN',
    },
    {
        id: '46',
        label: 'Gujarati',
        value: 'GU',
    },
    {
        id: '47',
        label: 'Hausa',
        value: 'HA',
    },
    {
        id: '48',
        label: 'Hawaii',
        value: 'HW',
    },
    {
        id: '49',
        label: 'Hebrew',
        value: 'IW',
    },
    {
        id: '50',
        label: 'Hindi',
        value: 'HI',
    },
    {
        id: '51',
        label: 'Hungarian',
        value: 'HU',
    },
    {
        id: '52',
        label: 'Icelandic',
        value: 'IS',
    },
    {
        id: '53',
        label: 'Indonesian',
        value: 'IN',
    },
    {
        id: '54',
        label: 'Interlingua',
        value: 'IA',
    },
    {
        id: '55',
        label: 'Interlingue',
        value: 'IE',
    },
    {
        id: '56',
        label: 'Inupiak',
        value: 'IK',
    },
    {
        id: '57',
        label: 'Irish',
        value: 'GA',
    },
    {
        id: '58',
        label: 'Italian',
        value: 'IT',
    },
    {
        id: '59',
        label: 'Japanese',
        value: 'JA',
    },
    {
        id: '60',
        label: 'Javanese',
        value: 'JW',
    },
    {
        id: '61',
        label: 'Kannada',
        value: 'KN',
    },
    {
        id: '62',
        label: 'Kashmiri',
        value: 'KS',
    },
    {
        id: '63',
        label: 'Kazakh',
        value: 'KK',
    },
    {
        id: '64',
        label: 'Kinyarwanda',
        value: 'RW',
    },
    {
        id: '65',
        label: 'Kirghiz',
        value: 'KY',
    },
    {
        id: '66',
        label: 'Kirundi',
        value: 'RN',
    },
    {
        id: '67',
        label: 'Korean',
        value: 'KO',
    },
    {
        id: '68',
        label: 'Kurdish',
        value: 'KU',
    },
    {
        id: '69',
        label: 'Laothian',
        value: 'LO',
    },
    {
        id: '70',
        label: 'Latin',
        value: 'LA',
    },
    {
        id: '71',
        label: 'Latvian',
        value: 'LV',
    },
    {
        id: '72',
        label: 'Lingala',
        value: 'LN',
    },
    {
        id: '73',
        label: 'Lithuanian',
        value: 'LT',
    },
    {
        id: '74',
        label: 'Macedonian',
        value: 'MK',
    },
    {
        id: '75',
        label: 'Malagasy',
        value: 'MG',
    },
    {
        id: '76',
        label: 'Malay',
        value: 'MS',
    },
    {
        id: '77',
        label: 'Malayalam',
        value: 'ML',
    },
    {
        id: '78',
        label: 'Maltese',
        value: 'MT',
    },
    {
        id: '79',
        label: 'Maori',
        value: 'MI',
    },
    {
        id: '80',
        label: 'Marathi',
        value: 'MR',
    },
    {
        id: '81',
        label: 'Moldavian',
        value: 'MO',
    },
    {
        id: '82',
        label: 'Mongolian',
        value: 'MN',
    },
    {
        id: '83',
        label: 'Nauru',
        value: 'NA',
    },
    {
        id: '84',
        label: 'Ndebele',
        value: 'ND',
    },
    {
        id: '85',
        label: 'Nepali',
        value: 'NE',
    },
    {
        id: '86',
        label: 'North Sotho',
        value: 'NS',
    },
    {
        id: '87',
        label: 'Norwegian',
        value: 'NO',
    },
    {
        id: '88',
        label: 'Occitan',
        value: 'OC',
    },
    {
        id: '89',
        label: 'Oriya',
        value: 'OR',
    },
    {
        id: '90',
        label: 'Oromo',
        value: 'OM',
    },
    {
        id: '91',
        label: 'Papiamento',
        value: 'PM',
    },
    {
        id: '92',
        label: 'Pashto',
        value: 'PS',
    },
    {
        id: '93',
        label: 'Polish',
        value: 'PL',
    },
    {
        id: '94',
        label: 'Portuguese',
        value: 'PT',
    },
    {
        id: '95',
        label: 'Punjabi',
        value: 'PA',
    },
    {
        id: '96',
        label: 'Quechua',
        value: 'QU',
    },
    {
        id: '97',
        label: 'Rhaeto-Romance',
        value: 'RM',
    },
    {
        id: '98',
        label: 'Romanian',
        value: 'RO',
    },
    {
        id: '99',
        label: 'Russian',
        value: 'RU',
    },
    {
        id: '100',
        label: 'Samoan',
        value: 'SM',
    },
    {
        id: '101',
        label: 'Sangro',
        value: 'SG',
    },
    {
        id: '102',
        label: 'Sanskrit',
        value: 'SA',
    },
    {
        id: '103',
        label: 'Scots Gaelic',
        value: 'GD',
    },
    {
        id: '104',
        label: 'Serbian',
        value: 'SR',
    },
    {
        id: '105',
        label: 'Serbo-Croatian',
        value: 'SH',
    },
    {
        id: '106',
        label: 'Sesotho',
        value: 'ST',
    },
    {
        id: '107',
        label: 'Setswana',
        value: 'TN',
    },
    {
        id: '108',
        label: 'Shona',
        value: 'SN',
    },
    {
        id: '109',
        label: 'Sindhi',
        value: 'SD',
    },
    {
        id: '110',
        label: 'Singhalese',
        value: 'SI',
    },
    {
        id: '111',
        label: 'Siswati',
        value: 'SS',
    },
    {
        id: '112',
        label: 'Slovak',
        value: 'SK',
    },
    {
        id: '113',
        label: 'Slovenian',
        value: 'SL',
    },
    {
        id: '114',
        label: 'Somali',
        value: 'SO',
    },
    {
        id: '115',
        label: 'Sudanese',
        value: 'SU',
    },
    {
        id: '116',
        label: 'Swahili',
        value: 'SW',
    },
    {
        id: '117',
        label: 'Swedish',
        value: 'SV',
    },
    {
        id: '118',
        label: 'Tagalog',
        value: 'TL',
    },
    {
        id: '119',
        label: 'Tajik',
        value: 'TG',
    },
    {
        id: '120',
        label: 'Tamil',
        value: 'TA',
    },
    {
        id: '121',
        label: 'Tatar',
        value: 'TT',
    },
    {
        id: '122',
        label: 'Telugu',
        value: 'TE',
    },
    {
        id: '123',
        label: 'Thai',
        value: 'TH',
    },
    {
        id: '124',
        label: 'Tibetan',
        value: 'BO',
    },
    {
        id: '125',
        label: 'Tigrinya',
        value: 'TI',
    },
    {
        id: '126',
        label: 'Tonga',
        value: 'TO',
    },
    {
        id: '127',
        label: 'Tsonga',
        value: 'TS',
    },
    {
        id: '128',
        label: 'Turkish',
        value: 'TR',
    },
    {
        id: '129',
        label: 'Turkmen',
        value: 'TK',
    },
    {
        id: '130',
        label: 'Twi',
        value: 'TW',
    },
    {
        id: '131',
        label: 'Ukranian',
        value: 'UK',
    },
    {
        id: '132',
        label: 'Urdu',
        value: 'UR',
    },
    {
        id: '133',
        label: 'Uzbek',
        value: 'UZ',
    },
    {
        id: '134',
        label: 'Venda',
        value: 'VE',
    },
    {
        id: '135',
        label: 'Vietnamese',
        value: 'VI',
    },
    {
        id: '136',
        label: 'Volapuk',
        value: 'VO',
    },
    {
        id: '137',
        label: 'Welsh',
        value: 'CY',
    },
    {
        id: '138',
        label: 'Wolof',
        value: 'WO',
    },
    {
        id: '139',
        label: 'Xhosa',
        value: 'XH',
    },
    {
        id: '140',
        label: 'Yiddish',
        value: 'JI',
    },
    {
        id: '141',
        label: 'Yoruba',
        value: 'YO',
    },
    {
        id: '142',
        label: 'Zulu',
        value: 'ZU',
    },
    {
        id: '144',
        label: 'Hmong',
        value: 'B0',
    },
    {
        id: '145',
        label: 'Cantonese',
        value: 'A0',
    },
    {
        id: '146',
        label: 'English GB',
        value: 'A9',
    },
    {
        id: '147',
        label: 'Multi',
        value: 'A8',
    },
    {
        id: '148',
        label: 'Algonquian',
        value: 'A7',
    },
    {
        id: '149',
        label: 'Persian',
        value: 'A6',
    },
    {
        id: '150',
        label: 'Mandarin',
        value: 'A5',
    },
    {
        id: '151',
        label: 'English AU',
        value: 'A4',
    },
    {
        id: '152',
        label: 'Gallegan',
        value: 'A3',
    },
    {
        id: '153',
        label: 'SP',
        value: 'A1',
    },
    {
        id: '154',
        label: 'ES',
        value: 'A2',
    },
];
exports.titleTypeOptions = [
    {
        id: '9',
        value: 'TE',
        label: 'First Line Of Text',
    },
    {
        id: '10',
        value: 'FT',
        label: 'Formal Title ',
    },
    {
        id: '11',
        value: 'IT',
        label: 'Incorrect Title',
    },
    {
        id: '12',
        value: 'OT',
        label: 'Original Title',
    },
    {
        id: '13',
        value: 'TT',
        label: 'Original Title Translated',
    },
    {
        id: '14',
        value: 'PT',
        label: 'Part Title',
    },
    {
        id: '15',
        value: 'RT',
        label: 'Restricted Title',
    },
    {
        id: '16',
        value: 'ET',
        label: 'Extra Search Title',
    },
    {
        id: '17',
        value: 'OL',
        label: 'Original Title With National Characters',
    },
    {
        id: '18',
        value: 'AL',
        label: 'Alternative Title With National Characters',
    },
    {
        id: '29',
        value: 'AT',
        label: 'Alternative Title',
    },
];
exports.writerRoleOptions = [
    {
        id: '11',
        value: 'CA',
        label: 'Composer/Author',
    },
    {
        id: '9',
        value: 'A',
        label: 'Author',
    },
    {
        id: '7',
        value: 'AD',
        label: 'Adaptor',
    },
    {
        id: '8',
        value: 'AR',
        label: 'Arranger',
    },
    {
        id: '10',
        value: 'C',
        label: 'Composer',
    },
    {
        id: '14',
        value: 'TR',
        label: 'Translator',
    },
    {
        id: '12',
        value: 'SR',
        label: 'Sub Arranger',
    },
    {
        id: '13',
        value: 'SA',
        label: 'Sub Author',
    },
];
exports.contactTypeOptions = [
    {
        label: 'Public',
        value: 'PUBLIC',
        id: '1',
    },
    {
        label: 'Finance',
        value: 'FINANCE',
        id: '2',
    },
    {
        label: 'Legal',
        value: 'LEGAL',
        id: '3',
    },
    {
        label: 'Copyright',
        value: 'COPYRIGHT',
        id: '4',
    },
];
exports.unmatchedValueOptions = [
    { label: '$$$$$', value: '5', id: '5' },
    { label: '$$$$', value: '4', id: '4' },
    { label: '$$$', value: '3', id: '3' },
    { label: '$$', value: '2', id: '2' },
    { label: '$', value: '1', id: '1' },
];
