"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IS_ISRC_MESSGAE = exports.NO_PAYEE_ACTIVITY = exports.PUBLISHER_SHARE_IS_0_ERROR = exports.FAILED_VERIFICATION_MESSAGE = exports.BIRTHDAY_ERROR_MESSAGE = exports.IS_US_POSTAL_CODE_MESSAGE = exports.IS_IPI_MESSAGE = exports.IS_ISWC_MESSAGE = exports.IS_DATE_MESSAGE = exports.IS_LANGUAGE_MESSAGE = exports.IS_DURATION_MESSAGE = exports.NO_RESERVED_EMAIL_MESSAGE = exports.EMAIL_MESSAGE = exports.STATIC_LENGTH_MESSAGE = exports.MIN_LENGTH_MESSAGE = exports.MAX_LENGTH_MESSAGE = exports.IS_NUMBER_MESSAGE = exports.REQUIRED_MESSAGE = exports.CREATE_WORK_ERROR_MESSAGE = void 0;
exports.CREATE_WORK_ERROR_MESSAGE = 'There is an error in your submission. Please review your submission and retry. If ' +
    'you continue to have issues, use the Contact Us form or call customer support.';
exports.REQUIRED_MESSAGE = 'Required';
exports.IS_NUMBER_MESSAGE = 'Value must contain only numbers';
exports.MAX_LENGTH_MESSAGE = 'Invalid value, max length';
exports.MIN_LENGTH_MESSAGE = 'Invalid value, min length';
exports.STATIC_LENGTH_MESSAGE = 'Invalid value, length of value must be';
exports.EMAIL_MESSAGE = 'Invalid email address';
exports.NO_RESERVED_EMAIL_MESSAGE = 'This email domain is internal and can’t be used';
exports.IS_DURATION_MESSAGE = 'Enter the duration in the format 00:00';
exports.IS_LANGUAGE_MESSAGE = 'Please select language';
exports.IS_DATE_MESSAGE = 'Enter the date in format YYYY-MM-DD';
exports.IS_ISWC_MESSAGE = 'The ISWC format must have a prefix letter, followed by 10 digits. Hyphens are optional. Example: T-034.524.680-1';
exports.IS_IPI_MESSAGE = 'Invalid IPI number';
exports.IS_US_POSTAL_CODE_MESSAGE = 'Invalid US postal code';
exports.BIRTHDAY_ERROR_MESSAGE = 'Birthday must earlier than today';
exports.FAILED_VERIFICATION_MESSAGE = 'Your account has failed verification. Please contact support for more information.';
exports.PUBLISHER_SHARE_IS_0_ERROR = 'Publisher share must be greater than 0';
exports.NO_PAYEE_ACTIVITY = 'Your payee activity is currently unavailable. Please contact The MLC Support Team for more information.';
exports.IS_ISRC_MESSGAE = 'ISRC must be alpha-numeric, 12 characters long, the first two characters must be letters, and the last 7 characters must be numbers';
