"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
const search_option_type_1 = require("@App/enum/search-option-type");
const required_criteria_1 = require("@App/enum/required-criteria");
const uuid_1 = require("uuid");
class SearchCriteriaStore {
    constructor(searchCriteriaArray, criteriaOptionsArray, searchOptionType, requiredCriteria, maxCriteria = 6, primaryFilterNames) {
        Object.defineProperty(this, "_searchCriteria", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "primaryFilterNames", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_defaultSearchCriteria", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_criteriaOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "maxCriteria", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "searchOptionType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "requiredCriteria", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_isUniqueSelected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        (0, mobx_1.makeObservable)(this, {
            _criteriaOptions: mobx_1.observable,
            _searchCriteria: mobx_1.observable,
            _isUniqueSelected: mobx_1.observable,
            searchCriteria: mobx_1.computed,
            isAddCriterionDisabled: mobx_1.computed,
            filteredOptions: mobx_1.computed,
            nonPrimaryOptionsFiltered: mobx_1.computed,
            setCriterionOption: mobx_1.action,
            setIsUniqueSelected: mobx_1.action,
            deleteCriterion: mobx_1.action,
            addCriterion: mobx_1.action,
            atMaxCriteria: mobx_1.computed,
        });
        this._criteriaOptions = [...criteriaOptionsArray];
        this._searchCriteria.push(...searchCriteriaArray.map((value) => (Object.assign(Object.assign({}, value), { uniqueId: (0, uuid_1.v4)() }))));
        this._defaultSearchCriteria = [...searchCriteriaArray];
        this.maxCriteria = maxCriteria;
        this.searchOptionType = searchOptionType;
        this.requiredCriteria = requiredCriteria;
        this.primaryFilterNames = primaryFilterNames ? primaryFilterNames : [];
    }
    get searchCriteria() {
        return [...this._searchCriteria];
    }
    get isAddCriterionDisabled() {
        return this._isUniqueSelected || this.atMaxCriteria;
    }
    get filteredOptions() {
        const currentSelectedOptionIds = this.searchCriteria.map((searchCriterion) => searchCriterion.selectedOption.id);
        return this._criteriaOptions.filter((option) => !currentSelectedOptionIds.includes(option.id));
    }
    get nonPrimaryOptionsFiltered() {
        return this.filteredOptions.filter((option) => !option.isPrimary);
    }
    get atMaxCriteria() {
        return this._searchCriteria.length >= this.maxCriteria;
    }
    get isUniqueSelected() {
        return this._isUniqueSelected;
    }
    get defaultSearchCriteria() {
        return this._defaultSearchCriteria;
    }
    get defaultSearchCriteriaLength() {
        return this._defaultSearchCriteria.length;
    }
    isPrimaryFilter(value) {
        return this.primaryFilterNames.includes(value);
    }
    isSomeCriteria(searchCriteria) {
        return searchCriteria.some((criterion) => criterion.searchTerm && criterion.searchTerm.trim().length > 0);
    }
    isCriteriaSearchReady(searchCriteria, filters) {
        switch (this.requiredCriteria) {
            case required_criteria_1.RequiredCriteria.all:
                return searchCriteria.every((criterion) => criterion.searchTerm && criterion.searchTerm.trim().length > 0);
            case required_criteria_1.RequiredCriteria.some:
                return this.isSomeCriteria(searchCriteria);
            case required_criteria_1.RequiredCriteria.first:
                return !!(searchCriteria.length &&
                    searchCriteria[0].searchTerm &&
                    searchCriteria[0].searchTerm.trim().length > 0);
            case required_criteria_1.RequiredCriteria.primaryFilter:
                return ((!!filters &&
                    Object.entries(filters).some((filter) => this.isPrimaryFilter(filter[0]) && filter[1])) ||
                    this.isSomeCriteria(searchCriteria));
            case required_criteria_1.RequiredCriteria.none:
                return true;
        }
    }
    getOptionsForCriterion(criterion) {
        switch (this.searchOptionType) {
            case search_option_type_1.SearchOptionType.primary:
                if (criterion.id === this.searchCriteria[0].id) {
                    return this.getFilteredOptionsPlusSelected(criterion).filter((option) => option.value !== 'publisherNumber');
                }
                return this.getFilteredOptionsPlusSelected(criterion).filter((option) => !option.isPrimary);
            case search_option_type_1.SearchOptionType.filter:
                return this.getFilteredOptionsPlusSelected(criterion);
            case search_option_type_1.SearchOptionType.determine:
                return this.determineOptions(criterion);
        }
    }
    determineOptions(criterion) {
        if (criterion.isDisabled) {
            const criterionOption = this._criteriaOptions.find((option) => option.value === criterion.selectedOption.value);
            return [
                {
                    id: '1',
                    value: criterion.selectedOption.value,
                    label: criterionOption ? criterionOption.label : '',
                },
            ];
        }
        else {
            return this._criteriaOptions.filter((option) => !option.shouldHide);
        }
    }
    getFilteredOptionsPlusSelected(criterion) {
        const availableOptions = [...this.filteredOptions];
        if (criterion.selectedOption) {
            availableOptions.push(criterion.selectedOption);
        }
        return availableOptions;
    }
    setIsUniqueSelected(isSelected) {
        this._isUniqueSelected = isSelected;
    }
    setCriterionOption(idx, selectedOption) {
        if (selectedOption) {
            this._searchCriteria[idx] = Object.assign(Object.assign({}, this._searchCriteria[idx]), { id: (0, uuid_1.v4)(), searchTerm: '', selectedOption: selectedOption });
            if (selectedOption.isUnique) {
                this.deleteCriterion(idx + 1, this._searchCriteria.length - (idx + 1));
                this.setIsUniqueSelected(true);
            }
            else {
                this.setIsUniqueSelected(false);
            }
        }
    }
    deleteCriterion(idx, deleteCount = 1) {
        this._searchCriteria.splice(idx, deleteCount);
    }
    addCriterion() {
        let nextCriterionOption;
        switch (this.searchOptionType) {
            case search_option_type_1.SearchOptionType.primary:
                nextCriterionOption = this.nonPrimaryOptionsFiltered[0];
                break;
            case search_option_type_1.SearchOptionType.filter:
                nextCriterionOption = this.filteredOptions[0];
                break;
            case search_option_type_1.SearchOptionType.determine:
                nextCriterionOption = Object.assign(Object.assign({}, this._criteriaOptions[2]), { id: (0, uuid_1.v4)() });
                break;
        }
        this._searchCriteria.push({
            id: nextCriterionOption.id,
            searchTerm: '',
            selectedOption: nextCriterionOption,
        });
    }
    // This only syncs fields on this observable, and will not cause additional re-renders
    syncFormValues(data) {
        data &&
            data.forEach((criteria, idx) => {
                if (this._searchCriteria.length > idx) {
                    this._searchCriteria[idx].searchTerm = criteria.searchTerm;
                }
            });
    }
}
exports.default = SearchCriteriaStore;
