"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStrRHStatus = void 0;
const rightsholder_statuses_1 = require("@Const/rightsholder-statuses");
const getStrRHStatus = (validationStatus) => {
    if (validationStatus === rightsholder_statuses_1.RIGHTSHOLDER_VALIDATION_STATUSES.DONE.name) {
        return 'Done';
    }
    else if (validationStatus === rightsholder_statuses_1.RIGHTSHOLDER_VALIDATION_STATUSES.NOT_VALIDATED.name ||
        validationStatus === rightsholder_statuses_1.RIGHTSHOLDER_VALIDATION_STATUSES.TRULIOO_PASSED.name ||
        validationStatus === rightsholder_statuses_1.RIGHTSHOLDER_VALIDATION_STATUSES.HFA_APPROVED_EXISTING.name ||
        validationStatus === rightsholder_statuses_1.RIGHTSHOLDER_VALIDATION_STATUSES.HFA_COPIED.name ||
        validationStatus === rightsholder_statuses_1.RIGHTSHOLDER_VALIDATION_STATUSES.HFA_APPROVED_NEW.name) {
        return 'Pending';
    }
    else if (validationStatus === rightsholder_statuses_1.RIGHTSHOLDER_VALIDATION_STATUSES.TROLIOO_FAILED.name ||
        validationStatus === rightsholder_statuses_1.RIGHTSHOLDER_VALIDATION_STATUSES.HFA_REJECTED.name) {
        return 'Failed';
    }
    else {
        return;
    }
};
exports.getStrRHStatus = getStrRHStatus;
