"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatName = void 0;
const string_escape_newlines_1 = require("@App/helpers/string-escape-newlines");
const formatName = (firstName = '', lastName) => {
    if (firstName === 'Anonymous') {
        return firstName;
    }
    return (0, string_escape_newlines_1.stringEscapeNewlines)(''.concat(firstName || '', ' ', lastName || '').trim());
};
exports.formatName = formatName;
