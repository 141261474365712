"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DURATION_REGEX = exports.ONLY_NUMBERS_REGEX = exports.EMAIL_REGEX = exports.DATE_REGEX = exports.PASSWORD_REGEX = exports.PHONE_NUMBER_REGEX = void 0;
exports.PHONE_NUMBER_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}?$/;
exports.PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[!@"#№$;%^:&?*()_+={}|/,.~`])(?=.*[0-9])(?=.*[a-z]).{10,256}$/;
// tslint:disable:max-line-length
exports.DATE_REGEX = /^((19\d{2})|(20\d{2}))-(((02)-(0[1-9]|[1-2][0-9]))|(((0(1|[3-9]))|(1[0-2]))-(0[1-9]|[1-2][0-9]|30))|((01|03|05|07|08|10|12)-(31)))$/;
exports.EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// tslint:enable:max-line-length
exports.ONLY_NUMBERS_REGEX = /^\d+$/;
exports.DURATION_REGEX = /^[0-9]{2}[:]{1}[0-9]{2}$/;
