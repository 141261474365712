"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatPhoneNumber = void 0;
const global_validation_1 = require("@App/common/global-validation");
const formatPhoneNumber = (phoneNumberForm) => {
    const { phoneCountryCode, phoneAreaCode, phoneNumber } = phoneNumberForm;
    let phone = '';
    if (phoneCountryCode) {
        phone += '+' + phoneCountryCode + '-';
    }
    if (phoneAreaCode && !(0, global_validation_1.isBlank)(phoneAreaCode)) {
        phone += phoneAreaCode.trim() + '-';
    }
    if (phoneNumber && !(0, global_validation_1.isBlank)(phoneNumber)) {
        phone += phoneNumber.trim();
    }
    return phone;
};
exports.formatPhoneNumber = formatPhoneNumber;
