"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LibStore = void 0;
const mobx_1 = require("mobx");
const lib_1 = require("@App/api/routes/lib");
const mobx_utils_1 = require("mobx-utils");
class LibStore {
    constructor() {
        Object.defineProperty(this, "_languages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "_rawCountries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "_akaTitleTypes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "_writerRoles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "rawStatesForCountry", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (0, mobx_utils_1.computedFn)((countryId) => { var _a; return (_a = this._rawCountries.find((country) => country.id === +countryId)) === null || _a === void 0 ? void 0 : _a.states; })
        });
        Object.defineProperty(this, "statesForCountry", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (0, mobx_utils_1.computedFn)((countryId) => {
                const states = this.rawStatesForCountry(countryId);
                return states
                    ? states
                        .map((data) => ({
                        value: data.id.toString(),
                        label: data.name,
                        id: data.id.toString(),
                    }))
                        .sort((x, y) => x.label.localeCompare(y.label))
                    : [];
            })
        });
        Object.defineProperty(this, "statesForCountryForForm", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (0, mobx_utils_1.computedFn)((countryId) => {
                var _a;
                const states = (_a = this._rawCountries.find((country) => country.id === +countryId)) === null || _a === void 0 ? void 0 : _a.states;
                return states
                    ? states
                        .map((data) => ({
                        value: data.name,
                        label: data.name,
                        id: data.id.toString(),
                    }))
                        .sort((x, y) => x.label.localeCompare(y.label))
                    : [];
            })
        });
        Object.defineProperty(this, "doesStateExistInCountry", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (0, mobx_utils_1.computedFn)((countryId, state) => !!this.statesForCountryForForm(countryId).find((country) => country.value === state))
        });
        Object.defineProperty(this, "loadLanguages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                if (!this._languages.length) {
                    const languages = yield (0, lib_1.getLanguages)();
                    this._languages = languages.data.map((data) => ({
                        value: data.languageCode,
                        label: data.language,
                        id: data.id.toString(),
                    }));
                }
            })
        });
        Object.defineProperty(this, "loadAkaTitleTypes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                if (!this._akaTitleTypes.length) {
                    const akaTitleTypes = yield (0, lib_1.getAkaTitleTypes)();
                    this._akaTitleTypes = akaTitleTypes.data.map((data) => ({
                        value: data.typeCode,
                        label: data.description,
                        id: data.id.toString(),
                    }));
                }
            })
        });
        Object.defineProperty(this, "loadWriterRoles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                if (!this._writerRoles.length) {
                    const writerRoles = yield (0, lib_1.getWriterRoles)();
                    this._writerRoles = writerRoles.data.map((data) => ({
                        value: data.roleCode,
                        label: data.description,
                        id: data.id.toString(),
                    }));
                }
            })
        });
        Object.defineProperty(this, "loadCountries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                if (!this._rawCountries.length) {
                    const rawCountries = yield (0, lib_1.getCountries)();
                    this._rawCountries = rawCountries.data.sort((x, y) => x.name === 'United States' ? -1 : y.name === 'United States' ? 1 : 0);
                }
            })
        });
        Object.defineProperty(this, "getStateFullName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (0, mobx_utils_1.computedFn)((countryId, stateAbbreviation) => {
                const states = this.rawStatesForCountry(countryId);
                if (states) {
                    const state = states.find((data) => data.code === stateAbbreviation);
                    if (state) {
                        return state.name;
                    }
                }
                return stateAbbreviation;
            })
        });
        Object.defineProperty(this, "getStateAbbreviation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (0, mobx_utils_1.computedFn)((countryId, stateFullName) => {
                var _a;
                const states = (_a = this._rawCountries.find((country) => country.id === countryId)) === null || _a === void 0 ? void 0 : _a.states;
                if (states) {
                    const state = states.find((data) => data.name === stateFullName);
                    if (state) {
                        return state.code;
                    }
                }
                return stateFullName;
            })
        });
        (0, mobx_1.makeObservable)(this, {
            _languages: mobx_1.observable,
            _rawCountries: mobx_1.observable,
            _akaTitleTypes: mobx_1.observable,
            _writerRoles: mobx_1.observable,
            countries: mobx_1.computed,
            phoneCountryCodes: mobx_1.computed,
            loadLanguages: mobx_1.action,
            loadAkaTitleTypes: mobx_1.action,
            loadWriterRoles: mobx_1.action,
            loadCountries: mobx_1.action,
        });
    }
    get languages() {
        return this._languages;
    }
    get akaTitleTypes() {
        return this._akaTitleTypes;
    }
    get writerRoles() {
        return this._writerRoles;
    }
    get countries() {
        return this._rawCountries.map((data) => ({
            value: data.id.toString(),
            label: data.name,
            id: data.id.toString(),
        }));
    }
    get phoneCountryCodes() {
        return this._rawCountries
            .reduce((prev, curr) => {
            const found = prev.findIndex((entry) => entry.value === curr.phoneCode.toString());
            if (found > -1) {
                prev[found].label += ', ' + curr.code;
            }
            else {
                prev.push({
                    value: curr.phoneCode.toString(),
                    label: curr.code,
                    id: curr.id.toString(),
                });
            }
            return prev;
        }, [])
            .map((option) => {
            option.label = option.label + ' +' + option.value;
            return option;
        });
    }
}
exports.LibStore = LibStore;
