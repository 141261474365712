"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableCellSanitizer = void 0;
const tableCellSanitizer = (text, placeholder = '--') => {
    if (!text) {
        return placeholder;
    }
    return text.toString();
};
exports.tableCellSanitizer = tableCellSanitizer;
