"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
require("./index.scss");
const Button_1 = require("@Components/Buttons/Button");
const contexts_1 = require("@App/contexts");
const mobx_react_1 = require("mobx-react");
const common_1 = require("@Const/common");
const WizardBackButton = () => {
    const { wizardFlowStore } = (0, react_1.useContext)(contexts_1.StoresContext);
    const onPressed = () => {
        if (wizardFlowStore.currentIndex > 0) {
            wizardFlowStore.decrementIndex();
            wizardFlowStore.setCurrentSubIndex(0);
        }
    };
    return (react_1.default.createElement(Button_1.Button, { mainColor: true, type: 'secondary', icon: common_1.ICONS.arrow_back, reverseIconPosition: true, onClick: onPressed, disabled: wizardFlowStore.currentIndex === 0 }, "Back"));
};
exports.default = (0, mobx_react_1.observer)(WizardBackButton);
