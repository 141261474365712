"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
const rightsholder_statuses_1 = require("@Const/rightsholder-statuses");
const rightsholder_1 = require("@App/api/routes/rightsholder/rightsholder");
class CreateRightsholderStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_questions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_createRightsholder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "loadQuestions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const reply = yield (0, rightsholder_1.getNewRightsholderQuestions)();
                (0, mobx_1.runInAction)(() => {
                    this._questions = reply.data.data;
                });
            })
        });
        Object.defineProperty(this, "setCreateRightsholderForm", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (createRightsholder) => {
                this._createRightsholder = Object.assign(Object.assign({}, this._createRightsholder), createRightsholder);
            }
        });
        Object.defineProperty(this, "setCreateRightsholderQuestionnaire", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (questionAnswers) => {
                if (this._createRightsholder) {
                    this._createRightsholder.questionAnswers = questionAnswers;
                }
                else {
                    this._createRightsholder = {
                        questionAnswers,
                    };
                }
            }
        });
        Object.defineProperty(this, "submitCreateRightsholder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                if (!this.rootStore.userStore.userInfo.isInternalUser && this._createRightsholder) {
                    const createRightsholderRequest = this._createRightsholder;
                    yield (0, rightsholder_1.submitCreateRightsholder)(createRightsholderRequest);
                    yield this.rootStore.rightsholderSearchStore.loadRightsholders({
                        status: rightsholder_statuses_1.RIGHTSHOLDER_GROUP.allDone,
                    });
                    (0, mobx_1.runInAction)(() => (this._createRightsholder = undefined));
                }
            })
        });
        Object.defineProperty(this, "getDefaultCreateRightsholder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => ({
                name: '',
                hfaPublisherNumber: '',
                ipiNumber: '',
                isni: '',
                address: {
                    streetAddress: '',
                    unitNumber: '',
                    city: '',
                    state: '',
                    countryId: '',
                    postalCode: '',
                },
                phoneNumber: {
                    phoneCountryCode: '',
                    phoneAreaCode: '',
                    phoneNumber: '',
                },
                email: '',
                termsAndConditions: false,
            })
        });
        (0, mobx_1.makeObservable)(this, {
            _questions: mobx_1.observable,
            _createRightsholder: mobx_1.observable,
            setCreateRightsholderForm: mobx_1.action,
            setCreateRightsholderQuestionnaire: mobx_1.action,
        });
        this.rootStore = rootStore;
        this._questions = [];
    }
    get questions() {
        return this._questions;
    }
    get createRightsholder() {
        var _a;
        return (_a = this._createRightsholder) !== null && _a !== void 0 ? _a : this.getDefaultCreateRightsholder();
    }
}
exports.default = CreateRightsholderStore;
