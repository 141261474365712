"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const areObjectChildrenUndefined = (obj) => Object.values(obj).every((value) => {
    if (typeof value === 'object' && value !== null) {
        return areObjectChildrenUndefined(value);
    }
    else if (value) {
        return false;
    }
    else {
        return true;
    }
});
const convertEmptyValuesToUndefined = (obj, parentObjToUndefined) => {
    const clone = {};
    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (typeof value === 'object' && value !== null) {
            clone[key] = convertEmptyValuesToUndefined(value);
        }
        else if (Array.isArray(obj)) {
            clone[key] = obj.map((item) => convertEmptyValuesToUndefined(item));
        }
        else if (typeof value === 'string' && value.length === 0) {
            clone[key] = undefined;
        }
        else if (Object.getOwnPropertyDescriptor(obj, key)) {
            clone[key] = value;
        }
    });
    if (!!parentObjToUndefined && areObjectChildrenUndefined(clone)) {
        return undefined;
    }
    return clone;
};
exports.default = convertEmptyValuesToUndefined;
