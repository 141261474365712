"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PERMISSION_CATEGORIES = exports.PERMISSION_TITLES = exports.PERMISSION_NAMES = exports.PERMISSIONS = void 0;
exports.PERMISSIONS = {
    CATALOG_PERMISSIONS: {
        full: 'full',
        manage_works: 'manage_works',
        view_work_registration_status: 'view_work_registration_status',
        view_works: 'view_works',
    },
    RIGHTSHOLDER_PERMISSIONS: {
        full: 'full',
        manage_banking_and_tax: 'manage_banking_and_tax',
        manage_contact_and_address: 'manage_contact_and_address',
    },
    SUPER_USER_PERMISSIONS: {
        full: 'full',
        manage_user_permissions: 'manage_user_permissions',
    },
};
exports.PERMISSION_NAMES = [
    'manage_user_permissions',
    'view_works',
    'manage_works',
    'view_work_registration_status',
    'view_members',
    'manage_contact_and_address',
    'manage_banking_and_tax',
];
exports.PERMISSION_TITLES = [
    'Manage user permissions',
    'View works',
    'Manage works',
    'View work registration status',
    'View members',
    'Manage contact and address',
    'Manage banking and tax',
];
exports.PERMISSION_CATEGORIES = [
    'CATALOG_PERMISSIONS',
    'RIGHTSHOLDER_PERMISSIONS',
    'SUPER_USER_PERMISSIONS',
    'REPORTING_PERMISSIONS',
];
