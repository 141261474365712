"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValueForm = void 0;
const validate_1 = require("@App/helpers/validate");
const isNumeric_1 = require("@App/helpers/isNumeric");
const regexp_1 = require("@Const/regexp");
const error_messages_1 = require("@Const/error-messages");
/**
 * @deprecated Use updateValidation = (object: any, validations: any): validations instead found in src/helpers/validation/form-validation.ts
 */
const getValueForm = (value, id, formObject, validationObject) => {
    if (typeof value === 'string') {
        formObject[id] = value.trim();
    }
    else {
        formObject[id] = value;
    }
    const currentFieldValidation = validationObject[id];
    const requiredMessage = error_messages_1.REQUIRED_MESSAGE;
    const isNumberMessage = error_messages_1.IS_NUMBER_MESSAGE;
    const maxLengthMessage = `${error_messages_1.MAX_LENGTH_MESSAGE} ${currentFieldValidation.maxLength}`;
    const minLengthMessage = `${error_messages_1.MIN_LENGTH_MESSAGE} ${currentFieldValidation.minLength}`;
    const staticLengthMessage = `${error_messages_1.STATIC_LENGTH_MESSAGE} ${currentFieldValidation.staticLength}`;
    const emailMessage = error_messages_1.EMAIL_MESSAGE;
    const noReservedEmailMessage = error_messages_1.NO_RESERVED_EMAIL_MESSAGE;
    const isDurationMessage = error_messages_1.IS_DURATION_MESSAGE;
    const isLanguageMessage = error_messages_1.IS_LANGUAGE_MESSAGE;
    const isDateMessage = error_messages_1.IS_DATE_MESSAGE;
    const isISWC = error_messages_1.IS_ISWC_MESSAGE;
    const isIPI = error_messages_1.IS_IPI_MESSAGE;
    // MPOR-1746
    // It's too late to change every validation to pass via a function
    // specific to it, but I'm adding this here as a possible future
    // gradual change we can make to have validation logic for a
    // specific field encapsulated within its own testable function.
    // Generic validations aren't ideal for industry-specific identifiers
    // and the such.
    if (currentFieldValidation.pipeFunction) {
        if (currentFieldValidation.pipeFunction(value)) {
            switch (id) {
                case 'isrc':
                    currentFieldValidation.error =
                        'ISRC must be alpha-numeric, 12 characters long, the first two characters must be letters, and the last' +
                            ' 7 characters must be numbers';
                    break;
                case 'title':
                    currentFieldValidation.error =
                        'Work Title must be less than 300 characters, and may not include multiple lines';
                    break;
                default:
                    break;
            }
        }
        else {
            currentFieldValidation.error = '';
        }
    }
    else {
        // We'll handle legacy logic here...
        if (typeof value === 'string' && currentFieldValidation.required && (0, validate_1.valueIsEmpty)(value)) {
            currentFieldValidation.error = requiredMessage;
        }
        else if (typeof value === 'boolean' && currentFieldValidation.required && !value) {
            currentFieldValidation.error = requiredMessage;
        }
        else if (currentFieldValidation.isNumber && value && !(0, isNumeric_1.isNumeric)(value)) {
            currentFieldValidation.error = isNumberMessage;
        }
        else if (typeof value === 'string' &&
            currentFieldValidation.maxLength &&
            value.length > currentFieldValidation.maxLength) {
            currentFieldValidation.error = maxLengthMessage;
        }
        else if (typeof value === 'string' &&
            currentFieldValidation.minLength &&
            value.length < currentFieldValidation.minLength) {
            currentFieldValidation.error = minLengthMessage;
        }
        else if (typeof value === 'string' &&
            currentFieldValidation.staticLength &&
            value &&
            value.length !== currentFieldValidation.staticLength) {
            currentFieldValidation.error = staticLengthMessage;
        }
        else if (typeof value === 'string' && currentFieldValidation.isEmail && value && !(0, validate_1.emailIsValid)(value)) {
            currentFieldValidation.error = emailMessage;
        }
        else if (typeof value === 'string' &&
            currentFieldValidation.noReservedEmail &&
            value &&
            !(0, validate_1.isNotAnReservedEmail)(value)) {
            currentFieldValidation.error = noReservedEmailMessage;
        }
        else if (typeof value === 'string' &&
            currentFieldValidation.isDuration &&
            value &&
            !value.match(/^[0-9]{2}[:]{1}[0-9]{2}$/)) {
            currentFieldValidation.error = isDurationMessage;
        }
        else if (currentFieldValidation.isLanguage && currentFieldValidation.required && !value) {
            currentFieldValidation.error = isLanguageMessage;
        }
        else if (typeof value === 'string' && currentFieldValidation.isDate && value && !isValidDate(value)) {
            currentFieldValidation.error = isDateMessage;
        }
        else if (typeof value === 'string' && currentFieldValidation.isISWC && value && !(0, validate_1.ISWCValidator)(value)) {
            currentFieldValidation.error = isISWC;
        }
        else if (typeof value === 'string' && currentFieldValidation.isIPI && value && !(0, validate_1.IPIValidator)(value, false)) {
            currentFieldValidation.error = isIPI;
        }
        else {
            currentFieldValidation.error = '';
        }
    }
    return {
        formObject,
        validationObject,
    };
};
exports.getValueForm = getValueForm;
const isValidDate = (date) => date.match(regexp_1.DATE_REGEX);
