"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CLEAR_WORKS_FROM_UPLOAD = exports.CLEAR_WORK_DRAFT = void 0;
exports.CLEAR_WORK_DRAFT = {
    title: '',
    createdDate: '2020-12-12T00:00:00Z',
    duration: null,
    languageCode: null,
    copyrightOfficeNumber: null,
    copyrightOfficeDate: null,
    firstUseRefusalIndicator: null,
    rightsholderProprietaryId: null,
    iswc: null,
    alternativeTitles: [],
    originalPublishers: [],
    writers: [],
    recordings: [],
    matchedRecordings: {
        count: 0,
        recordings: [],
    },
    isComplete: false,
    errors: [],
    updateDate: null,
    totalKnownShares: 0,
    lastUpdated: '',
    registrationHistoryStatus: null,
    registrationHistoryIndicator: null,
    parent: null,
    registrationHistoryDate: null,
    propertyId: null,
};
exports.CLEAR_WORKS_FROM_UPLOAD = {
    complete: [],
    error: [],
    completeCount: 0,
    count: 0,
    errorCount: 0,
    isComplete: false,
    fileId: 0,
};
