"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOverclaimToolTip = exports.getOverclaimStatusText = exports.OVERCLAIM_FILTER_STATUS = void 0;
exports.OVERCLAIM_FILTER_STATUS = {
    OVERCLAIM: 'Overclaim',
    DISPUTE: 'Dispute',
    RESOLVED: 'Resolved',
    MERGED: 'Merged',
    CANCELED: 'Canceled',
};
const getOverclaimStatusText = (status) => {
    switch (status) {
        case 'OVERCLAIM':
            return 'Overclaim';
        case 'DISPUTE':
            return 'Dispute';
        case 'RESOLVED':
            return 'Resolved';
        case 'MERGED':
            return 'Merged';
        case 'CANCELED':
            return 'Canceled';
        default:
            return null;
    }
};
exports.getOverclaimStatusText = getOverclaimStatusText;
const getOverclaimToolTip = (status) => {
    switch (status) {
        case 'OVERCLAIM':
            return 'This work has submitted shares totaling over 100%.';
        case 'DISPUTE':
            return 'This work is in dispute.';
        case 'RESOLVED':
            return 'This work is no longer in overclaim.';
        case 'MERGED':
            return 'This work has been merged into another work.';
        case 'CANCELED':
            return 'The overclaim on this work has been cancelled.';
        default:
            return 'Overclaim';
    }
};
exports.getOverclaimToolTip = getOverclaimToolTip;
