"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLatestUpdateDate = void 0;
const getLatestUpdateDate = (work) => {
    let timeStamps = [];
    const entities = [
        work,
        ...work.alternativeTitles,
        ...work.writers,
        ...work.recordings,
        ...work.originalPublishers,
        ...work.originalPublishers.flatMap((op) => op.administratorPublishers),
    ];
    entities.forEach((entity) => {
        timeStamps = addDatesToArray(entity, timeStamps);
    });
    if (timeStamps.length >= 1) {
        return new Date(Math.max(...timeStamps));
    }
    return null;
};
exports.getLatestUpdateDate = getLatestUpdateDate;
// This is necessary because Math.max cannot take Maybe<number>, so we have to guarantee it exists.
const addDatesToArray = (entity, dates) => {
    if (entity.lastUpdated && entity.lastUpdated !== '') {
        const time = new Date(entity.lastUpdated).getTime();
        dates.push(time);
        return dates;
    }
    return dates;
};
