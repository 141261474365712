"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
const rightsholder_1 = require("@App/api/routes/rightsholder/rightsholder");
const rightsholder_statuses_1 = require("@Const/rightsholder-statuses");
class CurrentRightsholderStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_currentRightsholderId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_rightsholderClaimedShares", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_rightsholderSummary", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_payees", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "setCurrentRightsholderId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => __awaiter(this, void 0, void 0, function* () {
                if (this._currentRightsholderId !== rightsholderId) {
                    this.rootStore.preloaderStore.openTransparentPreloader(true);
                    this._currentRightsholderId = rightsholderId;
                    yield this.loadRightsholderSummary();
                    this.rootStore.preloaderStore.openTransparentPreloader(false);
                }
            })
        });
        Object.defineProperty(this, "loadClaimedShares", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (searchWorkIds) => __awaiter(this, void 0, void 0, function* () {
                const response = yield (0, rightsholder_1.getClaimedShares)(this.currentRightsholderId, searchWorkIds);
                (0, mobx_1.runInAction)(() => (this._rightsholderClaimedShares = response.data));
            })
        });
        Object.defineProperty(this, "loadRightsholderSummary", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const response = yield (0, rightsholder_1.getRightsholderSummary)(this.currentRightsholderId);
                (0, mobx_1.runInAction)(() => (this._rightsholderSummary = response.data));
            })
        });
        Object.defineProperty(this, "editRightsholder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, data) => __awaiter(this, void 0, void 0, function* () {
                const response = yield (0, rightsholder_1.editRightsholder)(rightsholderId, data);
                (0, mobx_1.runInAction)(() => (this._rightsholderSummary = response.data));
                const userInfo = this.rootStore.userStore.userInfo;
                const isInternal = userInfo && userInfo.isInternalUser;
                if (!isInternal) {
                    yield this.rootStore.rightsholderSearchStore.loadRightsholders({
                        status: rightsholder_statuses_1.RIGHTSHOLDER_GROUP.allDone,
                    });
                }
            })
        });
        Object.defineProperty(this, "loadPayees", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const payees = yield (0, rightsholder_1.getAllPayees)(this.currentRightsholderId);
                (0, mobx_1.runInAction)(() => (this._payees = payees.data.data));
            })
        });
        (0, mobx_1.makeObservable)(this, {
            _currentRightsholderId: mobx_1.observable,
            _rightsholderClaimedShares: mobx_1.observable,
            _rightsholderSummary: mobx_1.observable,
            _payees: mobx_1.observable,
            currentRightsholder: mobx_1.computed,
            payeeOptions: mobx_1.computed,
            setCurrentRightsholderId: mobx_1.action,
            destroyRightsholder: mobx_1.action,
            resetClaimedShares: mobx_1.action,
        });
        this.rootStore = rootStore;
        this._payees = [];
    }
    get currentRightsholderId() {
        if (this._currentRightsholderId) {
            return this._currentRightsholderId;
        }
        else {
            throw new Error('Rightsholder ID Is Missing');
        }
    }
    get optionalCurrentRightsholderId() {
        return this._currentRightsholderId;
    }
    get rightsholderClaimedShares() {
        return this._rightsholderClaimedShares;
    }
    get rightsholderSummary() {
        if (this._rightsholderSummary) {
            return this._rightsholderSummary;
        }
        else {
            throw new Error('Rightsholder Summary Is Missing');
        }
    }
    get optionalRightsholderSummary() {
        return this._rightsholderSummary;
    }
    get currentRightsholder() {
        const allDoneRightsHolders = this.rootStore.rightsholderSearchStore.rightsholders;
        const currentRightsHolderId = this.optionalCurrentRightsholderId;
        if (allDoneRightsHolders && currentRightsHolderId) {
            return allDoneRightsHolders.rightsholders.find((item) => item.id === currentRightsHolderId);
        }
        return null;
    }
    get payeeOptions() {
        return this._payees.map((payee) => ({
            id: payee.payeeIpId.toString(),
            value: payee.payeeIpId.toString(),
            label: payee.payeeName,
        }));
    }
    destroyRightsholder() {
        this._currentRightsholderId = undefined;
        this._rightsholderSummary = undefined;
    }
    resetClaimedShares() {
        this._rightsholderClaimedShares = { workClaimedShares: [] };
    }
}
exports.default = CurrentRightsholderStore;
