"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RECORDING_GROUP_STATUS = void 0;
// Values required by the API
var RECORDING_GROUP_STATUS;
(function (RECORDING_GROUP_STATUS) {
    RECORDING_GROUP_STATUS["SUBMITTED"] = "SUBMITTED";
    RECORDING_GROUP_STATUS["ACCEPTED"] = "ACCEPTED";
    RECORDING_GROUP_STATUS["REJECTED"] = "REJECTED";
    RECORDING_GROUP_STATUS["SUPERSEDED"] = "SUPERSEDED";
})(RECORDING_GROUP_STATUS = exports.RECORDING_GROUP_STATUS || (exports.RECORDING_GROUP_STATUS = {}));
