"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STANDARD_EMAIL = exports.OPTIONAL_LAST_NAME = exports.STANDARD_LAST_NAME = exports.STANDARD_FIRST_NAME = exports.PASSWORD_LABEL = exports.EMAIL_LABEL = exports.LAST_NAME_LABEL = exports.FIRST_NAME_LABEL = void 0;
const error_messages_1 = require("@Const/error-messages");
const regexp_1 = require("@Const/regexp");
const validate_1 = require("@App/helpers/validate");
const form_field_types_1 = require("@App/enum/form-field-types");
exports.FIRST_NAME_LABEL = 'First Name';
exports.LAST_NAME_LABEL = 'Last Name';
exports.EMAIL_LABEL = 'Email Address';
exports.PASSWORD_LABEL = 'Password';
exports.STANDARD_FIRST_NAME = {
    name: 'firstName',
    label: exports.FIRST_NAME_LABEL,
    type: form_field_types_1.FormFieldTypes.TEXT,
    registerOptions: {
        required: error_messages_1.REQUIRED_MESSAGE,
        maxLength: {
            value: 60,
            message: `${error_messages_1.MAX_LENGTH_MESSAGE} 60`,
        },
    },
};
exports.STANDARD_LAST_NAME = {
    name: 'lastName',
    label: exports.LAST_NAME_LABEL,
    type: form_field_types_1.FormFieldTypes.TEXT,
    registerOptions: {
        required: error_messages_1.REQUIRED_MESSAGE,
        maxLength: {
            value: 60,
            message: `${error_messages_1.MAX_LENGTH_MESSAGE} 60`,
        },
    },
};
exports.OPTIONAL_LAST_NAME = {
    name: 'lastName',
    label: exports.LAST_NAME_LABEL,
    type: form_field_types_1.FormFieldTypes.TEXT,
    registerOptions: {
        maxLength: {
            value: 60,
            message: `${error_messages_1.MAX_LENGTH_MESSAGE} 60`,
        },
    },
};
exports.STANDARD_EMAIL = {
    name: 'email',
    label: exports.EMAIL_LABEL,
    type: form_field_types_1.FormFieldTypes.TEXT,
    registerOptions: {
        required: error_messages_1.REQUIRED_MESSAGE,
        pattern: {
            value: regexp_1.EMAIL_REGEX,
            message: error_messages_1.EMAIL_MESSAGE,
        },
        maxLength: {
            value: 2000,
            message: `${error_messages_1.MAX_LENGTH_MESSAGE} 2000`,
        },
        validate: validate_1.isNotAnReservedEmail,
    },
};
