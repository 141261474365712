"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
require("./index.scss");
const Button_1 = require("@Components/Buttons/Button");
const contexts_1 = require("@App/contexts");
const mobx_react_1 = require("mobx-react");
const common_1 = require("@Const/common");
const WizardNextButton = ({ disabled }) => {
    const { wizardFlowStore } = (0, react_1.useContext)(contexts_1.StoresContext);
    const onPressed = () => {
        var _a, _b;
        const isStepValid = wizardFlowStore.currentFlowStep.onNextValidation
            ? wizardFlowStore.currentFlowStep.onNextValidation()
            : true;
        if (isStepValid) {
            if (((_a = wizardFlowStore.currentFlowStep) === null || _a === void 0 ? void 0 : _a.forms) &&
                wizardFlowStore.currentSubIndex !== ((_b = wizardFlowStore.currentFlowStep) === null || _b === void 0 ? void 0 : _b.forms.length) - 1) {
                wizardFlowStore.advanceSubIndex();
            }
            else if (wizardFlowStore.currentIndex < wizardFlowStore.flowLength - 1) {
                wizardFlowStore.advanceIndex();
                wizardFlowStore.setCurrentSubIndex(0);
            }
            window.scrollTo({
                top: 90,
                behavior: 'smooth',
            });
        }
    };
    return (react_1.default.createElement(Button_1.Button, { submitType: 'submit', mainColor: true, type: 'primary', icon: common_1.ICONS.arrow_forward, onClick: onPressed, disabled: disabled }, "Next"));
};
exports.default = (0, mobx_react_1.observer)(WizardNextButton);
