"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.markFieldAsRequired = exports.createPhoneNumberFormFields = void 0;
const regexp_1 = require("@Const/regexp");
const error_messages_1 = require("@Const/error-messages");
const form_field_types_1 = require("@App/enum/form-field-types");
const getDefaultPhoneCountryCode = (prefix, optional) => ({
    name: prefix + 'phoneCountryCode',
    label: 'Country',
    type: form_field_types_1.FormFieldTypes.DROPDOWN,
    options: [],
    className: 'phone-code-form-type border',
    showLabelOnSelect: false,
    isPhoneType: true,
    assistiveTextFunction: (required) => (required ? 'Country Required' : ''),
    registerOptions: {
        required: optional ? false : error_messages_1.REQUIRED_MESSAGE,
    },
    isClearable: true,
});
const getDefaultPhoneAreaCode = (prefix, optional) => ({
    name: prefix + 'phoneAreaCode',
    label: 'Area Code',
    type: form_field_types_1.FormFieldTypes.TEXT,
    assistiveTextFunction: (required) => (required ? 'Area Required' : 'Area'),
    registerOptions: {
        required: optional ? false : error_messages_1.REQUIRED_MESSAGE,
        pattern: {
            value: regexp_1.ONLY_NUMBERS_REGEX,
            message: error_messages_1.IS_NUMBER_MESSAGE,
        },
        maxLength: {
            value: 10,
            message: `${error_messages_1.MAX_LENGTH_MESSAGE} 10`,
        },
        minLength: {
            value: 1,
            message: `${error_messages_1.MIN_LENGTH_MESSAGE} 1`,
        },
    },
});
const getDefaultPhoneNumber = (prefix, optional) => ({
    name: prefix + 'phoneNumber',
    label: 'Phone Number',
    assistiveTextFunction: (required) => (required ? 'Number Required' : 'Number'),
    type: form_field_types_1.FormFieldTypes.TEXT,
    registerOptions: {
        required: optional ? false : error_messages_1.REQUIRED_MESSAGE,
        pattern: {
            value: regexp_1.ONLY_NUMBERS_REGEX,
            message: error_messages_1.IS_NUMBER_MESSAGE,
        },
        maxLength: {
            value: 40,
            message: `${error_messages_1.MAX_LENGTH_MESSAGE} 40`,
        },
        minLength: {
            value: 6,
            message: `${error_messages_1.MIN_LENGTH_MESSAGE} 6`,
        },
    },
});
const createPhoneNumberFormFields = (prefix = '', optional) => ({
    phoneCountryCode: getDefaultPhoneCountryCode(prefix, !!optional),
    phoneAreaCode: getDefaultPhoneAreaCode(prefix, !!optional),
    phoneNumber: getDefaultPhoneNumber(prefix, !!optional),
});
exports.createPhoneNumberFormFields = createPhoneNumberFormFields;
const markFieldAsRequired = (phoneNumberFormProps, isOptional) => {
    phoneNumberFormProps.phoneCountryCode = Object.assign(Object.assign({}, phoneNumberFormProps.phoneCountryCode), { registerOptions: Object.assign(Object.assign({}, phoneNumberFormProps.phoneCountryCode.registerOptions), { required: isOptional ? false : error_messages_1.REQUIRED_MESSAGE }) });
    phoneNumberFormProps.phoneNumber = Object.assign(Object.assign({}, phoneNumberFormProps.phoneNumber), { registerOptions: Object.assign(Object.assign({}, phoneNumberFormProps.phoneNumber.registerOptions), { required: isOptional ? false : error_messages_1.REQUIRED_MESSAGE }) });
    return phoneNumberFormProps;
};
exports.markFieldAsRequired = markFieldAsRequired;
