"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDSPLink = void 0;
const getDSPLink = (mNumber, dspReleaseId, senderResourceId) => {
    switch (mNumber) {
        case 'M1777E':
            return `https://open.spotify.com/track/${senderResourceId.slice(14)}`;
        case 'M1789H':
            return `https://music.youtube.com/watch?v=${senderResourceId}`;
        case 'M1841P':
            return `https://tidal.com/browse/track/${senderResourceId}`;
        case 'M0017R':
            return `https://play.anghami.com/song/`;
        case 'M18306':
            return dspReleaseId ? `https://music.apple.com/album/${dspReleaseId}?i=${senderResourceId}` : undefined;
        case 'M1824P':
            return dspReleaseId ? `https://music.amazon.com/albums/${dspReleaseId}/${senderResourceId}` : undefined;
        case 'M1821A':
            return `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${senderResourceId}`;
        case 'M0009T':
            return `https://www.deezer.com/en/track/${senderResourceId}`;
        case 'M14910':
            return `https://app.napster.com/${senderResourceId}`;
        default:
            return undefined;
    }
};
exports.getDSPLink = getDSPLink;
