"use strict";
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
Object.defineProperty(exports, "__esModule", { value: true });
const configs_1 = require("../configs");
const base = (rest) => `${configs_1.configuration.api}/${rest}`;
const base2v = (rest) => `${configs_1.configuration.api2v}/${rest}`;
const paginationPath = (pagination) => {
    if (pagination) {
        return `?page=${pagination.page}&limit=${pagination.limit}${pagination.source ? `&source=${pagination.source}` : ''}`;
    }
    else {
        return;
    }
};
const paginationSearchPath = (pagination) => {
    if (pagination) {
        return `?page=${pagination.page}&limit=${pagination.limit}${pagination.refined ? `&refined` : ''}`;
    }
    else {
        return;
    }
};
const paginationUserPath = (pagination) => {
    if (pagination && !pagination.group) {
        return `?page=${pagination.page}&limit=${pagination.limit}`;
    }
    else if (pagination) {
        return `?page=${pagination.page}&limit=${pagination.limit}&group=${pagination.group}`;
    }
    else {
        return;
    }
};
class Pathes {
}
exports.default = Pathes;
Object.defineProperty(Pathes, "Auth", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (_a = class {
        },
        Object.defineProperty(_a, "signIn", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('security/token')
        }),
        Object.defineProperty(_a, "mfaSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('security/mfa-confirm')
        }),
        Object.defineProperty(_a, "logOut", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('security/logout')
        }),
        Object.defineProperty(_a, "forgotPassword", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('security/forgot-password')
        }),
        Object.defineProperty(_a, "confirmForgotPassword", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('security/confirm-forgot-password')
        }),
        Object.defineProperty(_a, "loginMaintenanceCheck", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('loginMaintenanceCheck')
        }),
        _a)
});
Object.defineProperty(Pathes, "User", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (_b = class {
        },
        Object.defineProperty(_b, "userInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('user/me')
        }),
        Object.defineProperty(_b, "userSetSetting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('user/setting')
        }),
        Object.defineProperty(_b, "userEnable2fa", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('user/enable-2fa')
        }),
        Object.defineProperty(_b, "userGetSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('user/settings')
        }),
        Object.defineProperty(_b, "createUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('user/registration')
        }),
        Object.defineProperty(_b, "attemptKyc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (userExposedId) => base2v(`user/${userExposedId}/kyc/attempt`)
        }),
        Object.defineProperty(_b, "activateUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (activationCode) => base(`user/activation-code/${activationCode}`)
        }),
        Object.defineProperty(_b, "sendResetPasswordCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('security/user-password-validate')
        }),
        Object.defineProperty(_b, "userPassChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('security/user-password-change')
        }),
        Object.defineProperty(_b, "permissionFeatures", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('permission/features')
        }),
        Object.defineProperty(_b, "permissionGroups", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('permission/groups')
        }),
        _b)
});
Object.defineProperty(Pathes, "RightsHolder", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (_c = class {
        },
        Object.defineProperty(_c, "rightsHolders", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('rightsholders')
        }),
        Object.defineProperty(_c, "createRightsHolder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base2v('rightsholder')
        }),
        Object.defineProperty(_c, "newRightsholderQuestionnaire", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base2v('rightsholder/questions')
        }),
        Object.defineProperty(_c, "getRelatedRightsholders", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => base2v(`rightsholder/related`)
        }),
        Object.defineProperty(_c, "verifyRightsholder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (hfaPublisherNumber) => base2v(`rightsholder/verify/${hfaPublisherNumber}`)
        }),
        Object.defineProperty(_c, "editRightsHolder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base(`rightsholder/${rightsholderId}`)
        }),
        Object.defineProperty(_c, "rightsHolderSummary", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base(`rightsholder/${rightsholderId}/summary`)
        }),
        Object.defineProperty(_c, "rightsholderContact", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base2v(`rightsholder/${rightsholderId}/contact`)
        }),
        Object.defineProperty(_c, "rightsholderContacts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base2v(`rightsholder/${rightsholderId}/contacts`)
        }),
        Object.defineProperty(_c, "rightsholderContactById", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, contactId) => base2v(`rightsholder/${rightsholderId}/contact/${contactId}`)
        }),
        Object.defineProperty(_c, "rightsHolderUsers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base(`rightsholder/${rightsholderId}/users`)
        }),
        Object.defineProperty(_c, "rightsHolderUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, userId) => base(`rightsholder/${rightsholderId}/user/${userId}`)
        }),
        Object.defineProperty(_c, "actionRightsHolderUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base(`rightsholder/${rightsholderId}/user`)
        }),
        Object.defineProperty(_c, "rightsHolderGroupsPermissions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base(`rightsholder/${rightsholderId}/permission-groups`)
        }),
        Object.defineProperty(_c, "tipaltiIframeUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, payeeIpId) => base2v(`rightsholder/${rightsholderId}/payees/${payeeIpId}/iframe`)
        }),
        Object.defineProperty(_c, "rightsholderRegData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('rightsholder-reg-data')
        }),
        Object.defineProperty(_c, "claimedShares", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base(`rightsholder/${rightsholderId}/works/shares`)
        }),
        // statements "pathes"
        Object.defineProperty(_c, "dspRates", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (year, month, dsp) => base2v(`statements/rates/years/${year}${month ? `/months/${month}` : ''}${dsp ? `/dsps/${dsp}` : ''}`)
        }),
        Object.defineProperty(_c, "ratePeriods", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => base2v('statements/rates/usageperiods')
        }),
        Object.defineProperty(_c, "rateDsps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => base2v('statements/rates/dsps')
        }),
        Object.defineProperty(_c, "rateSheetDownload", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (downloadLink) => base2v(downloadLink)
        }),
        Object.defineProperty(_c, "royalties", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, year, month) => base2v(`statements/${rightsholderId}/payees/years/${year}${month ? `/months/${month}` : ''}`)
        }),
        Object.defineProperty(_c, "payeeActivityDownload", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pasLink) => base2v(pasLink)
        }),
        Object.defineProperty(_c, "latestRoyalties", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base2v(`statements/${rightsholderId}/options`)
        }),
        Object.defineProperty(_c, "payeeOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base2v(`statements/${rightsholderId}/payees/options`)
        }),
        Object.defineProperty(_c, "allPayees", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base2v(`rightsholder/${rightsholderId}/payees`)
        }),
        Object.defineProperty(_c, "statements", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, payee) => base2v(`statements/${rightsholderId}/payees/${payee}`)
        }),
        Object.defineProperty(_c, "tsvRequest", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => base2v(`statements/request`)
        }),
        Object.defineProperty(_c, "annualTSVRequest", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base2v(`statements/${rightsholderId}/request/annual`)
        }),
        // processing analytics
        Object.defineProperty(_c, "paymentsYTD", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base2v(`processing/analytics/${rightsholderId}/paid/yeartodate`)
        }),
        Object.defineProperty(_c, "paymentsLastDistribution", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base2v(`processing/analytics/${rightsholderId}/paid/lastdistribution`)
        }),
        Object.defineProperty(_c, "paymentsForDistributions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, distributionCount) => base2v(`processing/analytics/${rightsholderId}/paid/period/${distributionCount}`)
        }),
        // unmatched "pathes"
        Object.defineProperty(_c, "unclaimedSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pagination) => base(`search/matching${paginationSearchPath(pagination)}&unclaimed=true`)
        }),
        Object.defineProperty(_c, "unmatchedSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (queryString) => base(`dsp-recording?${queryString}`)
        }),
        Object.defineProperty(_c, "dspRecordingDetails", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (recordingId) => base(`dsp-recording/${recordingId}`)
        }),
        Object.defineProperty(_c, "dspRecordingsForSongCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (songCode, pagination, order = 'matchedAmount') => base(`dsp-recording/matched/${songCode}${paginationPath(pagination)}&order=${order}&direction=desc`)
        }),
        Object.defineProperty(_c, "createMatchSuggestion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base(`dsp-recording/rightsholder/${rightsholderId}/suggested-matches`)
        }),
        Object.defineProperty(_c, "matchSuggestions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, status, pagination) => {
                var _a, _b;
                const url = new URL(base2v(`matching/members/${rightsholderId}/suggestions`));
                status.forEach((thisStatus) => url.searchParams.append('status', thisStatus));
                if (pagination) {
                    url.searchParams.append('size', ((_a = pagination.limit) !== null && _a !== void 0 ? _a : 10).toString());
                    url.searchParams.append('page', ((_b = pagination.page) !== null && _b !== void 0 ? _b : 1).toString());
                }
                return url.toString();
            }
        }),
        Object.defineProperty(_c, "singleMatchSuggestion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, suggestionId) => base2v(`matching/members/${rightsholderId}/suggestions/${suggestionId}`)
        }),
        Object.defineProperty(_c, "recordingGroupDetails", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, suggestionId, recordingGroupStatus, pagination) => {
                var _a, _b;
                const url = new URL(base2v(`matching/members/${rightsholderId}/suggestions/${suggestionId}/recording_groups`));
                recordingGroupStatus.forEach((thisStatus) => url.searchParams.append('status', thisStatus));
                if (pagination) {
                    url.searchParams.append('size', ((_a = pagination.limit) !== null && _a !== void 0 ? _a : 10).toString());
                    url.searchParams.append('page', ((_b = pagination.page) !== null && _b !== void 0 ? _b : 1).toString());
                }
                return url.toString();
            }
        }),
        Object.defineProperty(_c, "matchSummary", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base2v(`matching/members/${rightsholderId}/summary`)
        }),
        // Overclaim "pathes"
        Object.defineProperty(_c, "overclaimViewByHeaderId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (overclaimHeaderId, rightsholderId) => base2v(`rightsholder/${rightsholderId}/overclaim/${overclaimHeaderId}`)
        }),
        Object.defineProperty(_c, "overclaimHistoryByHeaderId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (overclaimHeaderId, rightsholderId) => base2v(`rightsholder/${rightsholderId}/overclaim/${overclaimHeaderId}/history`)
        }),
        Object.defineProperty(_c, "overclaimStatusCounts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base(`rightsholders/${rightsholderId}/overclaim/count`)
        }),
        Object.defineProperty(_c, "sendOverclaimEmail", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (overclaimHeaderId, rightsholderId) => base2v(`rightsholder/${rightsholderId}/overclaim/${overclaimHeaderId}/email`)
        }),
        Object.defineProperty(_c, "renderOverclaimEmailTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (overclaimHeaderId, rightsholderId) => base2v(`rightsholder/${rightsholderId}/overclaim/${overclaimHeaderId}/email-template`)
        }),
        Object.defineProperty(_c, "amendOverclaimShares", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (overclaimHeaderId, rightsholderId) => base2v(`rightsholder/${rightsholderId}/overclaim/${overclaimHeaderId}/amend`)
        }),
        Object.defineProperty(_c, "createRelinquishment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, overclaimHeaderId) => base2v(`rightsholder/${rightsholderId}/overclaim/${overclaimHeaderId}/create-relinquishment`)
        }),
        Object.defineProperty(_c, "getRelinquishments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, overclaimHeaderId) => base2v(`rightsholder/${rightsholderId}/overclaim/${overclaimHeaderId}/relinquishments`)
        }),
        _c)
});
Object.defineProperty(Pathes, "Report", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (_d = class {
        },
        Object.defineProperty(_d, "formats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (type) => base2v(`report/${type}/formats`)
        }),
        Object.defineProperty(_d, "export", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, exportType) => base2v(`report/${exportType}/rightsholder/${rightsholderId}`)
        }),
        _d)
});
Object.defineProperty(Pathes, "Search", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (_e = class {
        },
        Object.defineProperty(_e, "searchWorks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pagination) => base(`search/work${paginationSearchPath(pagination)}`)
        }),
        Object.defineProperty(_e, "searchWorksV2", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pagination) => {
                var _a;
                const url = new URL(base2v(`public/search/works`));
                if (pagination) {
                    url.searchParams.append('size', ((_a = pagination.limit) !== null && _a !== void 0 ? _a : 10).toString());
                    url.searchParams.append('page', (pagination.page ? pagination.page - 1 : 0).toString());
                }
                return url.toString();
            }
        }),
        Object.defineProperty(_e, "searchWorksRH", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, pagination) => base(`rightsholders/${rightsholderId}/catalog/search${paginationSearchPath(pagination)}`)
        }),
        Object.defineProperty(_e, "searchWorksRHV2", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, pagination) => {
                var _a, _b, _c;
                const url = new URL(base2v(`search/works/rightsholder/${rightsholderId}/catalog`));
                if (pagination) {
                    url.searchParams.append('size', ((_a = pagination.limit) !== null && _a !== void 0 ? _a : 10).toString());
                    url.searchParams.append('page', (pagination.page ? pagination.page - 1 : 0).toString());
                    if (((_b = pagination.sort) === null || _b === void 0 ? void 0 : _b.field) !== undefined) {
                        const sort = pagination.sort;
                        url.searchParams.append('sort', `${sort.field},${(_c = sort.direction) !== null && _c !== void 0 ? _c : 'asc'}`);
                    }
                }
                return url.toString();
            }
        }),
        Object.defineProperty(_e, "searchOverclaimsRH", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, pagination) => base(`rightsholders/${rightsholderId}/overclaim/search${paginationSearchPath(pagination)}`)
        }),
        Object.defineProperty(_e, "searchWorksForPublisherV2", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (publisherIpId, pagination) => {
                var _a;
                const url = new URL(base2v(`public/search/works/publisher/${publisherIpId}`));
                if (pagination) {
                    url.searchParams.append('size', ((_a = pagination.limit) !== null && _a !== void 0 ? _a : 10).toString());
                    url.searchParams.append('page', (pagination.page ? pagination.page - 1 : 0).toString());
                    url.searchParams.append('sort', 'title.keyword');
                }
                return url.toString();
            }
        }),
        Object.defineProperty(_e, "searchWorksHistoryRH", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, pagination) => base(`search/rightsholder/${rightsholderId}/works/history${paginationPath(pagination)}`)
        }),
        Object.defineProperty(_e, "searchWorksDraftsRH", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, pagination) => base(`search/rightsholder/${rightsholderId}/works/drafts${paginationPath(pagination)}`)
        }),
        Object.defineProperty(_e, "searchUsers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, pagination) => base(`search/rightsholder/${rightsholderId}/users${paginationUserPath(pagination)}`)
        }),
        Object.defineProperty(_e, "searchRightsHolders", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pagination) => base(`search/rightsholder${paginationPath(pagination)}`)
        }),
        Object.defineProperty(_e, "searchWriters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pagination) => base(`search/writer${paginationPath(pagination)}`)
        }),
        Object.defineProperty(_e, "searchWritersV2", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pagination) => {
                var _a;
                const url = new URL(base2v(`public/search/works/writers`));
                if (pagination) {
                    url.searchParams.append('size', ((_a = pagination.limit) !== null && _a !== void 0 ? _a : 10).toString());
                    url.searchParams.append('page', (pagination.page ? pagination.page - 1 : 0).toString());
                }
                return url.toString();
            }
        }),
        Object.defineProperty(_e, "searchPublishers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pagination) => base(`search/publisher${paginationPath(pagination)}`)
        }),
        Object.defineProperty(_e, "searchPublishersV2", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pagination) => {
                var _a;
                const url = new URL(base2v(`public/search/works/publishers`));
                if (pagination) {
                    url.searchParams.append('size', ((_a = pagination.limit) !== null && _a !== void 0 ? _a : 10).toString());
                    url.searchParams.append('page', (pagination.page ? pagination.page - 1 : 0).toString());
                }
                return url.toString();
            }
        }),
        Object.defineProperty(_e, "searchPublishersForRH", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsHolderId, pagination) => base(`search/${rightsHolderId}/publisher${paginationPath(pagination)}`)
        }),
        _e)
});
Object.defineProperty(Pathes, "Work", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (_f = class {
        },
        Object.defineProperty(_f, "worksAll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base(`works`)
        }),
        Object.defineProperty(_f, "worksRH", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, pagination) => base(`rightsholder/${rightsholderId}/works${paginationPath(pagination)}`)
        }),
        Object.defineProperty(_f, "worksHistoryRH", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, pagination) => base(`rightsholder/${rightsholderId}/works/history${paginationPath(pagination)}`)
        }),
        Object.defineProperty(_f, "worksDraftsRH", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, pagination) => base(`rightsholder/${rightsholderId}/works/drafts${pagination ? paginationPath(pagination) : ''}`)
        }),
        Object.defineProperty(_f, "workDraftRH", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (draftId) => base(`drafts/${draftId}`)
        }),
        Object.defineProperty(_f, "createWork", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId) => base(`rightsholder/${rightsholderId}/work`)
        }),
        Object.defineProperty(_f, "updateWork", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (workId) => base(`work/${workId}`)
        }),
        Object.defineProperty(_f, "workById", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (workId, rightsHolderId) => base(`work/${rightsHolderId}/${workId}/summary`)
        }),
        Object.defineProperty(_f, "submissionById", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (workId) => base(`submission/transaction/${workId}`)
        }),
        Object.defineProperty(_f, "summaryById", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (workId, rightsHolderId) => base(`rightsholders/${rightsHolderId}/catalog/${workId}/summary`)
        }),
        Object.defineProperty(_f, "catalogWorkById", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (workId) => base(`catalog/work/${workId}`)
        }),
        Object.defineProperty(_f, "createWriter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('writer')
        }),
        _f)
});
Object.defineProperty(Pathes, "Lib", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (_g = class {
        },
        Object.defineProperty(_g, "langs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('lib/languages')
        }),
        Object.defineProperty(_g, "countries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('lib/countries')
        }),
        Object.defineProperty(_g, "writerRoles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('lib/writer-roles')
        }),
        Object.defineProperty(_g, "akaTitleTypes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('lib/aka-title-types')
        }),
        _g)
});
Object.defineProperty(Pathes, "Upload", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (_h = class {
        },
        Object.defineProperty(_h, "files", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pagination, rightsholderId) => base(`upload/${rightsholderId}/file${paginationPath(pagination)}`)
        }),
        Object.defineProperty(_h, "works", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, file) => base(`upload/${rightsholderId}/works${file ? `/${file}` : ''}`)
        }),
        Object.defineProperty(_h, "downloadTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: `${configs_1.configuration.simple}/MLCBulkWork_V1.1.xlsx`
        }),
        _h)
});
Object.defineProperty(Pathes, "Support", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (_j = class {
        },
        Object.defineProperty(_j, "support", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('support')
        }),
        Object.defineProperty(_j, "helpForm", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: base('support/contact-us')
        }),
        _j)
});
Object.defineProperty(Pathes, "Public", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (_k = class {
        },
        Object.defineProperty(_k, "contacts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (ipIds) => base2v(`public/contacts/${ipIds}`)
        }),
        _k)
});
