"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WriterSearchStore = void 0;
const mobx_1 = require("mobx");
const search_1 = require("@App/api/routes/search");
const check_is_empty_object_1 = require("@App/helpers/check-is-empty-object");
const calc_num_pages_1 = require("@App/helpers/pagination/calc-num-pages");
const convert_new_search_terms_1 = require("@App/helpers/convert-new-search-terms");
class WriterSearchStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_writerSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                writerName: '',
                writerIpi: '',
            }
        });
        Object.defineProperty(this, "_writersSearchModel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                count: 0,
                results: [],
            }
        });
        Object.defineProperty(this, "_isSearching", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "_writersSearchResultPaginationInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "setWriterSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (writerSearch) => {
                this._writerSearch = writerSearch;
            }
        });
        Object.defineProperty(this, "searchWriters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pagination = { page: 1, limit: 5 }) => __awaiter(this, void 0, void 0, function* () {
                this._isSearching = true;
                if (this._writerSearch && !(0, check_is_empty_object_1.isEmptyObject)(this._writerSearch)) {
                    const writersSearchModel = this.rootStore.userStore.useNewSearch
                        ? yield this.searchWritersNew(pagination, this._writerSearch)
                        : yield this.searchWritersLegacy(pagination, this._writerSearch);
                    (0, mobx_1.runInAction)(() => {
                        if (pagination.limit && pagination.page && writersSearchModel) {
                            this._writersSearchResultPaginationInfo = (0, calc_num_pages_1.calcNumPages)(writersSearchModel.count, pagination.limit, pagination.page);
                        }
                    });
                    (0, mobx_1.runInAction)(() => (this._writersSearchModel = writersSearchModel));
                    (0, mobx_1.runInAction)(() => (this._isSearching = false));
                }
                else {
                    (0, mobx_1.runInAction)(() => {
                        this._writersSearchModel = {
                            count: 0,
                            results: [],
                        };
                        this._isSearching = false;
                    });
                }
            })
        });
        Object.defineProperty(this, "searchWritersLegacy", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pagination, search) => __awaiter(this, void 0, void 0, function* () {
                const result = yield (0, search_1.searchWriters)(pagination, search);
                return result.data;
            })
        });
        Object.defineProperty(this, "searchWritersNew", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pagination, search) => __awaiter(this, void 0, void 0, function* () {
                const writersSearchModel = yield (0, search_1.searchWritersNew)(pagination, (0, convert_new_search_terms_1.convertWriterSearchTerms)(search));
                return {
                    results: writersSearchModel.data.content.map((writer) => ({ entity: writer, highlights: {} })),
                    count: writersSearchModel.data.totalElements,
                };
            })
        });
        Object.defineProperty(this, "clearStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this._writersSearchModel = {
                    count: 0,
                    results: [],
                };
                this._writerSearch = {
                    writerName: '',
                    writerIpi: '',
                };
                this._isSearching = false;
            }
        });
        (0, mobx_1.makeObservable)(this, {
            _writerSearch: mobx_1.observable,
            _writersSearchModel: mobx_1.observable,
            _isSearching: mobx_1.observable,
            _writersSearchResultPaginationInfo: mobx_1.observable,
            isThereSearchResults: mobx_1.computed,
            setWriterSearch: mobx_1.action,
            searchWriters: mobx_1.action,
            clearStore: mobx_1.action,
        });
        this.rootStore = rootStore;
    }
    get writerSearch() {
        return this._writerSearch;
    }
    get writersSearchModel() {
        return this._writersSearchModel;
    }
    get isSearching() {
        return this._isSearching;
    }
    get isThereSearchResults() {
        return !!this._writersSearchModel && this._writersSearchModel.count > 0;
    }
}
exports.WriterSearchStore = WriterSearchStore;
