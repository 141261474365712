"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapRecordingToRecordingModel = void 0;
const seconds_to_duration_1 = require("@App/helpers/seconds-to-duration");
const mapRecordingToRecordingModel = (recording) => ({
    id: recording.id,
    title: recording.recordingFullTitle,
    artistName: recording.recordingDisplayArtistName,
    isrc: recording.isrc,
    duration: (0, seconds_to_duration_1.secondsToDuration)(parseInt(recording.duration, 10)),
    label: recording.label,
    dsp: recording.dsp,
    releaseDate: recording.releaseDate,
});
exports.mapRecordingToRecordingModel = mapRecordingToRecordingModel;
