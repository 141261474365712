"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.userinfoToZodMapper = void 0;
const utc_to_local_tz_without_time_change_1 = require("@App/helpers/utc-to-local-tz-without-time-change");
const userinfoToZodMapper = (userInfoData) => {
    var _a;
    const { phoneCountryCode, phoneAreaCode } = userInfoData, withoutPhone = __rest(userInfoData, ["phoneCountryCode", "phoneAreaCode"]);
    const { streetAddress, unitNumber, city, state, postalCode, country } = withoutPhone, withoutPhoneAddress = __rest(withoutPhone, ["streetAddress", "unitNumber", "city", "state", "postalCode", "country"]);
    const address = {
        streetAddress,
        unitNumber: unitNumber ? unitNumber : '',
        city,
        state: state ? state : '',
        postalCode: postalCode ? postalCode : '',
        countryId: country ? String(country) : '',
    };
    const phoneNumber = {
        phoneCountryCode: phoneCountryCode ? String(phoneCountryCode) : '',
        phoneAreaCode: phoneAreaCode ? phoneAreaCode : '',
        phoneNumber: userInfoData.phoneNumber,
    };
    const newSettings = {};
    (_a = withoutPhone.settings) === null || _a === void 0 ? void 0 : _a.forEach((setting) => {
        newSettings[setting.parameter] = setting.value;
    });
    const retypedSettings = newSettings;
    // Conversion from open-string field from API, to string-literal Zod type
    const retypedGroups = withoutPhone.groups;
    return Object.assign(Object.assign({}, withoutPhoneAddress), { groups: retypedGroups, settingsForm: retypedSettings, username: withoutPhoneAddress.email, birthday: withoutPhoneAddress.birthday
            ? (0, utc_to_local_tz_without_time_change_1.utcToLocalTzWithoutTimeChange)(withoutPhoneAddress.birthday)
            : undefined, address,
        phoneNumber });
};
exports.userinfoToZodMapper = userinfoToZodMapper;
