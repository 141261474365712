"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcNumPages = void 0;
const pagination_1 = require("@App/const/pagination");
const calcNumPages = (count, limit = 10, currentPage = 1, refined) => ({
    currentPage,
    lastPage: count !== 0 ? Math.ceil(count / limit) : 1,
    cappedLastPage: count !== 0 ? Math.min(Math.ceil(count / limit), Math.ceil(pagination_1.PAGINATION.paginationLimit / limit)) : 1,
    refined,
});
exports.calcNumPages = calcNumPages;
