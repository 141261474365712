"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processAnalytics = void 0;
const userpilot_1 = require("userpilot");
const analytics_utils_1 = require("@App/common/utils/analytics-utils");
const processAnalytics = (user) => {
    if (user) {
        (0, analytics_utils_1.analyticsSendIdentify)(user);
        const userId = user.id || '';
        const name = user.firstName + ' ' + user.lastName;
        userpilot_1.Userpilot.identify(userId, {
            name: name,
            email: user.email,
            created_at: user.invitationSentTimestamp,
        });
    }
    else {
        userpilot_1.Userpilot.anonymous();
    }
};
exports.processAnalytics = processAnalytics;
