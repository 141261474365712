"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SUGGESTION_STATUS_VALUES = exports.SUGGESTION_STATUS = void 0;
// Values required by the API
var SUGGESTION_STATUS;
(function (SUGGESTION_STATUS) {
    SUGGESTION_STATUS["SUBMITTED"] = "SUBMITTED";
    SUGGESTION_STATUS["IN_PROGRESS"] = "IN_PROGRESS";
    SUGGESTION_STATUS["ACCEPTED"] = "ACCEPTED";
    SUGGESTION_STATUS["PARTIALLY_ACCEPTED"] = "PARTIALLY_ACCEPTED";
    SUGGESTION_STATUS["REJECTED"] = "REJECTED";
    SUGGESTION_STATUS["SUPERSEDED"] = "SUPERSEDED";
})(SUGGESTION_STATUS = exports.SUGGESTION_STATUS || (exports.SUGGESTION_STATUS = {}));
// Values required by the status icons
exports.SUGGESTION_STATUS_VALUES = {
    SUBMITTED: 'submitted',
    IN_PROGRESS: 'in progress',
    ACCEPTED: 'accepted',
    PARTIALLY_ACCEPTED: 'partially accepted',
    REJECTED: 'rejected',
    SUPERSEDED: 'rematched',
};
