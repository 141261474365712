"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
const regexp_1 = require("@Const/regexp");
const forms_constants_1 = require("@Const/forms-constants");
const user_1 = require("@App/api/routes/user");
const create_password_status_1 = require("@App/enum/create-password-status");
const HttpError_1 = require("@App/exceptions/HttpError");
class CreatePasswordStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_phoneNumberValidation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_user", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_password", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_passwordError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_password2", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_passwordError2", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_codeError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_code", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_phoneCountryCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_phoneAreaCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_phoneNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_createPasswordStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_isEmailMFA", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_isConfigMFAValid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "getValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value, id) => {
                switch (id) {
                    case 'password':
                        this._passwordError = value.match(regexp_1.PASSWORD_REGEX)
                            ? ''
                            : !value.length
                                ? 'Required'
                                : 'Minimum 10 characters and at least one upper case, one lower case, ' +
                                    'one number and one special character';
                        this._passwordError2 = this._password2 && this._password2 !== value ? 'Passwords must match' : '';
                        this._password = value;
                        break;
                    case 'password2':
                        this._passwordError2 = !value.length
                            ? 'Required'
                            : value !== this._password
                                ? 'Passwords must match'
                                : '';
                        this._password2 = value;
                        break;
                    case 'code':
                        this._codeError = !value.length ? 'Required' : '';
                        this._code = value;
                        break;
                    case forms_constants_1.PHONE_NUMBER_FORM.phoneCountryCode.id:
                        this._phoneNumberValidation.phoneCountryCode.error = value.length <= 0 ? 'Country Code is required' : '';
                        this._phoneCountryCode = Number(value);
                        break;
                    case forms_constants_1.PHONE_NUMBER_FORM.phoneAreaCode.id:
                        this._phoneNumberValidation.phoneAreaCode.error = !value.length ? 'Area Code is required' : '';
                        this._phoneAreaCode = value;
                        break;
                    case forms_constants_1.PHONE_NUMBER_FORM.phoneNumber.id:
                        this._phoneNumberValidation.phoneNumber.error = !value.length ? 'Phone number is required' : '';
                        this._phoneNumber = value;
                        break;
                    default:
                        break;
                }
            }
        });
        (0, mobx_1.makeObservable)(this, {
            _user: mobx_1.observable,
            _password: mobx_1.observable,
            _passwordError: mobx_1.observable,
            _password2: mobx_1.observable,
            _passwordError2: mobx_1.observable,
            _codeError: mobx_1.observable,
            _code: mobx_1.observable,
            _phoneCountryCode: mobx_1.observable,
            _phoneAreaCode: mobx_1.observable,
            _phoneNumber: mobx_1.observable,
            _phoneNumberValidation: mobx_1.observable,
            _createPasswordStatus: mobx_1.observable,
            _isEmailMFA: mobx_1.observable,
            _isConfigMFAValid: mobx_1.observable,
            email: mobx_1.computed,
            isPasswordValid: mobx_1.computed,
            isConfigMFAValid: mobx_1.computed,
            formattedPhoneNumber: mobx_1.computed,
            activateUser: mobx_1.action,
            createPassword: mobx_1.action,
            getValue: mobx_1.action,
            submitConfigurationMFA: mobx_1.action,
            submitMfaCode: mobx_1.action,
            setIsConfigMFAValid: mobx_1.action,
            validatorConfigMFA: mobx_1.action,
        });
        this.rootStore = rootStore;
        this._createPasswordStatus = create_password_status_1.CreatePasswordStatus.setting_password;
        this._isEmailMFA = false;
        this._isConfigMFAValid = false;
        this._phoneNumberValidation = {
            phoneCountryCode: { error: '' },
            phoneAreaCode: { error: '' },
            phoneNumber: { error: '' },
        };
    }
    get password() {
        return this._password;
    }
    get phoneCountryCode() {
        return this._phoneCountryCode;
    }
    get phoneAreaCode() {
        return this._phoneAreaCode;
    }
    get phoneNumber() {
        return this._phoneNumber;
    }
    get passwordError() {
        return this._passwordError;
    }
    get passwordError2() {
        return this._passwordError2;
    }
    get isEmailMFA() {
        return this._isEmailMFA;
    }
    get code() {
        return this._code;
    }
    get codeError() {
        return this._codeError;
    }
    get email() {
        var _a;
        return (_a = this._user) === null || _a === void 0 ? void 0 : _a.email;
    }
    get formattedPhoneNumber() {
        return `+${this.phoneCountryCode} (${this.phoneAreaCode}) ${this.phoneNumber}`;
    }
    get createPasswordStatus() {
        return this._createPasswordStatus;
    }
    get phoneNumberValidation() {
        return this._phoneNumberValidation;
    }
    get isPasswordValid() {
        return !!(this._password &&
            this._password2 &&
            !this._passwordError &&
            !this._passwordError2 &&
            this._password === this._password2);
    }
    get isConfigMFAValid() {
        return this._isConfigMFAValid;
    }
    setIsConfigMFAValid(isConfigMFAValid) {
        this._isConfigMFAValid = isConfigMFAValid;
    }
    validatorConfigMFA() {
        const isPhoneNumberValid = !(this.phoneNumberValidation.phoneNumber.error ||
            this.phoneNumberValidation.phoneAreaCode.error ||
            this.phoneNumberValidation.phoneCountryCode.error);
        this._isConfigMFAValid = !!(this.phoneNumber && isPhoneNumberValid);
    }
    setIsMFAType(isMFAType) {
        this._isEmailMFA = isMFAType;
    }
    createPassword(activatingCode) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield (0, user_1.createUserPassword)(activatingCode, this._password, this._phoneCountryCode, this._phoneAreaCode, this._phoneNumber);
                this._createPasswordStatus = create_password_status_1.CreatePasswordStatus.configuration_mfa;
            }
            catch (error) {
                if (error instanceof HttpError_1.HttpError) {
                    this.rootStore.popupStore.setAlertData(error.message, true);
                }
            }
        });
    }
    activateUser(activatingCode) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield (0, user_1.activateUser)(activatingCode);
            const data = response.data;
            if (data.error) {
                throw new Error(data.message);
            }
            if (data.email) {
                (0, mobx_1.runInAction)(() => (this._user = data));
                this.getValue(this._user.phoneCountryCode ? this._user.phoneCountryCode.toString(10) : '0', forms_constants_1.PHONE_NUMBER_FORM.phoneCountryCode.id);
                this.getValue(this._user.phoneAreaCode ? this._user.phoneAreaCode : '', forms_constants_1.PHONE_NUMBER_FORM.phoneAreaCode.id);
                this.getValue(this._user.phoneNumber ? this._user.phoneNumber : '', forms_constants_1.PHONE_NUMBER_FORM.phoneNumber.id);
            }
        });
    }
    submitConfigurationMFA() {
        return __awaiter(this, void 0, void 0, function* () {
            const reply = yield this.rootStore.userStore.enrollUser2fa(!this._isEmailMFA, this.email, this._password, this._phoneCountryCode, this._phoneAreaCode, this._phoneNumber);
            if (this._isEmailMFA) {
                this._createPasswordStatus = create_password_status_1.CreatePasswordStatus.config_done;
            }
            else {
                if (reply === 'no_update') {
                    this._createPasswordStatus = create_password_status_1.CreatePasswordStatus.config_done;
                }
                else {
                    this._createPasswordStatus = create_password_status_1.CreatePasswordStatus.confirm_sms_code;
                }
            }
        });
    }
    submitMfaCode() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.rootStore.userStore.submitPhoneEnrollmentMfaCode(this._code);
            this._createPasswordStatus = create_password_status_1.CreatePasswordStatus.config_done;
        });
    }
}
exports.default = CreatePasswordStore;
