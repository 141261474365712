"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteCookie = exports.getCookie = exports.setCookie = void 0;
const setCookie = (name, value, expiresInDays = 7) => {
    const date = new Date();
    date.setTime(date.getTime() + expiresInDays * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
};
exports.setCookie = setCookie;
const getCookie = (name) => {
    const allCookies = document.cookie.split(';');
    for (const cookie of allCookies) {
        const split = cookie.split('=');
        if (split[0].trim() === name) {
            return 'Y';
        }
    }
    return null;
};
exports.getCookie = getCookie;
const deleteCookie = (name) => {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
exports.deleteCookie = deleteCookie;
