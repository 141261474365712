"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QUESTIONNAIRE_ROUTE = exports.FORM_SEGMENT = exports.REGISTRATION_ROUTE = exports.SEARCH_PARAM = exports.EDIT_DRAFT_ROUTE = exports.EDIT_ROUTE = exports.EDIT_WORK_ROUTE = exports.RIGHTSHOLDER_SUB_ROUTES = exports.BULK_ROUTES = exports.FORM_ROUTE = exports.STEP_ROUTES = exports.WIZARD_ROUTE = exports.MANUAL_ROUTE = exports.CREATE_WORK_ROUTE = exports.RIGHTSHOLDER_ROUTE_WITH_ID = exports.CHANGE_PASSWORD_ROUTE = exports.PROFILE_ROUTE = exports.RECORDING_DETAILS_ROUTE_WITH_RECORDING_ID = exports.UI_ROUTE = exports.ACCESS_DENIED_ROUTE = exports.CONTACT_US_ROUTE = exports.PUBLIC_VIEW_WORK_ROUTE_WITH_WORK_ID = exports.SEARCH_ROUTE_WITH_TAB_NAME = exports.SEARCH_ROUTE = exports.CREATE_PASSWORD_ROUTE_WITH_ACT_CODE = exports.FORGOT_PASSWORD_ROUTE = exports.SIGN_UP_COMPLETE_ROUTE = exports.SIGN_UP_ROUTE = exports.INACTIVE_ROUTE = exports.UPCOMING_NOTES_ROUTE = exports.RELEASE_NOTES_ROUTE = exports.LOGIN_ROUTE = exports.PARAM = void 0;
const PARAM = Object.freeze({
    rightsholderId: ':rightsholderId',
    workId: ':workId',
    typeWork: ':typeWork',
    userId: ':userId',
    activatingCode: ':activatingCode',
    recordingId: ':recordingId',
    contactId: ':contactId',
    submissionId: ':submissionId',
    recordingGroupCategory: ':recordingGroupCategory',
    overclaimHeaderId: ':overclaimHeaderId',
    tabName: ':tabName',
});
exports.PARAM = PARAM;
const SEARCH_PARAM = Object.freeze({
    payee: 'payee',
    period: 'period',
    matchHistoryFilter: 'matchHistoryFilter',
});
exports.SEARCH_PARAM = SEARCH_PARAM;
// root routes
const RELEASE_NOTES_ROUTE = 'release-notes';
exports.RELEASE_NOTES_ROUTE = RELEASE_NOTES_ROUTE;
const UPCOMING_NOTES_ROUTE = 'upcoming-notes';
exports.UPCOMING_NOTES_ROUTE = UPCOMING_NOTES_ROUTE;
const OVERCLAIM_ROUTE = 'overclaim';
const OVERCLAIM_VIEW_ROUTE_WITH_OC_HEADER_ID = `${OVERCLAIM_ROUTE}/${PARAM.overclaimHeaderId}`;
const LOGIN_ROUTE = 'login';
exports.LOGIN_ROUTE = LOGIN_ROUTE;
const INACTIVE_ROUTE = 'handle-inactive';
exports.INACTIVE_ROUTE = INACTIVE_ROUTE;
const SIGN_UP_ROUTE = 'sign-up';
exports.SIGN_UP_ROUTE = SIGN_UP_ROUTE;
const SIGN_UP_COMPLETE_ROUTE = 'sign-up-complete';
exports.SIGN_UP_COMPLETE_ROUTE = SIGN_UP_COMPLETE_ROUTE;
const FORGOT_PASSWORD_ROUTE = 'forgot-password';
exports.FORGOT_PASSWORD_ROUTE = FORGOT_PASSWORD_ROUTE;
const CREATE_PASSWORD_ROUTE = 'create-password';
const CREATE_PASSWORD_ROUTE_WITH_ACT_CODE = `${CREATE_PASSWORD_ROUTE}/${PARAM.activatingCode}`;
exports.CREATE_PASSWORD_ROUTE_WITH_ACT_CODE = CREATE_PASSWORD_ROUTE_WITH_ACT_CODE;
const SEARCH_ROUTE = 'search';
exports.SEARCH_ROUTE = SEARCH_ROUTE;
const SEARCH_ROUTE_WITH_TAB_NAME = `${SEARCH_ROUTE}/${PARAM.tabName}`;
exports.SEARCH_ROUTE_WITH_TAB_NAME = SEARCH_ROUTE_WITH_TAB_NAME;
const VIEW_WORK_ROUTE = 'catalog/work';
const VIEW_SUBMISSION_ROUTE = 'registration-history/submission';
const VIEW_TRANSACTION_ROUTE = 'registration-history/transaction';
const PUBLIC_VIEW_WORK_ROUTE_WITH_WORK_ID = `catalog/work/${PARAM.workId}`;
exports.PUBLIC_VIEW_WORK_ROUTE_WITH_WORK_ID = PUBLIC_VIEW_WORK_ROUTE_WITH_WORK_ID;
const CONTACT_US_ROUTE = 'contact-us';
exports.CONTACT_US_ROUTE = CONTACT_US_ROUTE;
const ACCESS_DENIED_ROUTE = 'access-denied';
exports.ACCESS_DENIED_ROUTE = ACCESS_DENIED_ROUTE;
const UI_ROUTE = 'ui';
exports.UI_ROUTE = UI_ROUTE;
const RECORDING_DETAILS_ROUTE = 'recording-details';
const RECORDING_DETAILS_ROUTE_WITH_RECORDING_ID = `${RECORDING_DETAILS_ROUTE}/${PARAM.recordingId}`;
exports.RECORDING_DETAILS_ROUTE_WITH_RECORDING_ID = RECORDING_DETAILS_ROUTE_WITH_RECORDING_ID;
const PROFILE_ROUTE = 'profile';
exports.PROFILE_ROUTE = PROFILE_ROUTE;
const CHANGE_PASSWORD_ROUTE = `${PROFILE_ROUTE}/change-password`;
exports.CHANGE_PASSWORD_ROUTE = CHANGE_PASSWORD_ROUTE;
// register rightsholder routes
const REGISTRATION_ROUTE = 'registration-member';
exports.REGISTRATION_ROUTE = REGISTRATION_ROUTE;
const FORM_SEGMENT = 'form';
exports.FORM_SEGMENT = FORM_SEGMENT;
const QUESTIONNAIRE_ROUTE = 'questionnaire';
exports.QUESTIONNAIRE_ROUTE = QUESTIONNAIRE_ROUTE;
// rightsholder routes
const RIGHTSHOLDER_ROUTE = 'member';
const RIGHTSHOLDER_ROUTE_WITH_ID = `${RIGHTSHOLDER_ROUTE}/${PARAM.rightsholderId}`;
exports.RIGHTSHOLDER_ROUTE_WITH_ID = RIGHTSHOLDER_ROUTE_WITH_ID;
const CATALOG_ROUTE = 'catalog';
const HISTORY_ROUTE = 'registration-history';
const DRAFTS_ROUTE = 'registration-drafts';
const MATCH_HISTORY_ROUTE = 'match-history';
const VIEW_ROUTE = 'view';
const VIEW_DRAFT_ROUTE = 'registration-drafts/draft';
const RECORDING_ROUTE = 'recording';
const RH_MAINTENANCE_ROUTE = 'maintenance';
const RH_CONTACT_ROUTE = `${RH_MAINTENANCE_ROUTE}/contact`;
const RH_USER_PERMISSION_ROUTE = `${RH_MAINTENANCE_ROUTE}/user-permissions`;
const RIGHTSHOLDER_SUB_ROUTES = Object.freeze({
    summary: 'summary',
    dashboard: 'dashboard',
    catalog: {
        viewWork: `${VIEW_WORK_ROUTE}/${PARAM.workId}/${VIEW_ROUTE}`,
        root: CATALOG_ROUTE,
    },
    registrationHistory: {
        root: `${HISTORY_ROUTE}`,
        submission: `${VIEW_SUBMISSION_ROUTE}/${PARAM.workId}/${VIEW_ROUTE}`,
        transaction: `${VIEW_TRANSACTION_ROUTE}/${PARAM.workId}/${VIEW_ROUTE}`,
    },
    drafts: {
        root: `${DRAFTS_ROUTE}`,
        viewDraft: `${VIEW_DRAFT_ROUTE}/${PARAM.workId}/${VIEW_ROUTE}`,
    },
    claimingTool: 'claiming-tool',
    matchingTool: 'matching-tool',
    matchHistory: {
        recording: `${MATCH_HISTORY_ROUTE}/${PARAM.submissionId}/${RECORDING_ROUTE}/${PARAM.recordingGroupCategory}`,
        submission: `${MATCH_HISTORY_ROUTE}/${PARAM.submissionId}`,
        root: MATCH_HISTORY_ROUTE,
    },
    overclaim: {
        root: OVERCLAIM_ROUTE,
        view: OVERCLAIM_VIEW_ROUTE_WITH_OC_HEADER_ID,
    },
    royalties: {
        statements: 'royalties/statements',
        annualStatements: 'royalties/annual-statements',
        rateSheets: 'royalties/rate-sheets',
        root: 'royalties',
    },
    maintenance: {
        rightsholderContact: `${RH_CONTACT_ROUTE}/${PARAM.contactId}`,
        rightsholderContacts: `${RH_MAINTENANCE_ROUTE}/contacts`,
        userPermission: `${RH_USER_PERMISSION_ROUTE}/${PARAM.userId}`,
        userPermissions: RH_USER_PERMISSION_ROUTE,
        root: RH_MAINTENANCE_ROUTE,
    },
});
exports.RIGHTSHOLDER_SUB_ROUTES = RIGHTSHOLDER_SUB_ROUTES;
// work routes
const CREATE_WORK_ROUTE = 'create-work';
exports.CREATE_WORK_ROUTE = CREATE_WORK_ROUTE;
const MANUAL_ROUTE = 'manual';
exports.MANUAL_ROUTE = MANUAL_ROUTE;
const WIZARD_ROUTE = `${MANUAL_ROUTE}/wizard`;
exports.WIZARD_ROUTE = WIZARD_ROUTE;
const FORM_ROUTE = `${MANUAL_ROUTE}/form`;
exports.FORM_ROUTE = FORM_ROUTE;
const BULK_ROUTES = Object.freeze({
    root: 'bulk',
    fileUpload: 'file-upload',
    fileReview: 'file-review',
    submission: 'submission',
});
exports.BULK_ROUTES = BULK_ROUTES;
const EDIT_ROUTE = 'edit';
exports.EDIT_ROUTE = EDIT_ROUTE;
const EDIT_WORK_ROUTE = `${CATALOG_ROUTE}/${PARAM.typeWork}/${PARAM.workId}/${EDIT_ROUTE}`;
exports.EDIT_WORK_ROUTE = EDIT_WORK_ROUTE;
const EDIT_DRAFT_ROUTE = `${DRAFTS_ROUTE}/${PARAM.typeWork}/${PARAM.workId}/${EDIT_ROUTE}`;
exports.EDIT_DRAFT_ROUTE = EDIT_DRAFT_ROUTE;
// work step routes
const STEP_ROUTES = Object.freeze({
    workDetails: 'work-details',
    writers: 'writers',
    writersShares: 'writers-shares-role',
    publishers: 'publishers',
    publishersShares: 'publishers-shares',
    publishersWriters: 'publishers-writers',
    recordings: 'recordings',
    review: 'review',
    submission: 'submission',
});
exports.STEP_ROUTES = STEP_ROUTES;
const createRootRightsholderRoute = (id) => `/${RIGHTSHOLDER_ROUTE}/${id}`;
const createRootWizardRoute = (id) => `${createRootRightsholderRoute(id)}/${CREATE_WORK_ROUTE}/${WIZARD_ROUTE}`;
const createRootFormRoute = (id) => `${createRootRightsholderRoute(id)}/${CREATE_WORK_ROUTE}/${FORM_ROUTE}`;
const createRootBulkRoute = (id) => `${createRootRightsholderRoute(id)}/${CREATE_WORK_ROUTE}/${BULK_ROUTES.root}`;
const createRootBulkEditRoute = (id) => `${createRootBulkRoute(id)}/${EDIT_ROUTE}`;
const createRootEditRoute = (id, type, workId) => `${createRootRightsholderRoute(id)}/${CATALOG_ROUTE}/${type}/${workId}/${EDIT_ROUTE}`;
const createRootEditDraftRoute = (id, type, workId) => `${createRootRightsholderRoute(id)}/${DRAFTS_ROUTE}/${type}/${workId}/${EDIT_ROUTE}`;
const createRootOverclaimRoute = (id) => `${createRootRightsholderRoute(id)}/${OVERCLAIM_ROUTE}`;
const createOverclaimViewRoute = (id, overclaimHeaderId) => `${createRootOverclaimRoute(id)}/${overclaimHeaderId}`;
const Navigation = {
    home: {
        root: () => '/',
    },
    login: {
        root: () => `/${LOGIN_ROUTE}`,
        inactive: () => `/${LOGIN_ROUTE}?inactive`,
        handleInactive: () => `/${INACTIVE_ROUTE}`,
    },
    signUp: {
        root: () => `/${SIGN_UP_ROUTE}`,
    },
    signUpComplete: {
        root: () => `/${SIGN_UP_COMPLETE_ROUTE}`,
    },
    forgotPassword: {
        root: () => `/${FORGOT_PASSWORD_ROUTE}`,
    },
    createPassword: {
        root: (activatingCode) => `/${CREATE_PASSWORD_ROUTE}/${activatingCode}`,
    },
    search: {
        root: () => `/${SEARCH_ROUTE}`,
    },
    viewWork: {
        root: (workId) => `/${VIEW_WORK_ROUTE}/${workId}`,
    },
    contactUs: {
        root: () => `/${CONTACT_US_ROUTE}`,
    },
    accessDenied: {
        root: () => `/${ACCESS_DENIED_ROUTE}`,
    },
    ui: {
        root: () => `/${UI_ROUTE}`,
    },
    recordingDetails: {
        root: (recordingId) => `/${RECORDING_DETAILS_ROUTE}/${recordingId}`,
    },
    profile: {
        root: () => `/${PROFILE_ROUTE}`,
        changePassword: () => `/${PROFILE_ROUTE}/change-password`,
        rightsHolders: () => `/${PROFILE_ROUTE}#members'`,
        user: () => `/${PROFILE_ROUTE}#user`,
    },
    registrationRightsholder: {
        root: () => `/${REGISTRATION_ROUTE}`,
        form: () => `/${REGISTRATION_ROUTE}/${FORM_SEGMENT}`,
        questionnaire: () => `/${REGISTRATION_ROUTE}/${QUESTIONNAIRE_ROUTE}`,
    },
    rightholder: {
        root: (id) => createRootRightsholderRoute(id),
        summary: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.summary}`,
        createWork: {
            root: (id) => `/${RIGHTSHOLDER_ROUTE}/${id}/${CREATE_WORK_ROUTE}/${MANUAL_ROUTE}`,
            wizard: {
                root: (id) => createRootWizardRoute(id),
                workDetails: (id) => `${createRootWizardRoute(id)}/${STEP_ROUTES.workDetails}`,
                writers: (id) => `${createRootWizardRoute(id)}/${STEP_ROUTES.writers}`,
                writersSharesRole: (id) => `${createRootWizardRoute(id)}/${STEP_ROUTES.writersShares}`,
                publishers: (id) => `${createRootWizardRoute(id)}/${STEP_ROUTES.publishers}`,
                publishersShares: (id) => `${createRootWizardRoute(id)}/${STEP_ROUTES.publishersShares}`,
                publishersWriters: (id) => `${createRootWizardRoute(id)}/${STEP_ROUTES.publishersWriters}`,
                review: (id) => `${createRootWizardRoute(id)}/${STEP_ROUTES.review}`,
                recordings: (id) => `${createRootWizardRoute(id)}/${STEP_ROUTES.recordings}`,
                submission: (id) => `${createRootWizardRoute(id)}/${STEP_ROUTES.submission}`,
            },
            form: {
                root: (id) => createRootFormRoute(id),
                workDetails: (id) => `${createRootFormRoute(id)}/${STEP_ROUTES.workDetails}`,
                writers: (id) => `${createRootFormRoute(id)}/${STEP_ROUTES.writers}`,
                writersSharesRole: (id) => `${createRootFormRoute(id)}/${STEP_ROUTES.writersShares}`,
                publishers: (id) => `${createRootFormRoute(id)}/${STEP_ROUTES.publishers}`,
                publishersShares: (id) => `${createRootFormRoute(id)}/${STEP_ROUTES.publishersShares}`,
                publishersWriters: (id) => `${createRootFormRoute(id)}/${STEP_ROUTES.publishersWriters}`,
                recordings: (id) => `${createRootFormRoute(id)}/${STEP_ROUTES.recordings}`,
                submission: (id) => `${createRootFormRoute(id)}/${STEP_ROUTES.submission}`,
            },
            bulk: {
                root: (id) => createRootBulkRoute(id),
                fileUpload: (id) => `${createRootBulkRoute(id)}/${BULK_ROUTES.fileUpload}`,
                fileReview: (id) => `${createRootBulkRoute(id)}/${BULK_ROUTES.fileReview}`,
                submission: (id) => `${createRootBulkRoute(id)}/${BULK_ROUTES.submission}`,
                edit: {
                    root: (id) => createRootBulkEditRoute(id),
                    workDetails: (id) => `${createRootBulkEditRoute(id)}/${STEP_ROUTES.workDetails}`,
                    writers: (id) => `${createRootBulkEditRoute(id)}/${STEP_ROUTES.writers}`,
                    writersSharesRole: (id) => `${createRootBulkEditRoute(id)}/${STEP_ROUTES.writersShares}`,
                    publishers: (id) => `${createRootBulkEditRoute(id)}/${STEP_ROUTES.publishers}`,
                    publishersShares: (id) => `${createRootBulkEditRoute(id)}/${STEP_ROUTES.publishersShares}`,
                    publishersWriters: (id) => `${createRootBulkEditRoute(id)}/${STEP_ROUTES.publishersWriters}`,
                    recordings: (id) => `${createRootBulkEditRoute(id)}/${STEP_ROUTES.recordings}`,
                    submission: (id) => `${createRootBulkEditRoute(id)}/${STEP_ROUTES.submission}`,
                },
            },
        },
        catalog: {
            root: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.catalog.root}`,
            work: (id, workId) => `${createRootRightsholderRoute(id)}/${VIEW_WORK_ROUTE}/${workId}/${VIEW_ROUTE}`,
            workEdit: {
                root: (id, type, workId) => createRootEditRoute(id, type, workId),
                workDetails: (id, type, workId) => `${createRootEditRoute(id, type, workId)}/${STEP_ROUTES.workDetails}`,
                writers: (id, type, workId) => `${createRootEditRoute(id, type, workId)}/${STEP_ROUTES.writers}`,
                writersSharesRole: (id, type, workId) => `${createRootEditRoute(id, type, workId)}/${STEP_ROUTES.writersShares}`,
                publishers: (id, type, workId) => `${createRootEditRoute(id, type, workId)}/${STEP_ROUTES.publishers}`,
                publishersShares: (id, type, workId) => `${createRootEditRoute(id, type, workId)}/${STEP_ROUTES.publishersShares}`,
                recordings: (id, type, workId) => `${createRootEditRoute(id, type, workId)}/${STEP_ROUTES.recordings}`,
                submission: (id, type, workId) => `${createRootEditRoute(id, type, workId)}/${STEP_ROUTES.submission}`,
            },
        },
        overclaim: {
            root: (id) => `${createRootOverclaimRoute(id)}`,
            view: (id, overclaimHeaderId) => `${createOverclaimViewRoute(id, overclaimHeaderId)}`,
        },
        registrationHistory: {
            root: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.registrationHistory.root}`,
            pending: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.registrationHistory.root}#pending`,
            processed: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.registrationHistory.root}#processed`,
            submission: (id, workId) => `${createRootRightsholderRoute(id)}/${VIEW_SUBMISSION_ROUTE}/${workId}/${VIEW_ROUTE}`,
            transaction: (id, workId) => `${createRootRightsholderRoute(id)}/${VIEW_TRANSACTION_ROUTE}/${workId}/${VIEW_ROUTE}`,
        },
        registrationDrafts: {
            root: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.drafts.root}`,
            draft: (id, workId) => `${createRootRightsholderRoute(id)}/${VIEW_DRAFT_ROUTE}/${workId}/${VIEW_ROUTE}`,
            editRoot: (id, type, workId) => `${createRootEditDraftRoute(id, type, workId)}`,
            workDetails: (id, type, workId) => `${createRootEditDraftRoute(id, type, workId)}/${STEP_ROUTES.workDetails}`,
            writers: (id, type, workId) => `${createRootEditDraftRoute(id, type, workId)}/${STEP_ROUTES.writers}`,
            writersSharesRole: (id, type, workId) => `${createRootEditDraftRoute(id, type, workId)}/${STEP_ROUTES.writersShares}`,
            publishers: (id, type, workId) => `${createRootEditDraftRoute(id, type, workId)}/${STEP_ROUTES.publishers}`,
            publishersShares: (id, type, workId) => `${createRootEditDraftRoute(id, type, workId)}/${STEP_ROUTES.publishersShares}`,
            recordings: (id, type, workId) => `${createRootEditDraftRoute(id, type, workId)}/${STEP_ROUTES.recordings}`,
            submission: (id, type, workId) => `${createRootEditDraftRoute(id, type, workId)}/${STEP_ROUTES.submission}`,
        },
        matchClaim: {
            match: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.matchingTool}`,
            claim: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.claimingTool}`,
            matchHistory: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.matchHistory.root}`,
            matchUsage: (id, submissionId) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.matchHistory.root}/${submissionId}`,
            recordingGroups: (id, submissionId, recordingGroupCategory) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.matchHistory.root}/${submissionId}/${RECORDING_ROUTE}/${recordingGroupCategory}`,
        },
        royalties: {
            root: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.royalties.root}`,
            statements: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.royalties.statements}`,
            statement: (id, payee, period) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.royalties.statements}?payee=${payee}&period=${period}`,
            rateSheets: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.royalties.rateSheets}`,
            annual: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.royalties.annualStatements}`,
        },
        maintenance: {
            root: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.maintenance.root}`,
            mailingAndContact: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.maintenance.root}#mailing-and-contact`,
            userPermissions: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.maintenance.userPermissions}`,
            userPermissionsProfile: (id, userId) => `${createRootRightsholderRoute(id)}/${RH_USER_PERMISSION_ROUTE}/${userId}`,
            memberContacts: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.maintenance.rightsholderContacts}`,
            contactInformation: (id, contactId) => `${createRootRightsholderRoute(id)}/${RH_CONTACT_ROUTE}/${contactId}`,
            bankingAndTax: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.maintenance.root}#banking-and-tax`,
            endorsement: (id) => `${createRootRightsholderRoute(id)}/${RIGHTSHOLDER_SUB_ROUTES.maintenance.root}#member-endorsement`,
        },
    },
};
exports.default = Object.freeze(Navigation);
