"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const enumFromValue = (val, _enum) => {
    const enumName = Object.keys(_enum).find((k) => _enum[k] === val);
    if (!enumName) {
        throw Error('Invalid Enum Value');
    }
    return _enum[enumName];
};
exports.default = enumFromValue;
