"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HELP_FORM = exports.USER_FORM = exports.RIGHTSHOLDER_CONTACT_VIEW_FORM = exports.RIGHTSHOLDER_FORM = exports.PHONE_NUMBER_FORM = void 0;
exports.PHONE_NUMBER_FORM = {
    phoneCountryCode: {
        id: 'phoneCountryCode',
        label: 'Country Code',
    },
    phoneAreaCode: {
        id: 'phoneAreaCode',
        label: 'Area Code',
    },
    phoneNumber: {
        id: 'phoneNumber',
        label: 'Phone Number',
    },
};
exports.RIGHTSHOLDER_FORM = Object.assign({ name: {
        id: 'name',
        label: 'Member Name',
    }, streetAddress: {
        id: 'streetAddress',
        label: 'Street Address',
    }, unitNumber: {
        id: 'unitNumber',
        label: 'Unit Number',
    }, attention: {
        id: 'attention',
        label: 'Attention',
    }, address2: {
        id: 'address2',
        label: 'Address 2',
    }, address3: {
        id: 'address3',
        label: 'Address 3',
    }, address4: {
        id: 'address4',
        label: 'Address 4',
    }, city: {
        id: 'city',
        label: 'City / Town',
    }, state: {
        id: 'state',
        label: 'State / Province',
    }, country: {
        id: 'country',
        label: 'Country',
    }, postalCode: {
        id: 'postalCode',
        label: 'ZIP / Postal Code',
    }, email: {
        id: 'email',
        label: 'Email Address',
    }, registrationNumber: {
        id: 'registrationNumber',
        label: 'Registration Number',
    }, birthdate: {
        id: 'birthdate',
        label: 'Date of Birth',
    }, ipiNumber: {
        id: 'ipiNumber',
        label: 'Publisher IPI Number',
    }, hfaPublisherNumber: {
        id: 'hfaPublisherNumber',
        label: 'Publisher Account Number',
    }, isni: {
        id: 'isni',
        label: 'ISNI',
    } }, exports.PHONE_NUMBER_FORM);
exports.RIGHTSHOLDER_CONTACT_VIEW_FORM = {
    contactName: {
        id: 'contactName',
        label: 'Contact Name',
    },
    contactType: {
        id: 'contactType',
        label: 'Contact Type',
    },
    contactAddress: {
        id: 'contactAddress',
        label: 'Contact Address',
    },
    contactEmail: {
        id: 'contactEmail',
        label: 'Contact Email Address',
    },
    contactPhone: {
        id: 'contactPhone',
        label: 'Contact Phone Number',
    },
};
exports.USER_FORM = Object.assign({ firstName: {
        id: 'firstName',
        label: 'First Name',
    }, lastName: {
        id: 'lastName',
        label: 'Last Name',
    }, birthday: {
        id: 'birthday',
        label: 'Date Of Birth',
    }, city: {
        id: 'city',
        label: 'City / Town',
    }, country: {
        id: 'country',
        label: 'Country',
    }, state: {
        id: 'state',
        label: 'State / Province',
    }, email: {
        id: 'email',
        label: 'Email Address',
    }, streetAddress: {
        id: 'streetAddress',
        label: 'Street Address',
    }, unitNumber: {
        id: 'unitNumber',
        label: 'Unit Number',
    }, postalCode: {
        id: 'postalCode',
        label: 'ZIP / Postal Code',
    } }, exports.PHONE_NUMBER_FORM);
exports.HELP_FORM = {
    firstName: {
        id: 'firstName',
        label: 'First Name',
    },
    lastName: {
        id: 'lastName',
        label: 'Last Name',
    },
    email: {
        id: 'email',
        label: 'Email Address',
    },
    relatedTopRightsholder: {
        id: 'relatedTopRightsholder',
        label: 'Related Member',
    },
    topic: {
        id: 'topic',
        label: 'Topic',
    },
    subTopic: {
        id: 'subTopic',
        label: 'Sub Topic',
    },
    description: {
        id: 'description',
        label: 'Description',
    },
};
