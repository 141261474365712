"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublisherSearchStore = void 0;
const mobx_1 = require("mobx");
const check_is_empty_object_1 = require("@App/helpers/check-is-empty-object");
const search_1 = require("@App/api/routes/search");
const calc_num_pages_1 = require("@App/helpers/pagination/calc-num-pages");
class PublisherSearchStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_publisherSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                publisherName: '',
                publisherIpi: '',
            }
        });
        Object.defineProperty(this, "_isSearching", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "_publisherSearchModel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                count: 0,
                results: [],
            }
        });
        Object.defineProperty(this, "_publisherSearchPaginationInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_publisherSearchPagination", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                page: 1,
                limit: 10,
            }
        });
        Object.defineProperty(this, "clearStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this._publisherSearchModel = {
                    count: 0,
                    results: [],
                };
                this._publisherSearch = {
                    publisherIpi: '',
                    publisherName: '',
                };
                this._publisherSearchPagination = {
                    page: 1,
                    limit: 5,
                };
                this._isSearching = false;
            }
        });
        (0, mobx_1.makeObservable)(this, {
            _publisherSearch: mobx_1.observable,
            _isSearching: mobx_1.observable,
            _publisherSearchModel: mobx_1.observable,
            _publisherSearchPaginationInfo: mobx_1.observable,
            setPublisherSearch: mobx_1.action,
            searchPublishers: mobx_1.action,
            clearStore: mobx_1.action,
        });
        this.rootStore = rootStore;
    }
    get publisherSearch() {
        return this._publisherSearch;
    }
    get isSearching() {
        return this._isSearching;
    }
    get publisherSearchModel() {
        return this._publisherSearchModel;
    }
    setPublisherSearch(publisherSearch) {
        this._publisherSearch = publisherSearch;
    }
    searchPublishers(forRH = false) {
        return __awaiter(this, void 0, void 0, function* () {
            this._isSearching = true;
            yield this.routePublisherSearch(forRH);
        });
    }
    searchExists() {
        return !!(this._publisherSearch && !(0, check_is_empty_object_1.isEmptyObject)(this._publisherSearch));
    }
    routePublisherSearch(searchForRH = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.searchExists()) {
                const results = yield (0, search_1.searchPublishersForRH)(this.rootStore.rightsholderStore.currentRightsholderId, this._publisherSearchPagination, this._publisherSearch);
                const publishers = results.data;
                (0, mobx_1.runInAction)(() => {
                    this._publisherSearchModel = publishers;
                    if (this._publisherSearchPagination.limit && this._publisherSearchPagination.page) {
                        this._publisherSearchPaginationInfo = (0, calc_num_pages_1.calcNumPages)(publishers.count, this._publisherSearchPagination.limit, this._publisherSearchPagination.page);
                    }
                    this._isSearching = false;
                });
            }
            else {
                (0, mobx_1.runInAction)(() => {
                    this._publisherSearchModel = {
                        count: 0,
                        results: [],
                    };
                    this._isSearching = false;
                });
            }
        });
    }
}
exports.PublisherSearchStore = PublisherSearchStore;
