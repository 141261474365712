"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupsFormKey = exports.groupsSchema = void 0;
const zod_1 = require("zod");
const groups_1 = require("@Const/groups");
const nameKey = 'name';
const groupsFormKey = 'groups';
exports.groupsFormKey = groupsFormKey;
const groupsSchema = zod_1.z.array(zod_1.z.object({
    [nameKey]: zod_1.z.enum(groups_1.GROUPS),
}));
exports.groupsSchema = groupsSchema;
