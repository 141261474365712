"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.USER_VERIFICATION_STATUSES = void 0;
exports.USER_VERIFICATION_STATUSES = {
    NOT_VERIFIED: 'Not Verified',
    PENDING: 'Pending',
    VERIFIED: 'Verified',
    REJECTED: 'Rejected',
    INACTIVE: 'Inactive',
    LIMIT_ATTEMPTS_BLOCK: 'Limit Attempts Block',
    LIMIT_ATTEMPTS_UNBLOCK: 'Limit Attempts Unblock',
    RIGHTSHOLDER_ACCESS: 'Rightsholder access',
    INVITE_SENT: 'Invite sent',
    INVITE_PENDING: 'Invite pending',
    EXPIRED_INVITE: 'Expired invite',
};
