"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequiredCriteria = void 0;
var RequiredCriteria;
(function (RequiredCriteria) {
    RequiredCriteria[RequiredCriteria["all"] = 0] = "all";
    RequiredCriteria[RequiredCriteria["some"] = 1] = "some";
    RequiredCriteria[RequiredCriteria["first"] = 2] = "first";
    RequiredCriteria[RequiredCriteria["primaryFilter"] = 3] = "primaryFilter";
    RequiredCriteria[RequiredCriteria["none"] = 4] = "none";
})(RequiredCriteria = exports.RequiredCriteria || (exports.RequiredCriteria = {}));
