"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RightsholderUserStore = void 0;
const mobx_1 = require("mobx");
const rightsholderUser_1 = require("@App/api/routes/rightsholder/rightsholderUser");
const calc_num_pages_1 = require("@App/helpers/pagination/calc-num-pages");
class RightsholderUserStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_rightsHolderUsersForUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_rightsHolderUserToAbility", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_rightsHolderUsers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_rightsHolderUsersPagination", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_rightsHolderUserInviteInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "loadRightsholderUsers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pagination, search) => __awaiter(this, void 0, void 0, function* () {
                const rhId = this.rootStore.rightsholderStore.currentRightsholderId;
                const users = search
                    ? yield (0, rightsholderUser_1.searchRightsholderUsers)(rhId, pagination, search)
                    : yield (0, rightsholderUser_1.getRightsholderUsers)(rhId, pagination);
                (0, mobx_1.runInAction)(() => {
                    this._rightsHolderUsers = users.data;
                    if (pagination.limit && pagination.page && users.data) {
                        this._rightsHolderUsersPagination = (0, calc_num_pages_1.calcNumPages)(users.data.count, pagination.limit, pagination.page);
                    }
                });
            })
        });
        Object.defineProperty(this, "loadRightsholderUsersForUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (userId, body) => __awaiter(this, void 0, void 0, function* () {
                const response = yield (0, rightsholderUser_1.getRightsholderUsers)(this.rootStore.rightsholderStore.currentRightsholderId, body);
                const usersForUser = {
                    count: response.data.count,
                    users: response.data.users.filter((item) => item.id !== userId).slice(0, body.limit),
                };
                (0, mobx_1.runInAction)(() => (this._rightsHolderUsersForUser = usersForUser));
            })
        });
        Object.defineProperty(this, "loadRightsholderUserToAbility", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (userId) => __awaiter(this, void 0, void 0, function* () {
                const response = yield (0, rightsholderUser_1.getRightsholderUserToAbility)(this.rootStore.rightsholderStore.currentRightsholderId, userId);
                (0, mobx_1.runInAction)(() => (this._rightsHolderUserToAbility = response.data));
            })
        });
        Object.defineProperty(this, "inviteRightsholderUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data) => __awaiter(this, void 0, void 0, function* () {
                const response = yield (0, rightsholderUser_1.inviteRightsholderUser)(this.rootStore.rightsholderStore.currentRightsholderId, data);
                (0, mobx_1.runInAction)(() => (this._rightsHolderUserInviteInfo = response.data));
            })
        });
        Object.defineProperty(this, "destroyRightsHolderUserToAbility", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this._rightsHolderUserToAbility = null;
            }
        });
        Object.defineProperty(this, "destroyRightsHolderUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this._rightsHolderUsersForUser = null;
            }
        });
        Object.defineProperty(this, "destroyRightsHolderUsers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this._rightsHolderUsers = null;
                this._rightsHolderUsersPagination = null;
            }
        });
        (0, mobx_1.makeObservable)(this, {
            _rightsHolderUsersForUser: mobx_1.observable,
            _rightsHolderUserToAbility: mobx_1.observable,
            _rightsHolderUsers: mobx_1.observable,
            _rightsHolderUserInviteInfo: mobx_1.observable,
            permissions: mobx_1.computed,
            destroyRightsHolderInviteInfo: mobx_1.action,
            destroyRightsHolderUserToAbility: mobx_1.action,
            destroyRightsHolderUser: mobx_1.action,
            destroyRightsHolderUsers: mobx_1.action,
        });
        this.rootStore = rootStore;
    }
    get rightsholderUsersForUser() {
        return this._rightsHolderUsersForUser;
    }
    get rightsHolderUserToAbility() {
        return this._rightsHolderUserToAbility;
    }
    get rightsholderUsers() {
        return this._rightsHolderUsers;
    }
    get rightsHolderUsersPagination() {
        return this._rightsHolderUsersPagination;
    }
    get rightsholderUserInviteInfo() {
        return this._rightsHolderUserInviteInfo;
    }
    get permissions() {
        var _a;
        return ((_a = this._rightsHolderUserToAbility) === null || _a === void 0 ? void 0 : _a.permissions)
            ? this._rightsHolderUserToAbility.permissions.filter((perm) => perm.value).map((perm) => perm.name)
            : [];
    }
    doesRHUserHavePermission(permission) {
        return this.permissions.includes(permission);
    }
    doesRHUserHavePermissionOrIsInternal(permission) {
        return this.rootStore.userStore.isInternal || this.permissions.includes(permission);
    }
    doesRHUserHaveOneOfThesePermission(requiredPermissions) {
        return requiredPermissions.some((perm) => this.permissions.includes(perm));
    }
    doesRHUserHaveOneOfThesePermissionOrIsInternal(requiredPermissions) {
        let hasPermission = this.rootStore.userStore.isInternal;
        if (!hasPermission) {
            hasPermission = requiredPermissions.some((perm) => this.permissions.includes(perm));
        }
        return hasPermission;
    }
    destroyRightsHolderInviteInfo() {
        this._rightsHolderUserInviteInfo = null;
    }
}
exports.RightsholderUserStore = RightsholderUserStore;
