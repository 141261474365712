"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WORK_STATUSES = void 0;
exports.WORK_STATUSES = {
    INCOMPLETE: 'incomplete',
    PENDING: 'pending',
    SUBMITTED: 'submitted',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    SUPERSEDED: 'superseded',
    UNKNOWN: 'unknown',
};
