"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const zod_1 = require("zod");
const validate_1 = require("@App/helpers/validate");
const work_entities_fields_validators_1 = require("@App/helpers/work-entities-fields-validators");
const error_messages_1 = require("@Entities/schemas/work/error-messages");
const writerSchema = zod_1.z.object({
    id: zod_1.z
        .number({ required_error: (0, error_messages_1.requiredMessage)('Writer ID'), invalid_type_error: (0, error_messages_1.numericMessage)('Writer ID') })
        .int((0, error_messages_1.integerMessage)('Writer role'))
        .optional()
        .nullable(),
    ipId: zod_1.z
        .string({ required_error: (0, error_messages_1.requiredMessage)('IPID'), invalid_type_error: (0, error_messages_1.stringMessage)('IPID') })
        .optional()
        .nullable(),
    localId: zod_1.z
        .number({ required_error: (0, error_messages_1.requiredMessage)('Local Id'), invalid_type_error: (0, error_messages_1.numericMessage)('Local Id') })
        .int((0, error_messages_1.integerMessage)('Local Id'))
        .optional(),
    firstName: zod_1.z
        .string({ required_error: (0, error_messages_1.requiredMessage)('First name'), invalid_type_error: (0, error_messages_1.numericMessage)('First name') })
        .max(60, (0, error_messages_1.stringSizeMaxMessage)('First name', 60))
        .refine((val) => !(0, work_entities_fields_validators_1.containsNewLine)(val), (0, error_messages_1.newlineMessage)('First name'))
        .nullable(),
    lastName: zod_1.z
        .string({ required_error: (0, error_messages_1.requiredMessage)('Last name'), invalid_type_error: (0, error_messages_1.numericMessage)('Last name') })
        .max(200, (0, error_messages_1.stringSizeMaxMessage)('Last name', 200))
        .refine((val) => !(0, work_entities_fields_validators_1.containsNewLine)(val), (0, error_messages_1.newlineMessage)('Last name')),
    ipiNumber: zod_1.z
        .string({ required_error: (0, error_messages_1.requiredMessage)('IPI number'), invalid_type_error: (0, error_messages_1.numericMessage)('IPI number') })
        .refine((ipiNumber) => (0, validate_1.IPIValidator)(ipiNumber, false), 'Invalid IPI Number')
        .nullable(),
    roleCode: zod_1.z
        .number({ required_error: (0, error_messages_1.requiredMessage)('Writer role'), invalid_type_error: (0, error_messages_1.requiredMessage)('Writer role') })
        .int((0, error_messages_1.integerMessage)('Writer role')),
    writerShare: zod_1.z.number({ invalid_type_error: (0, error_messages_1.numericMessage)('Writer share') }).nullable(),
    amend: zod_1.z.boolean().optional().nullable(),
    worksCount: zod_1.z
        .number({ invalid_type_error: (0, error_messages_1.numericMessage)('Works count') })
        .int((0, error_messages_1.integerMessage)('Works count'))
        .optional(),
});
exports.default = writerSchema;
