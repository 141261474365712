"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const zod_1 = require("zod");
const work_entities_fields_validators_1 = require("@App/helpers/work-entities-fields-validators");
const error_messages_1 = require("@Entities/schemas/work/error-messages");
const alternativeTitleSchema = zod_1.z.object({
    id: zod_1.z.number().int((0, error_messages_1.integerMessage)('ID')).optional().nullable(),
    localId: zod_1.z.number().int((0, error_messages_1.integerMessage)('Local ID')).optional().nullable(),
    title: zod_1.z
        .string({
        required_error: (0, error_messages_1.requiredMessage)('Alternative title'),
        invalid_type_error: (0, error_messages_1.stringMessage)('Alternative title'),
    })
        .min(1, (0, error_messages_1.stringSizeBetweenMessage)('Alternative title', 1, 500))
        .max(500, (0, error_messages_1.stringSizeBetweenMessage)('Alternative title', 1, 500))
        .refine((val) => !(0, work_entities_fields_validators_1.containsNewLine)(val), (0, error_messages_1.newlineMessage)('Alternative title')),
    titleType: zod_1.z
        .number({ invalid_type_error: (0, error_messages_1.numericMessage)('Title type'), required_error: (0, error_messages_1.requiredMessage)('Title type') })
        .int((0, error_messages_1.integerMessage)('Title type')),
    languageCode: zod_1.z.number().int((0, error_messages_1.integerMessage)('Language code')).nullable(),
    amend: zod_1.z.boolean({ invalid_type_error: (0, error_messages_1.booleanMessage)('amend') }).optional(),
});
exports.default = alternativeTitleSchema;
