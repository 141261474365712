"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const form_field_1 = require("@App/forms/v2/common/form-field");
const populateSchema = (formFields, schema) => {
    if (formFields && (0, form_field_1.isFormFields)(formFields)) {
        Object.entries(formFields).forEach((entry) => {
            const formFieldValue = entry[1];
            if ((0, form_field_1.isFormFieldProp)(formFieldValue)) {
                const fieldProp = formFieldValue;
                fieldProp.schema = 'innerType' in schema ? schema.innerType().shape[entry[0]] : schema.shape[entry[0]];
            }
        });
        return formFields;
    }
    else {
        throw new Error('Unable To Process - Not FormFields');
    }
};
exports.default = populateSchema;
