"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublisherSearch = void 0;
class PublisherSearch {
    constructor() {
        Object.defineProperty(this, "publisherName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "publisherIpi", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "publisherNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
exports.PublisherSearch = PublisherSearch;
