"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.publishersCounter = void 0;
const publishersCounter = (originalPublishers) => {
    let publishersCount = originalPublishers.length;
    if (!publishersCount) {
        return 0;
    }
    originalPublishers.forEach((op) => {
        publishersCount += op.administratorPublishers.length;
    });
    return publishersCount;
};
exports.publishersCounter = publishersCounter;
