"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ILLUSTRATIONS = exports.ICONS = void 0;
/* eslint @typescript-eslint/no-var-requires: "off" */
const avatarIcon = require('@App/assets/icons/icon_circle_avatar.svg');
const accountIcon = require('@App/assets/icons/icon_account.svg');
const accountCircleIcon = require('@App/assets/icons/icon_account_circle.svg');
const accountMultiIcon = require('@App/assets/icons/icon_accountmulti.svg');
const addIcon = require('@App/assets/icons/icon_add.svg');
const alertAccessIcon = require('@App/assets/icons/icon_alert_access.svg');
const alertErrorIcon = require('@App/assets/icons/icon_alert_error.svg');
const arrowBackIcon = require('@App/assets/icons/icon_arrow_back.svg');
const arrowForwardIcon = require('@App/assets/icons/icon_arrow_forward.svg');
const catalogIcon = require('@App/assets/icons/icon_catalog.svg');
const overclaimAndDisputeIcon = require('@App/assets/icons/icon_overclaim_and_dispute.svg');
const checkIcon = require('@App/assets/icons/icon_check.svg');
const chevronDropDownIcon = require('@App/assets/icons/icon_chevron_drop_down.svg');
const chevronDropUpIcon = require('@App/assets/icons/icon_chevron_drop_up.svg');
const chevronLeftIcon = require('@App/assets/icons/icon_chevron_left.svg');
const chevronRightIcon = require('@App/assets/icons/icon_chevron_right.svg');
const closeIcon = require('@App/assets/icons/icon_close.svg');
const closeLargeIcon = require('@App/assets/icons/icon_close2.svg');
const doneIcon = require('@App/assets/icons/icon_done.svg');
const downloadIcon = require('@App/assets/icons/icon_download.svg');
const errorIcon = require('@App/assets/icons/icon_error.svg');
const facebookIcon = require('@App/assets/icons/icon_facebook.svg');
const helpIcon = require('@App/assets/icons/icon_help.svg');
const helpIcon2 = require('@App/assets/icons/icon_help2.svg');
const homeIcon = require('@App/assets/icons/icon_home.svg');
const instagramIcon = require('@App/assets/icons/icon_instagram.svg');
const linkedinIcon = require('@App/assets/icons/icon_linkedin.svg');
const menuIcon = require('@App/assets/icons/icon_menu.svg');
const notificationsIcon = require('@App/assets/icons/icon_notification.svg');
const pauseCircleFilledIcon = require('@App/assets/icons/icon_pause_circle_filled.svg');
const phoneIcon = require('@App/assets/icons/icon_phone.png');
const publishersIcon = require('@App/assets/icons/icon_publishers.svg');
const playCircleFilledIcon = require('@App/assets/icons/icon_play_circle_filled.svg');
const readyIcon = require('@App/assets/icons/icon_ready.svg');
const reportIcon = require('@App/assets/icons/icon_report.svg');
const recordingsIcon = require('@App/assets/icons/icon_recordings.svg');
const reviewIcon = require('@App/assets/icons/icon_review.svg');
const searchIcon = require('@App/assets/icons/icon_search.svg');
const searchAltIcon = require('@App/assets/icons/icon_search_alt.svg');
const settingsIcon = require('@App/assets/icons/icon_settings.svg');
const shareIcon = require('@App/assets/icons/icon_share.svg');
const summaryIcon = require('@App/assets/icons/icon_summary.svg');
const twitterIcon = require('@App/assets/icons/icon_twitter.svg');
const uploadIcon = require('@App/assets/icons/icon_upload.svg');
const warningIcon = require('@App/assets/icons/icon_warning.svg');
const workDetailIcon = require('@App/assets/icons/icon_workdetail.svg');
const workSearchIcon = require('@App/assets/icons/icon_worksearch.svg');
const writersIcon = require('@App/assets/icons/icon_writers.svg');
const minusIcon = require('@App/assets/icons/icon_minus.svg');
const trashCan = require('@App/assets/icons/icon_trash_can.svg');
const claimingToolIcon = require('@App/assets/icons/icon_claiming_tool.svg');
const pdfIcon = require('@App/assets/icons/icon_pdf.svg');
const linkIcon = require('@App/assets/icons/icon_link.svg');
const writeIcon = require('@App/assets/icons/icon_write.svg');
const mlcLogo = require('@App/assets/icons/mlc_logo.svg');
const preloaderCircle = require('@App/assets/icons/preloader-circle.svg');
const mlcLogoWhite = require('@App/assets/icons/mlc_logo_white.svg');
const optionsIcon = require('@App/assets/icons/icon_options.svg');
// recording details icons (white with circle)
const recordingData = require('@App/assets/icons/icon_circle_workdetail.png');
const recordingWriters = require('@App/assets/icons/icon_circle_writers.png');
const recordingMusic = require('@App/assets/icons/icon_circle_recordings.png');
const recordingDSP = require('@App/assets/icons/icon_circle_dsp.png');
const filterIcon = require('@App/assets/icons/filter.svg');
const email = require('@App/assets/icons/email.svg');
const chatBubbleBlue = require('@App/assets/icons/chat_bubble.svg');
const phoneBlue = require('@App/assets/icons/icon_phone_blue.svg');
const questionBlue = require('@App/assets/icons/icon_question_blue.svg');
const illustration1 = require('@App/assets/illustrations/illustration-1.svg');
const illustration2 = require('@App/assets/illustrations/illustration-2.svg');
const illustration3 = require('@App/assets/illustrations/illustration-3.svg');
const illustration4 = require('@App/assets/illustrations/illustration-4.svg');
exports.ICONS = {
    avatar: avatarIcon,
    account: accountIcon,
    account_circle: accountCircleIcon,
    account_multi: accountMultiIcon,
    add: addIcon,
    minus: minusIcon,
    alertAccess: alertAccessIcon,
    alertError: alertErrorIcon,
    arrow_back: arrowBackIcon,
    arrow_forward: arrowForwardIcon,
    catalog: catalogIcon,
    overclaim_and_dispute: overclaimAndDisputeIcon,
    chatBubble: chatBubbleBlue,
    check: checkIcon,
    chevron_drop_down: chevronDropDownIcon,
    chevron_drop_up: chevronDropUpIcon,
    chevron_left: chevronLeftIcon,
    chevron_right: chevronRightIcon,
    close: closeIcon,
    largeClose: closeLargeIcon,
    done: doneIcon,
    download: downloadIcon,
    email: email,
    error: errorIcon,
    facebook: facebookIcon,
    filter: filterIcon,
    help: helpIcon,
    help2: helpIcon2,
    home: homeIcon,
    instagram: instagramIcon,
    linkedin: linkedinIcon,
    menu: menuIcon,
    notifications: notificationsIcon,
    pause_circle_filled: pauseCircleFilledIcon,
    phone: phoneIcon,
    phoneBlue: phoneBlue,
    play_circle_filled: playCircleFilledIcon,
    publishers: publishersIcon,
    ready: readyIcon,
    recording: recordingsIcon,
    report: reportIcon,
    review: reviewIcon,
    search: searchIcon,
    search_alt: searchAltIcon,
    share: shareIcon,
    settings: settingsIcon,
    summary: summaryIcon,
    twitter: twitterIcon,
    upload: uploadIcon,
    warning: warningIcon,
    workDetails: workDetailIcon,
    workSearch: workSearchIcon,
    writers: writersIcon,
    mlc_logo: mlcLogo,
    preloader_circle: preloaderCircle,
    mlc_logo_white: mlcLogoWhite,
    options: optionsIcon,
    recordings: recordingsIcon,
    claimingToolIcon: claimingToolIcon,
    recordingData,
    recordingMusic,
    recordingDSP,
    recordingWriters,
    trashCan,
    pdfIcon: pdfIcon,
    linkIcon: linkIcon,
    writeIcon: writeIcon,
    questionBlue: questionBlue,
};
exports.ILLUSTRATIONS = {
    illustration1: illustration1,
    illustration2: illustration2,
    illustration3: illustration3,
    illustration4: illustration4,
};
