"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.configuration = void 0;
exports.configuration = Object.freeze({
    env: process.env.MLC_ENV,
    api: process.env.API_PROTO + '://' + process.env.API_HOST + '/api',
    simple: process.env.API_PROTO + '://' + process.env.API_HOST,
    api2v: process.env.API_2V_PROTO + '://' + process.env.API_2V_HOST + '/api2v',
    datadog_app_id: process.env.DATADOG_APPLICATION_ID,
    datadog_client_token: process.env.DATADOG_CLIENT_TOKEN,
    datadog_site: process.env.DATADOG_SITE,
});
