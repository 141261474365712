"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverclaimViewStore = void 0;
const mobx_1 = require("mobx");
const overclaimAPI = __importStar(require("../../api/routes/overclaim"));
class OverclaimViewStore {
    constructor(rootStore) {
        Object.defineProperty(this, "_rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_overclaimViewItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_overclaimHistory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_relinquishments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        (0, mobx_1.makeObservable)(this, {
            _overclaimViewItem: mobx_1.observable,
            _overclaimHistory: mobx_1.observable,
            _relinquishments: mobx_1.observable,
            isExistingRelinquishment: mobx_1.computed,
            getOverclaimViewItemByHeaderId: mobx_1.action,
            destroyOverclaimViewItem: mobx_1.action,
            setOverclaimViewItem: mobx_1.action,
        });
        this._rootStore = rootStore;
    }
    getOverclaimViewItemByHeaderId(overclaimHeaderId, rightsholderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield overclaimAPI.getOverclaimViewItemByOverclaimHeaderId(overclaimHeaderId, rightsholderId);
            if (response.data) {
                (0, mobx_1.runInAction)(() => {
                    const newOverclaimItem = response.data;
                    newOverclaimItem.workType = 'overclaim';
                    this._overclaimViewItem = newOverclaimItem;
                    this.getRelinquishmentsForCurrentOverclaimViewItem();
                    if (this._rootStore.userStore.isInternal) {
                        this.getOverclaimHistoryByHeaderId(overclaimHeaderId, rightsholderId);
                    }
                });
            }
        });
    }
    getOverclaimHistoryByHeaderId(overclaimHeaderId, rightsholderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield overclaimAPI.getOverclaimHistoryByOverclaimHeaderId(overclaimHeaderId, rightsholderId);
            if (response.data) {
                (0, mobx_1.runInAction)(() => {
                    this._overclaimHistory = response.data;
                });
            }
        });
    }
    sendOverclaimEmail(content) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if ((_a = this.overclaimViewItem) === null || _a === void 0 ? void 0 : _a.overclaimHeaderId) {
                this._rootStore.preloaderStore.openTransparentPreloader(true);
                try {
                    yield overclaimAPI.sendEmail((_b = this.overclaimViewItem) === null || _b === void 0 ? void 0 : _b.overclaimHeaderId, this._rootStore.rightsholderStore.currentRightsholderId, content);
                }
                catch (_c) {
                    this._rootStore.popupStore.setAlertData(`Your email is unable to be sent at this time.
                 Please reach out to our Support Team via the Contact Us page.`, true);
                }
                finally {
                    this._rootStore.preloaderStore.openTransparentPreloader(false);
                }
            }
        });
    }
    createOverclaimRelinquishment() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const overclaimHeaderId = (_a = this._overclaimViewItem) === null || _a === void 0 ? void 0 : _a.overclaimHeaderId;
            const ipId = this._rootStore.rightsholderStore.currentRightsholderId;
            if (overclaimHeaderId) {
                yield overclaimAPI
                    .createOverclaimRelinquishment(ipId, overclaimHeaderId)
                    .then(() => {
                    this.getRelinquishmentsForCurrentOverclaimViewItem();
                })
                    .catch(() => Promise.reject());
            }
            else {
                return Promise.reject();
            }
        });
    }
    getRelinquishmentsForCurrentOverclaimViewItem() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if ((_a = this._overclaimViewItem) === null || _a === void 0 ? void 0 : _a.overclaimHeaderId) {
                const relinquishments = yield overclaimAPI.getRelinquishmentsForOverclaim(this._rootStore.rightsholderStore.currentRightsholderId, this._overclaimViewItem.overclaimHeaderId);
                (0, mobx_1.runInAction)(() => {
                    var _a;
                    this._relinquishments = (_a = relinquishments.data) !== null && _a !== void 0 ? _a : [];
                });
            }
        });
    }
    get isExistingRelinquishment() {
        var _a;
        return !!((_a = this._relinquishments) === null || _a === void 0 ? void 0 : _a.some((relinquishment) => relinquishment.ipId === this._rootStore.rightsholderStore.currentRightsholderId));
    }
    get overclaimViewItem() {
        return this._overclaimViewItem;
    }
    setOverclaimViewItem(data) {
        this._overclaimViewItem = data;
    }
    get relinquishments() {
        return this._relinquishments;
    }
    get overclaimHistory() {
        return this._overclaimHistory;
    }
    destroyOverclaimViewItem() {
        this._overclaimViewItem = undefined;
    }
}
exports.OverclaimViewStore = OverclaimViewStore;
