"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userLoginFormFields = exports.MFA_LABEL = void 0;
const generic_form_field_props_1 = require("@App/forms/v2/common/generic-form-field-props");
const form_field_types_1 = require("@App/enum/form-field-types");
exports.MFA_LABEL = 'Code';
exports.userLoginFormFields = {
    username: generic_form_field_props_1.STANDARD_USERNAME,
    password: generic_form_field_props_1.STANDARD_PASSWORD,
    code: {
        name: 'code',
        label: exports.MFA_LABEL,
        type: form_field_types_1.FormFieldTypes.TEXT,
    },
};
