"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createRightsholderContactFormFields = void 0;
const mailing_address_form_1 = require("@App/forms/common/mailing-address-form");
const generic_form_field_props_1 = require("@App/forms/common/generic-form-field-props");
const error_messages_1 = require("@Const/error-messages");
const dropdown_options_1 = require("@Const/dropdown-options");
const form_field_types_1 = require("@App/enum/form-field-types");
const phone_number_form_1 = require("@App/forms/common/phone-number-form");
const createRightsholderContactFormFields = () => ({
    firstName: generic_form_field_props_1.STANDARD_FIRST_NAME,
    lastName: generic_form_field_props_1.OPTIONAL_LAST_NAME,
    email: generic_form_field_props_1.STANDARD_EMAIL,
    rightsholderContactTypeId: {
        name: 'rightsholderContactTypeId',
        label: 'Contact Type',
        type: form_field_types_1.FormFieldTypes.DROPDOWN,
        options: dropdown_options_1.contactTypeOptions,
        registerOptions: {
            required: error_messages_1.REQUIRED_MESSAGE,
        },
    },
    address: (0, mailing_address_form_1.getMailingAddressFormProps)('address.', true, true),
    phoneNumber: (0, phone_number_form_1.createPhoneNumberFormFields)('phoneNumber.', true),
});
exports.createRightsholderContactFormFields = createRightsholderContactFormFields;
