"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STANDARD_PASSWORD = exports.STANDARD_USERNAME = void 0;
const generic_form_field_props_1 = require("@App/forms/common/generic-form-field-props");
const form_field_types_1 = require("@App/enum/form-field-types");
exports.STANDARD_USERNAME = {
    name: 'username',
    label: generic_form_field_props_1.EMAIL_LABEL,
    type: form_field_types_1.FormFieldTypes.TEXT,
    assistiveTextVisibility: { errors: false, assistive: false },
};
exports.STANDARD_PASSWORD = {
    name: 'password',
    label: generic_form_field_props_1.PASSWORD_LABEL,
    type: form_field_types_1.FormFieldTypes.TEXT,
    assistiveTextVisibility: { errors: false, assistive: false },
    inputType: 'password',
};
