"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setRequiredIfTrue = exports.validateUsPostalCode = exports.isNotAnReservedEmailBoolean = exports.isNotAnReservedEmail = exports.checkIPIFormatWithErrors = exports.ISWCValidator = exports.IPIValidator = exports.valueIsEmpty = exports.emailIsValid = void 0;
const error_messages_1 = require("@Const/error-messages");
const isNumeric_1 = require("@App/helpers/isNumeric");
const emailIsValid = (value) => value
    .trim()
    .toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
exports.emailIsValid = emailIsValid;
const valueIsEmpty = (value) => value.trim().length === 0;
exports.valueIsEmpty = valueIsEmpty;
const IPIValidator = (ipi, required) => {
    if (!ipi && !required) {
        return true;
    }
    else {
        if (ipi) {
            ipi = ipi.trim();
            if (ipi.match(/^[0-9]*$/) && ipi.length >= 9 && ipi.length <= 11) {
                ipi = ipi.padStart(11, '0');
                const check = Number(ipi.substring(9, 11));
                return check === getChecksumFromIpiString(ipi);
            }
        }
        return false;
    }
};
exports.IPIValidator = IPIValidator;
const ISWCValidator = (iswc) => {
    if (!iswc) {
        return true;
    }
    iswc = iswc.replace(/-/g, '').replace(/\./g, '');
    if (iswc.length !== 11 || !iswc.startsWith('T')) {
        return false;
    }
    const digits = iswc.substring(1, 11);
    let sum = 1;
    for (let i = 0; i <= 8; i += 1) {
        const digit = '' + digits.charAt(i);
        sum = sum + (i + 1) * +digit;
    }
    let rem = sum % 10;
    if (rem !== 0) {
        rem = 10 - rem;
    }
    const check = Number('' + digits.charAt(9));
    return check === rem;
};
exports.ISWCValidator = ISWCValidator;
// This function is similar to IPIValidator above, but is used when forms need to return an error message to the user.
const checkIPIFormatWithErrors = (value) => !value.length
    ? 'Required'
    : value.length > 11
        ? 'Сan not be longer 11 characters'
        : !value.match(/^[0-9]*$/)
            ? 'IPI number must contain only numbers'
            : (0, exports.IPIValidator)(value, false)
                ? ''
                : 'Check sum error';
exports.checkIPIFormatWithErrors = checkIPIFormatWithErrors;
const getChecksumFromIpiString = (ipi) => {
    let sum = 0;
    let weight = 10;
    const digits = ipi.substring(0, 9);
    for (let i = 0; i < 9; i++) {
        const digit = '' + digits.charAt(i);
        sum += weight * +digit;
        weight--;
    }
    let checkSum = sum % 101;
    if (checkSum === 1) {
        checkSum = 0;
    }
    if (checkSum !== 0) {
        checkSum = 101 - checkSum;
    }
    return checkSum;
};
const isNotAnReservedEmail = (email) => {
    if (!email.includes('@')) {
        return undefined;
    }
    const reservedDomains = ['harryfox.com', 'rumblefish.com', 'sesac.com', 'themlc.com', 'themlc.us'];
    const domain = email.split('@').pop();
    const isReserved = reservedDomains.find((reservedDomain) => reservedDomain === domain);
    if (isReserved) {
        return error_messages_1.NO_RESERVED_EMAIL_MESSAGE;
    }
    else {
        return undefined;
    }
};
exports.isNotAnReservedEmail = isNotAnReservedEmail;
const isNotAnReservedEmailBoolean = (email) => {
    if (!email.includes('@')) {
        return true;
    }
    const reservedDomains = ['harryfox.com', 'rumblefish.com', 'sesac.com', 'themlc.com', 'themlc.us'];
    const domain = email.split('@').pop();
    return !reservedDomains.find((reservedDomain) => reservedDomain === domain);
};
exports.isNotAnReservedEmailBoolean = isNotAnReservedEmailBoolean;
const validateUsPostalCode = (postalCode) => {
    if (postalCode.includes('-')) {
        const postalCodes = postalCode.split('-');
        if (postalCodes.length > 2) {
            return false;
        }
        if (postalCodes[0].length !== 5) {
            return false;
        }
        if (!(0, isNumeric_1.isNumeric)(postalCodes[0])) {
            return false;
        }
        if (postalCodes[1].length !== 4) {
            return false;
        }
        return (0, isNumeric_1.isNumeric)(postalCodes[1]);
    }
    else {
        return postalCode.length === 5 && (0, isNumeric_1.isNumeric)(postalCode);
    }
};
exports.validateUsPostalCode = validateUsPostalCode;
const setRequiredIfTrue = (isTrue, registerOptions) => isTrue
    ? Object.assign(Object.assign({}, registerOptions), { required: error_messages_1.REQUIRED_MESSAGE }) : Object.assign(Object.assign({}, registerOptions), { required: false });
exports.setRequiredIfTrue = setRequiredIfTrue;
