"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.regHistoryTabNamesToSource = void 0;
const registration_history_tab_name_1 = require("@App/enum/registration-history-tab-name");
const regHistoryTabNamesToSource = (tabName) => {
    switch (tabName) {
        case registration_history_tab_name_1.RegistrationHistoryTabName.Completed:
            return 'completed';
        case registration_history_tab_name_1.RegistrationHistoryTabName.Pending:
            return 'mlc';
        default:
            return undefined;
    }
};
exports.regHistoryTabNamesToSource = regHistoryTabNamesToSource;
