"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringLengthCreator = exports.createUpdatingWorkDraft = void 0;
const createUpdatingWorkDraft = (work, key, data) => {
    const dataArr = work[key];
    const lastLocalId = dataArr.length ? Number(dataArr[dataArr.length - 1].localId) : 0;
    return Object.assign(Object.assign({}, work), { [key]: [
            ...dataArr,
            Object.assign(Object.assign({}, data), { localId: lastLocalId + 1 }),
        ] });
};
exports.createUpdatingWorkDraft = createUpdatingWorkDraft;
const stringLengthCreator = (length) => {
    let value = '';
    for (let n = 0; n < length; n++) {
        value += 'x';
    }
    return value;
};
exports.stringLengthCreator = stringLengthCreator;
