"use strict";
// These helpers return updated WorkModel after removing an entity unit
Object.defineProperty(exports, "__esModule", { value: true });
exports.setItemToArrayByIndex = exports.recordingRemover = exports.adminPublisherRemover = exports.originalPublisherRemover = exports.writerRemover = exports.akaTitleRemover = void 0;
const akaTitleRemover = (removingTitle, workDraftFromLocal) => {
    const alternativeTitles = workDraftFromLocal.alternativeTitles;
    const updatedAlternativeTitles = alternativeTitles.filter((data) => removingTitle.localId !== data.localId);
    const updatedWorkDraft = Object.assign(Object.assign({}, workDraftFromLocal), { alternativeTitles: updatedAlternativeTitles });
    return updatedWorkDraft;
};
exports.akaTitleRemover = akaTitleRemover;
const writerRemover = (removingWriter, workDraftFromLocal) => {
    const writers = workDraftFromLocal.writers;
    const updatedWriters = writers.filter((data) => removingWriter.localId !== data.localId);
    const updatedOp = workDraftFromLocal.originalPublishers.map((op) => {
        const updatedWr = op.writers.filter((writer) => {
            if (removingWriter.id ? removingWriter.id === writer.id : removingWriter.localId === writer.localId) {
                return;
            }
            return writer;
        });
        return Object.assign(Object.assign({}, op), { writers: updatedWr });
    });
    const updatedWorkDraft = Object.assign(Object.assign({}, workDraftFromLocal), { originalPublishers: updatedOp, writers: updatedWriters });
    return updatedWorkDraft;
};
exports.writerRemover = writerRemover;
const originalPublisherRemover = (removingOP, workDraftFromLocal) => {
    const originalPublishers = workDraftFromLocal.originalPublishers;
    const updatedOP = originalPublishers.filter((data) => {
        if (removingOP.localId === data.localId) {
            return;
        }
        return data;
    });
    const updatedWorkDraft = Object.assign(Object.assign({}, workDraftFromLocal), { originalPublishers: updatedOP });
    return updatedWorkDraft;
};
exports.originalPublisherRemover = originalPublisherRemover;
const adminPublisherRemover = (removingAP, currentOP, workDraftFromLocal) => {
    const originalPublishers = workDraftFromLocal.originalPublishers;
    const administrationPublishers = currentOP.administratorPublishers;
    const currentOPid = currentOP.localId;
    const indexOfOP = originalPublishers.map((op) => op.localId).indexOf(currentOPid);
    const updatedCurrentAP = administrationPublishers.filter((data) => {
        if (removingAP.localId === data.localId) {
            return;
        }
        return data;
    });
    const updatedCurrentOP = Object.assign(Object.assign({}, currentOP), { administratorPublishers: updatedCurrentAP });
    const updatedOPArray = (0, exports.setItemToArrayByIndex)(originalPublishers, +indexOfOP, updatedCurrentOP);
    const updatedWorkDraft = Object.assign(Object.assign({}, workDraftFromLocal), { originalPublishers: updatedOPArray });
    return updatedWorkDraft;
};
exports.adminPublisherRemover = adminPublisherRemover;
const recordingRemover = (removingRecording, workDraftFromLocal) => {
    const recordings = workDraftFromLocal.recordings;
    const updatedRecordings = recordings.filter((data) => {
        if (removingRecording.localId === data.localId) {
            return;
        }
        return data;
    });
    const updatedRecordingsDraft = Object.assign(Object.assign({}, workDraftFromLocal), { recordings: updatedRecordings });
    return updatedRecordingsDraft;
};
exports.recordingRemover = recordingRemover;
const setItemToArrayByIndex = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index + 1),
];
exports.setItemToArrayByIndex = setItemToArrayByIndex;
