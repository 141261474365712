"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkUploadErrorType = void 0;
var BulkUploadErrorType;
(function (BulkUploadErrorType) {
    BulkUploadErrorType[BulkUploadErrorType["PARSE_ERROR"] = 0] = "PARSE_ERROR";
    BulkUploadErrorType[BulkUploadErrorType["FILE_TOO_LARGE"] = 1] = "FILE_TOO_LARGE";
    BulkUploadErrorType[BulkUploadErrorType["REQUIRED"] = 2] = "REQUIRED";
    BulkUploadErrorType[BulkUploadErrorType["DUPLICATE_TITLE"] = 3] = "DUPLICATE_TITLE";
    BulkUploadErrorType[BulkUploadErrorType["UNKNOWN"] = 4] = "UNKNOWN";
})(BulkUploadErrorType = exports.BulkUploadErrorType || (exports.BulkUploadErrorType = {}));
