"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatUSD = exports.formatter = void 0;
exports.formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
const formatUSD = (value) => exports.formatter.format(value);
exports.formatUSD = formatUSD;
