"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canMemberEdit = void 0;
const canMemberEdit = (work, rhId) => {
    const membersThatCanEdit = [];
    work.originalPublishers.forEach((originalPublisher) => {
        if (originalPublisher.publisherShare !== 0) {
            membersThatCanEdit.push(originalPublisher.topMemberIpId);
        }
        else {
            originalPublisher.administratorPublishers.forEach((administratorPublisher) => {
                if (administratorPublisher.publisherShare !== 0) {
                    membersThatCanEdit.push(administratorPublisher.topMemberIpId);
                }
            });
        }
    });
    return membersThatCanEdit.includes(rhId);
};
exports.canMemberEdit = canMemberEdit;
