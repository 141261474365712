"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modifyMailingAddressFormProps = exports.markFieldsAsRequired = exports.getMailingAddressFormProps = void 0;
const error_messages_1 = require("@Const/error-messages");
const form_field_types_1 = require("@App/enum/form-field-types");
const getDefaultStreetAddress = (prefix, optional) => ({
    type: form_field_types_1.FormFieldTypes.TEXT,
    name: prefix + 'streetAddress',
    label: 'Street Address',
    registerOptions: {
        required: optional ? false : error_messages_1.REQUIRED_MESSAGE,
        maxLength: {
            value: 60,
            message: `${error_messages_1.MAX_LENGTH_MESSAGE} 60`,
        },
    },
});
const getDefaultUnitNumber = (prefix) => ({
    type: form_field_types_1.FormFieldTypes.TEXT,
    name: prefix + 'unitNumber',
    label: 'Unit Number',
    registerOptions: {
        required: false,
        maxLength: {
            value: 10,
            message: `${error_messages_1.MAX_LENGTH_MESSAGE} 10`,
        },
    },
});
const getDefaultAttention = (prefix) => ({
    type: form_field_types_1.FormFieldTypes.TEXT,
    name: prefix + 'attention',
    label: 'Attention',
    registerOptions: {
        required: false,
        maxLength: {
            value: 25,
            message: `${error_messages_1.MAX_LENGTH_MESSAGE} 25`,
        },
    },
});
const getDefaultCity = (prefix, optional) => ({
    type: form_field_types_1.FormFieldTypes.TEXT,
    name: prefix + 'city',
    label: 'City / Town',
    registerOptions: {
        required: optional ? false : error_messages_1.REQUIRED_MESSAGE,
        maxLength: {
            value: 60,
            message: `${error_messages_1.MAX_LENGTH_MESSAGE} 60`,
        },
    },
});
const getDefaultState = (prefix, stateOptions, isUSAOrCan) => {
    if (isUSAOrCan) {
        return {
            type: form_field_types_1.FormFieldTypes.DROPDOWN,
            name: prefix + 'state',
            label: 'State / Province',
            options: stateOptions,
            isClearable: true,
            registerOptions: {
                required: error_messages_1.REQUIRED_MESSAGE,
            },
        };
    }
    else {
        return {
            type: form_field_types_1.FormFieldTypes.TEXT,
            name: prefix + 'state',
            label: 'State / Province',
            registerOptions: {
                required: false,
                maxLength: {
                    value: 60,
                    message: `${error_messages_1.MAX_LENGTH_MESSAGE} 60`,
                },
            },
        };
    }
};
const getDefaultPostalCode = (prefix, isPostalCodeRequired) => ({
    type: form_field_types_1.FormFieldTypes.TEXT,
    name: prefix + 'postalCode',
    label: 'ZIP / Postal Code',
    registerOptions: {
        required: isPostalCodeRequired ? error_messages_1.REQUIRED_MESSAGE : false,
        maxLength: {
            value: 60,
            message: `${error_messages_1.MAX_LENGTH_MESSAGE} 60`,
        },
    },
});
const getMailingAddressFormProps = (prefix = '', withAttention, optional) => ({
    streetAddress: getDefaultStreetAddress(prefix, !!optional),
    unitNumber: getDefaultUnitNumber(prefix),
    attention: withAttention ? getDefaultAttention(prefix) : undefined,
    city: getDefaultCity(prefix, !!optional),
    state: getDefaultState(prefix, [], !!optional),
    country: {
        type: form_field_types_1.FormFieldTypes.DROPDOWN,
        name: prefix + 'countryId',
        label: 'Country',
        isClearable: true,
        registerOptions: {
            required: optional ? false : error_messages_1.REQUIRED_MESSAGE,
        },
        options: [],
    },
    postalCode: getDefaultPostalCode(prefix, !!optional),
});
exports.getMailingAddressFormProps = getMailingAddressFormProps;
const markFieldsAsRequired = (mailingAddressFormProps, isOptional) => {
    mailingAddressFormProps.streetAddress = Object.assign(Object.assign({}, mailingAddressFormProps.streetAddress), { registerOptions: Object.assign(Object.assign({}, mailingAddressFormProps.streetAddress.registerOptions), { required: isOptional ? false : error_messages_1.REQUIRED_MESSAGE }) });
    mailingAddressFormProps.city = Object.assign(Object.assign({}, mailingAddressFormProps.city), { registerOptions: Object.assign(Object.assign({}, mailingAddressFormProps.city.registerOptions), { required: isOptional ? false : error_messages_1.REQUIRED_MESSAGE }) });
    mailingAddressFormProps.country = Object.assign(Object.assign({}, mailingAddressFormProps.country), { registerOptions: Object.assign(Object.assign({}, mailingAddressFormProps.country.registerOptions), { required: isOptional ? false : error_messages_1.REQUIRED_MESSAGE }) });
    if (isOptional) {
        mailingAddressFormProps.state = Object.assign(Object.assign({}, mailingAddressFormProps.state), { registerOptions: Object.assign(Object.assign({}, mailingAddressFormProps.state.registerOptions), { required: false }) });
        mailingAddressFormProps.postalCode = Object.assign(Object.assign({}, mailingAddressFormProps.postalCode), { registerOptions: Object.assign(Object.assign({}, mailingAddressFormProps.postalCode.registerOptions), { required: false }) });
    }
    return mailingAddressFormProps;
};
exports.markFieldsAsRequired = markFieldsAsRequired;
const modifyMailingAddressFormProps = (mailingAddressFormProps, isPostalCodeRequired, isUSA, isCanada, stateOptions) => {
    if (isUSA || isCanada) {
        mailingAddressFormProps.state = Object.assign(Object.assign({}, mailingAddressFormProps.state), { type: form_field_types_1.FormFieldTypes.DROPDOWN, options: stateOptions, registerOptions: Object.assign(Object.assign({}, mailingAddressFormProps.state.registerOptions), { required: error_messages_1.REQUIRED_MESSAGE }) });
    }
    else {
        mailingAddressFormProps.state = Object.assign(Object.assign({}, mailingAddressFormProps.state), { type: form_field_types_1.FormFieldTypes.TEXT, registerOptions: Object.assign(Object.assign({}, mailingAddressFormProps.state.registerOptions), { required: false, maxLength: {
                    value: 60,
                    message: `${error_messages_1.MAX_LENGTH_MESSAGE} 60`,
                } }) });
    }
    if (isPostalCodeRequired) {
        mailingAddressFormProps.postalCode = Object.assign(Object.assign({}, mailingAddressFormProps.postalCode), { registerOptions: Object.assign(Object.assign({}, mailingAddressFormProps.postalCode.registerOptions), { required: error_messages_1.REQUIRED_MESSAGE, maxLength: {
                    value: 10,
                    message: `${error_messages_1.MAX_LENGTH_MESSAGE} 10`,
                }, validate: {
                    checkPostalCodeFormat: (value) => {
                        let regex;
                        if (isUSA) {
                            regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
                        }
                        if (isCanada) {
                            regex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
                        }
                        if (regex) {
                            if (regex.test(value)) {
                                return true;
                            }
                            else {
                                return 'Must be a valid ZIP code';
                            }
                        }
                        else {
                            return !!value;
                        }
                    },
                } }) });
    }
    else {
        mailingAddressFormProps.postalCode = Object.assign(Object.assign({}, mailingAddressFormProps.postalCode), { registerOptions: Object.assign(Object.assign({}, mailingAddressFormProps.postalCode.registerOptions), { required: false, maxLength: {
                    value: 60,
                    message: `${error_messages_1.MAX_LENGTH_MESSAGE} 60`,
                }, validate: undefined }) });
    }
    return mailingAddressFormProps;
};
exports.modifyMailingAddressFormProps = modifyMailingAddressFormProps;
