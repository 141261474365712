"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const isFieldErrors = (value) => value.message === undefined;
const isFieldError = (value) => value.message !== undefined;
const getFormError = (formFields, errors) => {
    if (formFields.parentObjectName) {
        const parentErrors = (0, lodash_1.get)(errors, `${formFields.parentObjectName}.${formFields.name}`);
        if (parentErrors && isFieldError(parentErrors)) {
            return parentErrors;
        }
        if (parentErrors && isFieldErrors(parentErrors)) {
            const error = parentErrors[formFields.name];
            if (error && isFieldError(error)) {
                return error;
            }
        }
    }
    else {
        const error = errors[formFields.name];
        if (error && isFieldError(error)) {
            return error;
        }
    }
    return undefined;
};
exports.default = getFormError;
