"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClaimToolStore = void 0;
const mobx_1 = require("mobx");
const work_registration_forms_1 = require("@Const/work-registration-forms");
class ClaimToolStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_invalidPublishers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "onNextValidatePublishers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const hasValidPublishers = this.validateWorkHasEligiblePublisher();
                (0, mobx_1.runInAction)(() => (this._invalidPublishers = !hasValidPublishers));
                return hasValidPublishers;
            }
        });
        Object.defineProperty(this, "validateWorkHasEligiblePublisher", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const workDetails = this.rootStore.workStore.workDraftLocal;
                return (workDetails === null || workDetails === void 0 ? void 0 : workDetails.originalPublishers)
                    ? workDetails.originalPublishers
                        .flatMap((op) => op.administratorPublishers)
                        .concat(workDetails.originalPublishers)
                        .map((pub) => pub.amend)
                        .some((amend) => !!amend)
                    : false;
            }
        });
        Object.defineProperty(this, "validateClaimedWork", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (workToValidate) => {
                const hasComposer = !!workToValidate.writers.filter((writer) => writer.roleCode === 10 || writer.roleCode === 11).length;
                if (!hasComposer) {
                    this.rootStore.popupStore.setAlertData('Cannot currently claim shares on this work due to missing Composer or Composer/Author. Please reach out to Support for assistance.', true);
                }
                return hasComposer;
            }
        });
        Object.defineProperty(this, "clearWorkHistory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.rootStore.workStore.destroyViewWork();
                this.rootStore.workStore.setWorkDraftToLocal(work_registration_forms_1.CLEAR_WORK_DRAFT);
                this.rootStore.claimingToolSearchStore.resetClaimSearchCriteriaIfNeeded();
            }
        });
        Object.defineProperty(this, "resetClaimingToolWizard", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.clearWorkHistory();
                this.rootStore.workStore.setWizardStepValidation(false);
                this.rootStore.wizardFlowStore.resetIndex();
            }
        });
        Object.defineProperty(this, "submitClaim", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const workToSubmit = this.rootStore.workStore.workDraftLocal;
                if (!workToSubmit) {
                    return;
                }
                // TODO - Hopefully handle this validation in a more elegant manner with react-hook-forms
                if (!this.validateClaimedWork(workToSubmit)) {
                    return;
                }
                const submittedClaim = yield this.rootStore.workStore.createWork(workToSubmit, this.rootStore.rightsholderStore.currentRightsholderId);
                this.rootStore.popupStore.setAlertData('Claim Submitted Successfully', false);
                return submittedClaim;
            })
        });
        (0, mobx_1.makeObservable)(this, {
            _invalidPublishers: mobx_1.observable,
            setInvalidPublishers: mobx_1.action,
        });
        this.rootStore = rootStore;
    }
    get invalidPublishers() {
        return this._invalidPublishers;
    }
    setInvalidPublishers(isInvalid) {
        this._invalidPublishers = isInvalid;
    }
}
exports.ClaimToolStore = ClaimToolStore;
