"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHoldColor = exports.getHoldTypeIdFromName = exports.getHoldText = exports.isWorkOnHold = exports.WORK_HOLD_STATUS = void 0;
exports.WORK_HOLD_STATUS = {
    MLC_OVERCLAIM: 104,
    MLC_DISPUTE: 105,
    MLC_SONG_LEGAL: 101,
    SHARE_ISSUE: 141,
    SHARES_MISSING: 142,
};
const isWorkOnHold = (workItem) => !!workItem.holdTypeId;
exports.isWorkOnHold = isWorkOnHold;
const getHoldText = (status) => {
    switch (status) {
        case 104:
            return 'Overclaim';
        case 105:
            return 'Dispute';
        case 101:
            // This should be "Legal" once 101 and 104 are separated in the filter logic.
            return 'Dispute';
        case 141:
            return 'Share Issue';
        case 142:
            return 'Share Missing';
        default:
            return null;
    }
};
exports.getHoldText = getHoldText;
const getHoldTypeIdFromName = (name) => {
    switch (name) {
        case 'MLC_OVERCLAIM':
            return [exports.WORK_HOLD_STATUS.MLC_OVERCLAIM];
        case 'MLC_DISPUTE':
            // For now, hold status 101 and 105 will be applied to the catalog search when using the Dispute filter.
            return [exports.WORK_HOLD_STATUS.MLC_DISPUTE, exports.WORK_HOLD_STATUS.MLC_SONG_LEGAL];
        case 'MLC_SONG_LEGAL':
            return [exports.WORK_HOLD_STATUS.MLC_SONG_LEGAL];
        case 'SHARE_ISSUE':
            return [exports.WORK_HOLD_STATUS.SHARE_ISSUE];
        case 'SHARES_MISSING':
            return [exports.WORK_HOLD_STATUS.SHARES_MISSING];
        default:
            return [exports.WORK_HOLD_STATUS.MLC_OVERCLAIM];
    }
};
exports.getHoldTypeIdFromName = getHoldTypeIdFromName;
const getHoldColor = (status) => {
    switch (status) {
        case 104:
            return 'orange';
        case 105:
        case 101: // For now, hold status 101 and 105 will be treated the same, as Dispute
            return 'black';
        case 141:
        case 142:
            return 'yellow';
        default:
            return 'blue';
    }
};
exports.getHoldColor = getHoldColor;
