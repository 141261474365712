"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertPublisherSearchTerms = exports.convertWriterSearchTerms = exports.convertNewSearchTerms = void 0;
const convertNewSearchTerms = (search, hasUnclaimedShare, notClaimedBy) => (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (search.title && { TITLE: search.title })), (search.memberProprietaryId && { MEMBER_PROPRIETARY_ID: search.memberProprietaryId })), (search.writerName && { WRITER_FULL_NAMES: search.writerName })), (search.writerIpi && { WRITER_NAME_NUMBERS: search.writerIpi })), (search.writerIpId && { WRITER_IP_IDS: search.writerIpId })), (search.publisherName && { ORIGINAL_PUB_NAMES: search.publisherName })), (search.publisherIpi && { ORIGINAL_PUB_IPI_NAME_NUMBERS: search.publisherIpi })), (search.publisherNumber && { ORIGINAL_PUB_HFA_NUMBERS: search.publisherNumber })), (search.publisherIpId && { ORIGINAL_PUB_IP_IDS: search.publisherIpId })), (search.iswc && { ISWC: search.iswc })), (search.songCode && { HFA_SONG_CODE: search.songCode })), (search.recordingName && { RECORDING_TITLES: search.recordingName })), (search.artist && { RECORDING_ARTISTS: search.artist })), (search.isrc && { RECORDING_ISRCS: search.isrc })), (search.derivative && { IS_DERIVATIVE: 'true' })), (!(hasUnclaimedShare === undefined) && { HAS_UNCLAIMED_SHARE: hasUnclaimedShare })), (notClaimedBy && { NOT_CLAIMED_BY: notClaimedBy })), (search.overclaim && { OVERCLAIM: 'true' })), (!!(search.overclaimStatusFilter && search.overclaimStatusFilter.length) && {
    OVERCLAIM_STATUS_FILTER: search.overclaimStatusFilter,
})));
exports.convertNewSearchTerms = convertNewSearchTerms;
const convertWriterSearchTerms = (search) => (Object.assign(Object.assign({}, (search.writerIpi && { IPI_NAME_NUMBER: search.writerIpi })), (search.writerName && { FULL_NAME: search.writerName })));
exports.convertWriterSearchTerms = convertWriterSearchTerms;
const convertPublisherSearchTerms = (search) => (Object.assign(Object.assign(Object.assign({}, (search.publisherIpi && { IPI_NAME_NUMBER: search.publisherIpi })), (search.publisherName && { FULL_NAME: search.publisherName })), (search.publisherNumber && { HFA_NUMBER: search.publisherNumber })));
exports.convertPublisherSearchTerms = convertPublisherSearchTerms;
