"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactStore = void 0;
const mobx_1 = require("mobx");
const contact_1 = require("@App/api/routes/rightsholder/contact");
const flatten_object_1 = require("@App/helpers/flatten-object");
const rightsholder_contact_types_1 = require("@App/enum/rightsholder-contact-types");
class ContactStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_rightsholderContact", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_rightsholderContacts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "setRightsholderContact", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderContact) => {
                this._rightsholderContact = rightsholderContact;
            }
        });
        Object.defineProperty(this, "loadRightsholderContacts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _a;
                const response = yield (0, contact_1.getRightsholderContacts)(this.rootStore.rightsholderStore.currentRightsholderId);
                const mappedResponse = (_a = response.data) === null || _a === void 0 ? void 0 : _a.data;
                (0, mobx_1.runInAction)(() => {
                    this._rightsholderContacts = { count: response.data.count, data: mappedResponse };
                });
            })
        });
        Object.defineProperty(this, "loadRightsholderContactById", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (contactId) => __awaiter(this, void 0, void 0, function* () {
                // Attempt to find already-loaded contact first
                if (this._rightsholderContacts) {
                    const contactList = this._rightsholderContacts;
                    const matchingContact = contactList.data.find((contact) => contact.id === contactId);
                    if (matchingContact) {
                        this.setRightsholderContact(matchingContact);
                        return Promise.resolve();
                    }
                }
                const response = yield (0, contact_1.getRightsholderContactById)(this.rootStore.rightsholderStore.currentRightsholderId, contactId);
                this.setRightsholderContact((0, flatten_object_1.flattenObject)(response.data));
            })
        });
        Object.defineProperty(this, "editRightsholderContact", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (contact) => __awaiter(this, void 0, void 0, function* () {
                const updatedContactResponse = yield (0, contact_1.editRightsholderContact)(contact);
                const updatedContact = updatedContactResponse.data;
                this.setRightsholderContact(updatedContact);
                const rightsholderContacts = this.rightsholderContacts;
                if (rightsholderContacts) {
                    const contactList = rightsholderContacts.data;
                    const oldContactIndex = contactList.findIndex((contactFromList) => contactFromList.id === contact.id);
                    if (oldContactIndex) {
                        contactList[oldContactIndex] = updatedContact;
                        this.updateContactList(() => contactList);
                    }
                    else {
                        yield this.loadRightsholderContacts();
                    }
                }
                else {
                    yield this.loadRightsholderContacts();
                }
            })
        });
        Object.defineProperty(this, "createRightsholderContact", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (contact) => __awaiter(this, void 0, void 0, function* () {
                contact.ipId = this.rootStore.rightsholderStore.currentRightsholderId;
                const newContactResponse = yield (0, contact_1.createRightsholderContact)(contact);
                const newContact = newContactResponse.data;
                this.setRightsholderContact(newContact);
                const rightsholderContacts = this.rightsholderContacts;
                if (rightsholderContacts) {
                    const contactList = rightsholderContacts.data;
                    contactList.push(newContact);
                    this.updateContactList(() => contactList);
                }
                else {
                    yield this.loadRightsholderContacts();
                }
            })
        });
        Object.defineProperty(this, "deleteRightsholderContact", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (rightsholderId, contactId) => __awaiter(this, void 0, void 0, function* () {
                return (0, contact_1.deleteRightsholderContact)(rightsholderId, contactId)
                    .then(() => __awaiter(this, void 0, void 0, function* () {
                    if (this.rightsholderContacts) {
                        const contactList = this.rightsholderContacts;
                        this.updateContactList(() => contactList.data.filter((contact) => contact.id !== contactId));
                    }
                    return Promise.resolve();
                }))
                    .catch((error) => Promise.reject(error));
            })
        });
        (0, mobx_1.makeObservable)(this, {
            _rightsholderContact: mobx_1.observable,
            _rightsholderContacts: mobx_1.observable,
            rightsholderHasPublicContact: mobx_1.computed,
            setRightsholderContact: mobx_1.action,
            updateContactList: mobx_1.action,
        });
        this.rootStore = rootStore;
    }
    get rightsholderContact() {
        return this._rightsholderContact;
    }
    get rightsholderContacts() {
        return this._rightsholderContacts;
    }
    get rightsholderHasPublicContact() {
        var _a;
        return !!((_a = this._rightsholderContacts) === null || _a === void 0 ? void 0 : _a.data.some((contact) => {
            var _a;
            return contact.rightsholderContactTypeId === rightsholder_contact_types_1.RightsholderContactTypes.PUBLIC &&
                ((_a = this._rightsholderContact) === null || _a === void 0 ? void 0 : _a.id) !== contact.id;
        }));
    }
    updateContactList(newContactListFn) {
        const newContactList = newContactListFn();
        if (newContactList) {
            this._rightsholderContacts = {
                count: newContactList.length,
                data: newContactList,
            };
        }
    }
}
exports.ContactStore = ContactStore;
