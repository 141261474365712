"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreloaderStore = void 0;
const mobx_1 = require("mobx");
class PreloaderStore {
    constructor() {
        Object.defineProperty(this, "_mainPreloaderIsOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_transparentPreloaderIsOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        (0, mobx_1.makeObservable)(this, {
            _mainPreloaderIsOpen: mobx_1.observable,
            _transparentPreloaderIsOpen: mobx_1.observable,
            openMainPreloader: mobx_1.action,
            openTransparentPreloader: mobx_1.action,
        });
        this._mainPreloaderIsOpen = false;
        this._transparentPreloaderIsOpen = false;
    }
    get mainPreloaderIsOpen() {
        return this._mainPreloaderIsOpen;
    }
    get transparentPreloaderIsOpen() {
        return this._transparentPreloaderIsOpen;
    }
    openMainPreloader(isOpen) {
        this._mainPreloaderIsOpen = isOpen;
    }
    openTransparentPreloader(isOpen) {
        this._transparentPreloaderIsOpen = isOpen;
    }
}
exports.PreloaderStore = PreloaderStore;
