"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const mobx_react_1 = require("mobx-react");
require("./index.scss");
const work_registration_forms_1 = require("@Const/work-registration-forms");
const contexts_1 = require("@App/contexts");
const fetch_type_enum_1 = require("@App/kit/components/WorkItemV2/fetch-type-enum");
const SubmissionStep = () => {
    var _a, _b;
    const { workStore } = (0, react_1.useContext)(contexts_1.StoresContext);
    const determineWorkType = () => __awaiter(void 0, void 0, void 0, function* () {
        if (workStore.createdWorkId) {
            yield workStore.getWorkById(workStore.createdWorkId, fetch_type_enum_1.FetchType.MLC);
        }
    });
    (0, react_1.useEffect)(() => {
        determineWorkType().then();
        workStore.setWizardStepValidation(true);
        return () => {
            workStore.destroyViewWork();
            workStore.setWorkDraftToLocal(work_registration_forms_1.CLEAR_WORK_DRAFT);
            workStore.setWizardStepValidation(false);
        };
    }, []);
    if (!workStore.workById) {
        return null;
    }
    return (react_1.default.createElement("div", { className: "submission-step" },
        react_1.default.createElement("h2", null, "Work Submitted for Registration"),
        react_1.default.createElement("div", { className: "submitted-work" },
            react_1.default.createElement("p", { className: "bold", title: workStore.workById.title }, workStore.workById.title),
            react_1.default.createElement("div", { className: "info" },
                react_1.default.createElement("p", { className: "ref" },
                    "Ref number: ",
                    react_1.default.createElement("strong", null, (_b = (_a = workStore.workById) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : workStore.createdWorkId)),
                react_1.default.createElement("p", { className: "small status" }, "Submitted")))));
};
exports.default = (0, mobx_react_1.observer)(SubmissionStep);
