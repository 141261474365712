"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchOptionType = void 0;
// this determines how the available search options are returned
var SearchOptionType;
(function (SearchOptionType) {
    // primary: if the first search criterion is not Primary, then NO primary search options are returned, otherwise all options are returned
    SearchOptionType[SearchOptionType["primary"] = 0] = "primary";
    // filter: will never return the same option twice
    SearchOptionType[SearchOptionType["filter"] = 1] = "filter";
    // determine: returns matching criterion's matching option if criterion is disabled, else it returns all options with shouldHide = false
    SearchOptionType[SearchOptionType["determine"] = 2] = "determine";
})(SearchOptionType = exports.SearchOptionType || (exports.SearchOptionType = {}));
