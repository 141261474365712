"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const zod_1 = require("zod");
const work_entities_fields_validators_1 = require("@App/helpers/work-entities-fields-validators");
const validate_1 = require("@App/helpers/validate");
const error_messages_1 = require("@Entities/schemas/work/error-messages");
const regexp_1 = require("@Const/regexp");
const workDetailsSchema = zod_1.z.object({
    title: zod_1.z
        .string({ invalid_type_error: (0, error_messages_1.stringMessage)('Title'), required_error: (0, error_messages_1.requiredMessage)('Title') })
        .min(1, (0, error_messages_1.stringSizeMinMessage)('Title', 1))
        .max(200, (0, error_messages_1.stringSizeMaxMessage)('Title', 200))
        .refine((val) => !(0, work_entities_fields_validators_1.containsNewLine)(val), (0, error_messages_1.newlineMessage)('Title')),
    duration: zod_1.z
        .string({ invalid_type_error: (0, error_messages_1.stringMessage)('Duration'), required_error: (0, error_messages_1.requiredMessage)('Duration') })
        .regex(regexp_1.DURATION_REGEX, (0, error_messages_1.durationMessage)())
        .or(zod_1.z.literal(''))
        .nullable(),
    languageCode: zod_1.z
        .number({
        invalid_type_error: (0, error_messages_1.numericMessage)('Language code'),
        required_error: (0, error_messages_1.requiredMessage)('Language code'),
    })
        .nullable(),
    copyrightOfficeNumber: zod_1.z
        .string({
        invalid_type_error: (0, error_messages_1.stringMessage)('Copyright office number'),
        required_error: (0, error_messages_1.requiredMessage)('Copyright office number'),
    })
        .max(12, (0, error_messages_1.stringSizeMaxMessage)('Copyright office number', 12))
        .nullable(),
    copyrightOfficeDate: zod_1.z
        .string({
        invalid_type_error: (0, error_messages_1.stringMessage)('Copyright office date'),
        required_error: (0, error_messages_1.requiredMessage)('Copyright office date'),
    })
        .nullable(),
    firstUseRefusalIndicator: zod_1.z.boolean().nullable(),
    rightsholderProprietaryId: zod_1.z
        .string({
        invalid_type_error: (0, error_messages_1.stringMessage)('Member Work Identifier'),
        required_error: (0, error_messages_1.requiredMessage)('Member Work Identifier'),
    })
        .max(14, (0, error_messages_1.stringSizeMaxMessage)('Member Work Identifier', 14))
        .nullable(),
    iswc: zod_1.z
        .string({
        invalid_type_error: (0, error_messages_1.stringMessage)('ISWC'),
        required_error: (0, error_messages_1.requiredMessage)('ISWC'),
    })
        .refine((val) => (0, validate_1.ISWCValidator)(val), 'Invalid ISWC format')
        .nullable(),
    holdTypeId: zod_1.z
        .number({
        invalid_type_error: (0, error_messages_1.numericMessage)('Hold type'),
        required_error: (0, error_messages_1.requiredMessage)('ISHold typeWC'),
    })
        .optional(),
});
exports.default = workDetailsSchema;
