"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOOLTIP_TEXT = void 0;
exports.TOOLTIP_TEXT = {
    createWork: {
        copyrightOfficeNumber: 'This is a twelve-character string that typically ' +
            'begins with a two or three letter prefix, such as "PA", "PAU", "SR", "SRU", "TX", "TXU", "VA", or "VAU". ' +
            'Zeros should be inserted between the prefix and the numerical portion of the number so that the total number ' +
            'of characters is twelve. (ex. TX0001234567 ; VAU000123456)',
        rightsholderProprietaryId: 'For Members that use a personal database, you may enter a personal identifier here. The identifier should be unique for each work registration.',
        iswc: 'The ISWC (International Standard Musical Work Code) is a unique and internationally recognized reference number to identify musical works. ISWCs help make matching and payments more accurate.',
        copyrightOfficeDate: 'This is the effective date of registration assigned by the Copyright Office.',
        alternativeTitles: 'Alternative titles should only be: language variations, live variations, and remixes where the writers are the same as the original composition.',
        publisher: 'The MLC defines the Publisher as the entity that has the direct relationship with the songwriter. ' +
            'It is also known as the "original publisher". If the Publisher is the same as the Administrator, you only ' +
            'need to enter the information for the Publisher.',
        administrator: 'The MLC defines the Administrator as the entity responsible for registering and licensing works ' +
            'and collecting royalties on behalf of the Publisher and songwriters it represents. If the Publisher is the ' +
            'same as the Administrator, you only need to enter the information for the Publisher.',
        representedWriter: '(OPTIONAL) This column displays the songwriters represented by this publisher/administrator.',
        writerRole: 'Please note: at least one Composer or Composer/Author writer role is required for\n' +
            'all new registrations.',
    },
    extendInfoToWorkItem: {
        writers: 'If the total known share is less than 100%, the remaining portion of the work is unclaimed.',
    },
    pagination: {
        totalResults: 'Users can only access the first 10,000 results of a search. ' +
            'We suggest entering additional criteria to the search box, which will return fewer and more specific results.',
    },
};
