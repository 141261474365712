"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const contexts_1 = require("@App/contexts");
require("./index.scss");
const format_name_1 = require("@App/helpers/format-name");
const WriterCard = ({ writers, errors }) => {
    const { libStore } = (0, react_1.useContext)(contexts_1.StoresContext);
    return (react_1.default.createElement(react_1.default.Fragment, null, writers.length ? (react_1.default.createElement("div", { className: 'writer-card-v2' },
        react_1.default.createElement("div", { className: 'card-header card-row' },
            react_1.default.createElement("div", null, "Writer Name"),
            react_1.default.createElement("div", null, "Writer IPI"),
            react_1.default.createElement("div", null, "Writer Role")),
        react_1.default.createElement("div", { className: 'card-body' }, writers.map((writer, idx) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            const writerRole = (_a = libStore.writerRoles.find((role) => +role.id === writer.roleCode)) === null || _a === void 0 ? void 0 : _a.label;
            return (react_1.default.createElement("div", { className: 'card-row', key: idx },
                react_1.default.createElement("div", { "data-label": 'Writer Name' },
                    react_1.default.createElement("span", null, (0, format_name_1.formatName)(writer.firstName, writer.lastName)),
                    react_1.default.createElement("span", { className: 'error-field' }, [
                        ...(((_c = (_b = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _b === void 0 ? void 0 : _b.firstName) === null || _c === void 0 ? void 0 : _c._errors) || []),
                        ...(((_e = (_d = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _d === void 0 ? void 0 : _d.lastName) === null || _e === void 0 ? void 0 : _e._errors) || []),
                    ].join(', '))),
                react_1.default.createElement("div", { "data-label": 'Writer IPI' },
                    react_1.default.createElement("span", null, writer.ipiNumber || '--'),
                    react_1.default.createElement("span", { className: 'error-field' }, (_h = (_g = (_f = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _f === void 0 ? void 0 : _f.ipiNumber) === null || _g === void 0 ? void 0 : _g._errors) === null || _h === void 0 ? void 0 : _h.join(', '))),
                react_1.default.createElement("div", { "data-label": 'Writer Role' },
                    react_1.default.createElement("span", null, writerRole),
                    react_1.default.createElement("span", { className: 'error-field' }, (_l = (_k = (_j = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _j === void 0 ? void 0 : _j.roleCode) === null || _k === void 0 ? void 0 : _k._errors) === null || _l === void 0 ? void 0 : _l.join(', ')))));
        })))) : (react_1.default.createElement("div", { className: 'missing-records' }, "No writers listed"))));
};
exports.default = WriterCard;
