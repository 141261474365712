"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.analyticsSendIdentify = exports.analyticsSendEvent = exports.analyticsSendPage = void 0;
const analyticsSendPage = (pageName) => {
    var _a;
    (_a = window === null || window === void 0 ? void 0 : window.analytics) === null || _a === void 0 ? void 0 : _a.page(pageName);
};
exports.analyticsSendPage = analyticsSendPage;
const analyticsSendEvent = (eventName) => {
    var _a;
    (_a = window === null || window === void 0 ? void 0 : window.analytics) === null || _a === void 0 ? void 0 : _a.track(eventName);
};
exports.analyticsSendEvent = analyticsSendEvent;
const analyticsSendIdentify = (user) => {
    var _a;
    if (user && user.id) {
        window.dataLayer.push({
            event: 'identify-user',
            mlcUserId: user.id,
            username: user.firstName + ' ' + user.lastName,
            userEmail: user.email,
            userCreatedAt: user.invitationSentTimestamp,
        });
        (_a = window === null || window === void 0 ? void 0 : window.analytics) === null || _a === void 0 ? void 0 : _a.identify(user.id, {
            name: user.firstName + ' ' + user.lastName,
            email: user.email,
            created_at: user.invitationSentTimestamp,
        });
    }
};
exports.analyticsSendIdentify = analyticsSendIdentify;
