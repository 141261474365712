"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLoginMaintenanceCheck = void 0;
const auth_1 = require("@App/api/routes/auth");
const react_1 = require("react");
const contexts_1 = require("@App/contexts");
const MAX_ATTEMPTS = 30;
const useLoginMaintenanceCheck = () => {
    const { popupStore } = (0, react_1.useContext)(contexts_1.StoresContext);
    const timerRef = (0, react_1.useRef)(undefined);
    // Must be an useRef to keep track of attempt across re-renders, while correctly not triggering a rerender itself when updated
    const loginMaintenanceAttemptsCount = (0, react_1.useRef)(0);
    (0, react_1.useEffect)(() => {
        checkStatus();
        return () => clearTimeout(timerRef.current);
    });
    /**
     * Checks backend for system alerts around login maintenance.
     * This is separate from system-wide maintenance, which locks
     * down the entire site. This will only show an error banner.
     */
    const checkStatus = () => {
        // stop attempting maintenance checks if:
        // 1) attempt limit has been reached, this is to prevent large numbers of users
        // sitting idly on the login screen causing more and more api calls that add little value
        // 2) we have left the login page, since this is a recursive function we need an exit
        // condition once the user has moved on
        loginMaintenanceAttemptsCount.current < MAX_ATTEMPTS &&
            (0, auth_1.loginMaintenanceCheck)()
                .then((response) => {
                var _a;
                const message = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.message;
                if (message) {
                    popupStore.setAlertData(message, true, true);
                }
            })
                .catch((err) => popupStore.setAlertData(err.message, true))
                .finally(() => {
                loginMaintenanceAttemptsCount.current = loginMaintenanceAttemptsCount.current + 1;
                timerRef.current = setTimeout(checkStatus, 60000);
            });
    };
    return () => {
        loginMaintenanceAttemptsCount.current = 0;
    };
};
exports.useLoginMaintenanceCheck = useLoginMaintenanceCheck;
