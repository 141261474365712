"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorksReportRequestDTO = void 0;
class WorksReportRequestDTO {
    constructor(workSearch) {
        Object.defineProperty(this, "format", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "artistName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mlcsongCodes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "publisherIPI", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "publisherName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "publisherPNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "title", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "writerIPI", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "writerIPID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "writerName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.format = '';
        this.artistName = workSearch.artist;
        this.mlcsongCodes = workSearch.songCode ? [workSearch.songCode] : undefined;
        this.publisherIPI = workSearch.publisherIpi;
        this.publisherName = workSearch.publisherName;
        this.publisherPNumber = workSearch.publisherNumber;
        this.title = workSearch.title;
        this.writerIPI = workSearch.writerIpi;
        this.writerIPID = workSearch.writerIpId;
        this.writerName = workSearch.writerName;
    }
}
exports.WorksReportRequestDTO = WorksReportRequestDTO;
