"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const another_phone_formatter_1 = require("@App/helpers/another-phone-formatter");
const react_1 = __importStar(require("react"));
const format_address_1 = require("@App/helpers/format-address");
const format_name_1 = require("@App/helpers/format-name");
const contexts_1 = require("@App/contexts");
const get_country_title_1 = require("@App/helpers/get-country-title");
const ContactDetailsBlock = (props) => {
    var _a;
    const { libStore } = (0, react_1.useContext)(contexts_1.StoresContext);
    const { contact } = props;
    const name = (0, format_name_1.formatName)(contact.firstName, contact.lastName);
    const fullStreetAddress = contact.address ? (0, format_address_1.formatStreetAddress)(contact.address) : '';
    const cityStateCode = contact.address ? (0, format_address_1.formatCityStateCode)(contact.address) : '';
    const fullPhoneNumber = contact.phoneNumber ? (0, another_phone_formatter_1.formatPhoneNumber)(contact.phoneNumber) : '';
    const countryTitle = (0, get_country_title_1.getCountryTitle)(libStore.countries, ((_a = contact.address) === null || _a === void 0 ? void 0 : _a.countryId) ? Number(contact.address.countryId) : null);
    return (react_1.default.createElement("div", null,
        react_1.default.createElement("p", null, name),
        react_1.default.createElement("p", null, fullStreetAddress),
        react_1.default.createElement("p", null, cityStateCode),
        react_1.default.createElement("p", null, countryTitle),
        react_1.default.createElement("p", null, fullPhoneNumber),
        react_1.default.createElement("p", null, contact.email)));
};
exports.default = ContactDetailsBlock;
