"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
require("../index.scss");
const Button_1 = require("@Components/Buttons/Button");
const forms_constants_1 = require("@Const/forms-constants");
const get_country_title_1 = require("@App/helpers/get-country-title");
const contexts_1 = require("@App/contexts");
const mobx_react_1 = require("mobx-react");
const RightsholderMaintenanceContactView = (props) => {
    const { pending, setEditable } = props;
    const { libStore, rightsholderStore } = (0, react_1.useContext)(contexts_1.StoresContext);
    const rightsHolderData = rightsholderStore.rightsholderSummary;
    const { name, address2, address3, address4, city, state, postalCode, hfaPublisherNumber, ipiNumber, email, phone, country, } = rightsHolderData;
    const countryTitle = country === 840 ? 'USA' : (0, get_country_title_1.getCountryTitle)(libStore.countries, country);
    const isni = rightsHolderData.isni ? parseInt(rightsHolderData.isni, 10) : '';
    return (react_1.default.createElement("div", { className: "rightsholder-maintenance-contact-tab" },
        react_1.default.createElement("div", { className: 'buttons-block' },
            react_1.default.createElement(Button_1.Button, { onClick: () => setEditable(true), mainColor: true, disabled: pending, type: 'primary' }, "Edit")),
        react_1.default.createElement("section", { className: 'name-section' },
            react_1.default.createElement("h5", null, "Member Name "),
            react_1.default.createElement("div", { className: 'fields-row-container' },
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("p", { className: 'small title' }, forms_constants_1.RIGHTSHOLDER_FORM.name.label),
                    react_1.default.createElement("p", { className: 'value' }, name)))),
        react_1.default.createElement("section", { className: 'mailing-address-section' },
            react_1.default.createElement("h5", null, "Mailing Address"),
            react_1.default.createElement("div", { className: 'fields-column-container custom' },
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("p", { className: 'value' }, address2),
                    react_1.default.createElement("p", { className: 'value' }, address3),
                    react_1.default.createElement("p", { className: 'value' }, address4)))),
        react_1.default.createElement("section", { className: 'address-section' },
            react_1.default.createElement("h5", null, "Address"),
            react_1.default.createElement("div", { className: 'fields-row-container' },
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("p", { className: 'small title' }, forms_constants_1.RIGHTSHOLDER_FORM.city.label),
                    react_1.default.createElement("p", { className: 'value' }, city)),
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("p", { className: 'small title' }, forms_constants_1.RIGHTSHOLDER_FORM.state.label),
                    react_1.default.createElement("p", { className: 'value' }, state)),
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("p", { className: 'small title' }, forms_constants_1.RIGHTSHOLDER_FORM.country.label),
                    react_1.default.createElement("p", { className: 'value' }, countryTitle)),
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("p", { className: 'small title' }, forms_constants_1.RIGHTSHOLDER_FORM.postalCode.label),
                    react_1.default.createElement("p", { className: 'value' }, postalCode)))),
        react_1.default.createElement("section", { className: 'identifiers-section' },
            react_1.default.createElement("h5", null, "Identifiers"),
            react_1.default.createElement("div", { className: 'fields-row-container' },
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("p", { className: 'small title' }, forms_constants_1.RIGHTSHOLDER_FORM.hfaPublisherNumber.label),
                    react_1.default.createElement("p", { className: 'value' }, hfaPublisherNumber)),
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("p", { className: 'small title' }, forms_constants_1.RIGHTSHOLDER_FORM.ipiNumber.label),
                    react_1.default.createElement("p", { className: 'value' }, ipiNumber)),
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("p", { className: 'small title' }, forms_constants_1.RIGHTSHOLDER_FORM.isni.label),
                    react_1.default.createElement("p", { className: 'value' }, isni)))),
        react_1.default.createElement("section", { className: 'contact-info-section' },
            react_1.default.createElement("h5", null, "Contact Information"),
            react_1.default.createElement("div", { className: 'fields-row-container' },
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("p", { className: 'small title' }, forms_constants_1.RIGHTSHOLDER_FORM.phoneNumber.label),
                    react_1.default.createElement("p", { className: 'value' }, phone)),
                react_1.default.createElement("div", { className: 'field-block' },
                    react_1.default.createElement("p", { className: 'small title' }, forms_constants_1.RIGHTSHOLDER_FORM.email.label),
                    react_1.default.createElement("p", { className: 'value' }, email))))));
};
exports.default = (0, mobx_react_1.observer)(RightsholderMaintenanceContactView);
