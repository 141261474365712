"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.helpTopicOptions = exports.qualifyOptionsMockProps = exports.statusMFAOptionsMockProps = exports.statusRightsHolderOptionsMockProps = exports.searchFilterCatalogWorkOptionsPublic = exports.searchFilterCatalogWorkOptions = exports.searchFilterCatalogWorkOptionsRecording = exports.searchPublisherCatalog = exports.searchFilterCatalogWorkOptionsWork = exports.registrationHistoryOptions = exports.REFINE_SEARCH_WORK_FIELDS = void 0;
exports.REFINE_SEARCH_WORK_FIELDS = {
    workTitle: 'work-title',
    writerIpi: 'writer-ipi',
    writerName: 'writer-name',
    publisherIpi: 'publisher-ipi',
    publisherName: 'publisher-name',
    publisherNumber: 'publisher-number',
    iswc: 'iswc',
    mlcSongCode: 'mlc-song-code',
    recordingTitle: 'recording-title',
    artist: 'artist',
    isrc: 'isrc',
    memberProprietaryId: 'member-song-id',
};
exports.registrationHistoryOptions = [
    { id: exports.REFINE_SEARCH_WORK_FIELDS.workTitle, value: 'work-title', label: 'Work Title' },
];
exports.searchFilterCatalogWorkOptionsWork = [
    { id: exports.REFINE_SEARCH_WORK_FIELDS.workTitle, value: 'work-title', label: 'Work Title' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.iswc, value: 'iswc', label: 'ISWC' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.mlcSongCode, value: 'mlc-song-code', label: 'MLC Song Code' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.writerName, value: 'writer-name', label: 'Writer Name' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.writerIpi, value: 'writer-ipi', label: 'Writer IPI' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.publisherName, value: 'publisher-name', label: 'Publisher Name' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.publisherIpi, value: 'publisher-ipi', label: 'Publisher IPI' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.publisherNumber, value: 'publisher-number', label: 'MLC Publisher Number' },
];
exports.searchPublisherCatalog = [
    { id: exports.REFINE_SEARCH_WORK_FIELDS.workTitle, value: 'work-title', label: 'Work Title' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.iswc, value: 'iswc', label: 'ISWC' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.mlcSongCode, value: 'mlc-song-code', label: 'MLC Song Code' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.writerName, value: 'writer-name', label: 'Writer Name' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.writerIpi, value: 'writer-ipi', label: 'Writer IPI' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.publisherName, value: 'publisher-name', label: 'Publisher Name' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.publisherIpi, value: 'publisher-ipi', label: 'Publisher IPI' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.memberProprietaryId, value: 'member-song-id', label: 'Member Work Identifier' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.recordingTitle, value: 'recording-title', label: 'Recording Title' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.artist, value: 'artist', label: 'Artist' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.isrc, value: 'isrc', label: 'ISRC' },
];
exports.searchFilterCatalogWorkOptionsRecording = [
    { id: exports.REFINE_SEARCH_WORK_FIELDS.recordingTitle, value: 'recording-title', label: 'Recording Title' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.artist, value: 'artist', label: 'Artist' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.isrc, value: 'isrc', label: 'ISRC' },
];
exports.searchFilterCatalogWorkOptions = [
    { id: exports.REFINE_SEARCH_WORK_FIELDS.writerName, value: 'writer-name', label: 'Writer Name' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.writerIpi, value: 'writer-ipi', label: 'Writer IPI' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.publisherName, value: 'publisher-name', label: 'Publisher Name' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.publisherIpi, value: 'publisher-ipi', label: 'Publisher IPI' },
    { id: exports.REFINE_SEARCH_WORK_FIELDS.publisherNumber, value: 'publisher-number', label: 'MLC Publisher Number' },
];
// export const searchFilterCatalogWorkOptions: SelectOption[] = [
//     ...SEARCH_CATALOG_WORK_OPTIONS,
//     ...searchFilterCatalogWorkOptionsRecording
// ]
exports.searchFilterCatalogWorkOptionsPublic = [...exports.searchFilterCatalogWorkOptionsWork];
exports.statusRightsHolderOptionsMockProps = {
    title: '',
    field: {
        name: 'status',
    },
    options: [
        { value: 'business', label: 'Business', id: 1 },
        { value: 'individual', label: 'Individual', id: 2 },
    ],
};
exports.statusMFAOptionsMockProps = {
    title: '',
    field: {
        name: 'mfa',
    },
    options: [
        { value: 'email', label: 'Email', id: 1 },
        { value: 'mobile', label: 'Mobile', id: 2 },
    ],
};
exports.qualifyOptionsMockProps = {
    title: '',
    field: {
        name: 'qualify',
    },
    options: [
        {
            value: 'completely',
            label: 'I work for a publishing company or CMO, or I am a completely self-administered songwriter',
            id: 1,
            description: 'Both of the following statements are true: 1) I own, or administer/collect on behalf of, one publishing entity;' +
                ' 2) My U.S. digital audio mechanical streaming royalties are paid to one payee.',
        },
        {
            value: 'multiple',
            label: 'I work for a publishing company or CMO, or I am a completely self-administered songwriter',
            id: 4,
            description: 'One or both of the following statements are true: 1) I own, or administer/collect on behalf of, more than one publishing entity;' +
                ' 2) My U.S. digital audio mechanical streaming royalties are paid to more than one payee.',
        },
        {
            value: 'partially',
            label: 'I am a partially self-administered songwriter',
            id: 2,
            description: 'Partially self-administered songwriters have the sole right to register, license and collect royalties for some of their' +
                ' works, while others are administered by third parties. These songwriters should register as Members of The MLC, but only for the works they' +
                ' self-administer.',
        },
        {
            value: 'exclusively',
            label: 'I am a songwriter who is exclusively administered by third parties',
            id: 3,
            description: 'Songwriters frequently grant music publishers, administrators or other third parties exclusive rights to register, license and' +
                ' collect royalties for their works. If your works are exclusively administered by third parties, only they need to register as Members of' +
                ' The MLC in order to collect royalties on your behalf.',
        },
    ],
};
exports.helpTopicOptions = [
    {
        id: 'Account',
        value: 'Account',
        label: 'Account',
        subTopics: [
            {
                id: 'User Account',
                value: 'User Account',
                label: 'I need help with my user account',
            },
            {
                id: 'Login Issues',
                value: 'Login Issues',
                label: "I can't log in to my user account",
            },
            {
                id: 'Verification',
                value: 'Verification',
                label: 'I was not verified as a user',
            },
            {
                id: 'Member Account',
                value: 'Member Account',
                label: 'I need help with my Member account',
            },
        ],
    },
    {
        id: 'Catalog Management',
        value: 'Catalog Management',
        label: 'Catalog Management',
        subTopics: [
            {
                id: 'CWR',
                value: 'CWR',
                label: 'I need help with CWR',
            },
            {
                id: 'Portal Works Registration',
                value: 'Portal Works Registration',
                label: 'I need help registering my work(s) in The MLC Portal',
            },
            {
                id: 'Catalog Transfer',
                value: 'Catalog Transfer',
                label: 'I need help with a catalog transfer',
            },
            {
                id: 'Conflicts and Overclaims',
                value: 'Conflicts and Overclaims',
                label: 'I need help with a conflict or overclaim',
            },
            {
                id: 'Member Relinquishing Claims',
                value: 'Member Relinquishing Claims',
                label: 'I need to relinquish a claim',
            },
            {
                id: 'User Replacement',
                value: 'User Replacement',
                label: 'I need to remove a user from my Member and/or gain access to my Member',
            },
            {
                id: 'DSP Reporting Usage Data Error',
                value: 'DSP Reporting Usage Data Error',
                label: 'I work for a DSP and need to report an error',
            },
            {
                id: 'Editing Works',
                value: 'Editing Works',
                label: 'I need help editing my works',
            },
            {
                id: 'Matching Tool',
                value: 'Matching Tool',
                label: 'I need help with the Matching Tool',
            },
            {
                id: 'Claiming Tool',
                value: 'Claiming Tool',
                label: 'I need help with the Claiming Tool',
            },
        ],
    },
    {
        id: 'Public Search',
        value: 'Public Search',
        label: 'Public Search',
        subTopics: [
            {
                id: 'Public Search',
                value: 'Public Search',
                label: 'I need help with the Public Search',
            },
            {
                id: 'Bulk Database Feed',
                value: 'Bulk Database Feed',
                label: 'I need help with the Bulk Database Feed',
            },
        ],
    },
    {
        id: 'About The MLC',
        value: 'About The MLC',
        label: 'About The MLC',
        subTopics: [
            {
                id: 'General Questions About The MLC',
                value: 'General Questions About The MLC',
                label: 'I have a question about The MLC and its operation',
            },
            {
                id: 'General Works Registration',
                value: 'General Works Registration',
                label: 'Why do I need to register my works with The MLC?',
            },
            {
                id: 'Marketing',
                value: 'Marketing',
                label: 'I have a request for marketing with The MLC',
            },
            {
                id: 'Job Inquiry/Careers',
                value: 'Job Inquiry/Careers',
                label: 'I want to apply for a position with The MLC',
            },
            {
                id: 'User Feedback',
                value: 'User Feedback',
                label: 'I have feedback for The MLC',
            },
        ],
    },
    {
        id: 'Payment',
        value: 'Payment',
        label: 'Payment',
        subTopics: [
            {
                id: 'Payment Setup',
                value: 'Payment Setup',
                label: 'I need help setting up my payment information in The MLC Portal',
            },
            {
                id: 'Royalties Information',
                value: 'Royalties Information',
                label: 'I need information about royalties',
            },
            {
                id: 'Payment Errors',
                value: 'Payment Errors',
                label: 'I need a payment error resolved',
            },
        ],
    },
    {
        id: 'DQI',
        value: 'DQI',
        label: 'DQI',
        subTopics: [
            {
                id: 'DQI',
                value: 'DQI',
                label: 'I need help with DQI',
            },
        ],
    },
];
