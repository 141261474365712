"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNumTitles = void 0;
const getNumTitles = (countAllTitles, currentPage, limit) => {
    let isRedirect = false;
    let firstTitle;
    let lastTitle;
    if (currentPage * limit > countAllTitles) {
        if (currentPage * limit - limit < countAllTitles) {
            const diff = currentPage * limit - countAllTitles;
            firstTitle = (currentPage - 1) * limit + 1;
            lastTitle = currentPage * limit - diff;
        }
        else {
            // page not found = > redirect to page1
            isRedirect = true;
            firstTitle = 1;
            lastTitle = limit;
            if (countAllTitles < limit) {
                lastTitle = countAllTitles;
            }
        }
    }
    else {
        firstTitle = (currentPage - 1) * limit + 1;
        lastTitle = currentPage * limit;
    }
    return {
        firstTitle,
        lastTitle,
        isRedirect,
    };
};
exports.getNumTitles = getNumTitles;
