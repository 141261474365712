"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formFieldsInvalidCheck = exports.recordingsInvalidCheck = exports.publishersInvalidCheck = exports.writersInvalidCheck = exports.workDetailsInvalidCheck = exports.akaTitlesInvalidCheck = void 0;
const work_entities_fields_validators_1 = require("@App/helpers/work-entities-fields-validators");
const fields_validators_1 = require("@App/helpers/work-entities/fields-validators");
const validate_1 = require("@App/helpers/validate");
// These validators check fields in the respective blocks and return isError: boolean
const akaTitlesInvalidCheck = (akaTitles, akaLib, langsLib) => {
    if (!akaTitles.length) {
        return false;
    }
    const isError = akaTitles.some((akaTitle) => {
        const title = akaTitle.title; // required, max-length: 500
        const titleType = akaTitle.titleType; // required, match with id from lib
        const languageCode = akaTitle.languageCode; // match with id from lib
        return ((0, fields_validators_1.akaTitleInvalidCheck)(title) ||
            (0, fields_validators_1.akaTitleTypeInvalidCheck)(titleType, akaLib) ||
            (0, work_entities_fields_validators_1.languageInvalidCheck)(languageCode, langsLib, false));
    });
    return isError;
};
exports.akaTitlesInvalidCheck = akaTitlesInvalidCheck;
const workDetailsInvalidCheck = (work, akaLib, langsLib) => {
    var _a, _b, _c, _d;
    const validations = {
        titleError: false,
        durationError: false,
        copyrightOfficeNumberError: false,
        copyrightOfficeDateError: false,
        rightsholderProprietaryIdError: false,
        iswcError: false,
        // languageCodeError: true,
        akaTitlesError: false,
    };
    validations.titleError = (0, work_entities_fields_validators_1.workTitleInvalidCheck)(work.title);
    validations.durationError = (0, work_entities_fields_validators_1.durationInvalidCheck)((_a = work.duration) !== null && _a !== void 0 ? _a : null);
    validations.copyrightOfficeNumberError = (0, work_entities_fields_validators_1.copyrightOfficeNumberInvalidCheck)((_b = work.copyrightOfficeNumber) !== null && _b !== void 0 ? _b : null);
    validations.rightsholderProprietaryIdError = (0, work_entities_fields_validators_1.rightsholderProprietaryIdInvalidCheck)((_c = work.rightsholderProprietaryId) !== null && _c !== void 0 ? _c : null);
    validations.iswcError = (0, work_entities_fields_validators_1.iswcInvalidCheck)((_d = work.iswc) !== null && _d !== void 0 ? _d : null);
    // validations.languageCodeError = languageInvalidCheck(work.languageCode, langsLib, true)
    validations.akaTitlesError = work.alternativeTitles.length
        ? (0, exports.akaTitlesInvalidCheck)(work.alternativeTitles, akaLib, langsLib)
        : false;
    let invalidCounter = 0;
    for (const validationStatus in validations) {
        if (validations[validationStatus]) {
            invalidCounter += 1;
        }
    }
    return !!invalidCounter;
};
exports.workDetailsInvalidCheck = workDetailsInvalidCheck;
const writersInvalidCheck = (writers, rolesLib) => {
    if (!writers.length) {
        return true;
    }
    const isError = writers.some((writer) => {
        const firstName = writer.firstName; // max-length: 60
        const lastName = writer.lastName; // required, max-length: 200
        const ipiNumber = writer.ipiNumber; // max-length: 11, ipi validator, numeric
        const roleCode = writer.roleCode; // required, match with id from lib
        return ((0, fields_validators_1.writerFirstNameInvalidCheck)(firstName) ||
            (0, fields_validators_1.writerLastNameInvalidCheck)(lastName) ||
            !(0, validate_1.IPIValidator)(ipiNumber, false) ||
            (0, fields_validators_1.writerRoleInvalidCheck)(roleCode, rolesLib));
    });
    return isError || !(0, fields_validators_1.isComposerPresent)(writers);
};
exports.writersInvalidCheck = writersInvalidCheck;
const publishersInvalidCheck = (originalPublishers, skipIpiCheck = false) => {
    if (!originalPublishers.length) {
        return true;
    }
    const isError = originalPublishers.some((op) => {
        const publisherNameOP = op.publisherName;
        const publisherShareOP = op.publisherShare;
        const ipiNumberOP = op.ipiNumber;
        const administratorPublishers = op.administratorPublishers;
        let checkOpShares = true;
        const administratorPublishersError = administratorPublishers.some((ap) => {
            if (!administratorPublishers.length) {
                return false;
            }
            checkOpShares = false;
            const publisherNameAP = ap.publisherName;
            const publisherShareAP = ap.publisherShare;
            const ipiNumberAP = ap.ipiNumber;
            return ((0, fields_validators_1.publisherNameInvalidCheck)(publisherNameAP) ||
                (0, fields_validators_1.publisherShareInvalidCheck)(publisherShareAP) ||
                !(0, validate_1.IPIValidator)(ipiNumberAP, !skipIpiCheck));
        });
        return ((0, fields_validators_1.publisherNameInvalidCheck)(publisherNameOP) ||
            (checkOpShares && (0, fields_validators_1.publisherShareInvalidCheck)(publisherShareOP)) ||
            !(0, validate_1.IPIValidator)(ipiNumberOP, !skipIpiCheck) ||
            administratorPublishersError);
    });
    return isError;
};
exports.publishersInvalidCheck = publishersInvalidCheck;
const recordingsInvalidCheck = (recordings, skipAmendCheck = false) => {
    if (!recordings.length) {
        return false;
    }
    const isError = recordings.some((recording) => {
        if (skipAmendCheck || !('amend' in recording) || recording.amend === true) {
            const title = recording.title; // required, max-length: 200
            const artistName = recording.artistName; // required, max-length: 60
            const isrc = recording.isrc; // max-length: 12
            const duration = recording.duration; // 00:00 format
            const label = recording.label; // max-length: 60
            return ((0, work_entities_fields_validators_1.recordingTitleInvalidCheck)(title) ||
                (0, work_entities_fields_validators_1.artistNameInvalidCheck)(artistName) ||
                (0, work_entities_fields_validators_1.isrcInvalidCheck)(isrc) ||
                (0, work_entities_fields_validators_1.durationInvalidCheck)(duration) ||
                (0, work_entities_fields_validators_1.labelInvalidCheck)(label));
        }
        else {
            return false;
        }
    });
    return isError;
};
exports.recordingsInvalidCheck = recordingsInvalidCheck;
// this validator checking data and errors on form and return isError: boolean
const formFieldsInvalidCheck = (data, validations) => {
    let errorCount = 0;
    for (const field in validations) {
        if (validations[field].error || (validations[field].required && field !== 'publisherShare' && !data[field])) {
            errorCount += 1;
        }
    }
    return !!errorCount;
};
exports.formFieldsInvalidCheck = formFieldsInvalidCheck;
