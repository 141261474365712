"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportStore = void 0;
const mobx_1 = require("mobx");
const search_1 = require("@App/api/routes/search");
class ExportStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_exportType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_exportFormats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "loadExportFormats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                this.rootStore.preloaderStore.openTransparentPreloader(true);
                const exportFormatsResponse = yield (0, search_1.getExportFormats)(this._exportType);
                (0, mobx_1.runInAction)(() => {
                    this._exportFormats = exportFormatsResponse.data.data.map((exportFormat, index) => ({
                        value: exportFormat.value,
                        label: exportFormat.label,
                        description: exportFormat.description,
                        id: index,
                    }));
                });
                this.rootStore.preloaderStore.openTransparentPreloader(false);
            })
        });
        (0, mobx_1.makeObservable)(this, {
            _exportType: mobx_1.observable,
            _exportFormats: mobx_1.observable,
            loadExportFormats: mobx_1.action,
        });
        this.rootStore = rootStore;
    }
    setExportType(type) {
        this._exportType = type;
    }
    get exportType() {
        return this._exportType;
    }
    get exportFormats() {
        return this._exportFormats;
    }
}
exports.ExportStore = ExportStore;
